// import Vue from 'vue'
import MemberAPI from '@/api/member'

export default {
    namespaced: true,
    state: {
        memberKind: null,
        verifyStatus: null,
        verifyDate: null,
        comment: null,
        sendDate: null,

        familyName: null,
        familyNameKana: null,
        name: null,
        nameKana: null,
        birthDate: null,
        zip: null,
        prefecture: null,
        city: null,
        address: null,

        companyName: null,
        companyNameKana: null,
        presidentName: null,
        presidentNameKana: null,
        companyZip: null,
        companyPrefecture: null,
        companyCity: null,
        companyAddress: null,
        sameAsCompanyAddress: false,
        registrationZip: null,
        registrationPrefecture: null,
        registrationCity: null,
        registrationAddress: null,
        documentKind: null,
        documents: [null, null]
    },
    mutations: {
        setVerificationStatus: (state, result) => {
            state.memberKind = result.memberKind
            state.verifyStatus = result.verifyStatus
            state.verifyDate = result.verifyDate
            state.comment = result.comment
            state.sendDate = result.sendDate
        },
        setVerify: (state, result) => {
            state.familyName = result.familyName
            state.familyNameKana = result.familyNameKana
            state.name = result.name
            state.nameKana = result.nameKana
            state.birthDate = result.birthDate
            state.zip = result.zip
            state.prefecture = result.prefecture
            state.city = result.city
            state.address = result.address

            state.companyName = result.companyName
            state.companyNameKana = result.companyNameKana
            state.presidentName = result.presidentName
            state.presidentNameKana = result.presidentNameKana
            state.companyZip = result.companyZip
            state.companyPrefecture = result.companyPrefecture
            state.companyCity = result.companyCity
            state.companyAddress = result.companyAddress
            state.sameAsCompanyAddress = result.sameAsCompanyAddress
            state.registrationZip = result.registrationZip
            state.registrationPrefecture = result.registrationPrefecture
            state.registrationCity = result.registrationCity
            state.registrationAddress = result.registrationAddress

            state.documentKind = result.documentKind
            state.documents = result.documents
        },

        setMemberKind(state, v) {
            state.memberKind = v
        },
        setFamilyName(state, v) {
            state.familyName = v.toString().replace(/\s+/g,'')
        },
        setFamilyNameKana(state, v) {
            state.familyNameKana = v.toString().replace(/\s+/g,'')
        },
        setName(state, v) {
            state.name = v.toString().replace(/\s+/g,'')
        },
        setNameKana(state, v) {
            state.nameKana = v.toString().replace(/\s+/g,'')
        },
        setBirthDate(state, v) {
            state.birthDate = v;
        },
        setZip(state, v) {
            state.zip = v
        },
        setPrefecture(state, v) {
            state.prefecture = v
        },
        setCity(state, v) {
            state.city = v
        },
        setAddress(state, v) {
            state.address = v
        },

        setCompanyName(state, v) {
            state.companyName = v
        },
        setCompanyNameKana(state, v) {
            state.companyNameKana = v
        },
        setPresidentName(state, v) {
            state.presidentName = v
        },
        setPresidentNameKana(state, v) {
            state.presidentNameKana = v
        },
        setCompanyZip(state, v) {
            state.companyZip = v
        },
        setCompanyPrefecture(state, v) {
            state.companyPrefecture = v
        },
        setCompanyCity(state, v) {
            state.companyCity = v
        },
        setCompanyAddress(state, v) {
            state.companyAddress = v
        },

        setDocumentKind(state, v) {
            state.documentKind = v
        },
    },
    actions: {
        getVerifyStatus: ({ commit }) => {
            return new Promise((resolve, reject) => {
                (async () => {

                    const result = await MemberAPI.getVerifyStatus().catch(e => {
                        console.log(e)
                        reject(e)
                        return false;
                    })

                    if (!result) {
                        return false;
                    }
                    commit('setVerificationStatus', result)

                    resolve()

                })();
            })
        },
        getVerify: ({ commit }) => {
            return new Promise((resolve) => {
                (async () => {

                    const result = await MemberAPI.getVerify().catch(e => {
                        console.log(e)
                        return false;
                    })

                    if (!result) {
                        return false;
                    }
                    commit('setVerify', result)

                    resolve()

                })();
            })
        },
        postVerify: ({ commit, state }) => {
            // 送信する前にdocumentsのnullを除外
            state.documents = state.documents.filter(document => document != null)
            return new Promise((resolve, reject) => {
                (async () => {
                    const result = await MemberAPI.postVerify({
                        familyName: state.familyName,
                        familyNameKana: state.familyNameKana,
                        name: state.name,
                        nameKana: state.nameKana,
                        birthDate: state.birthDate,
                        zip: state.zip,
                        prefecture: state.prefecture,
                        city: state.city,
                        address: state.address,
                        companyName: state.companyName,
                        companyNameKana: state.companyNameKana,
                        presidentName: state.presidentName,
                        presidentNameKana: state.presidentNameKana,
                        companyZip: state.companyZip,
                        companyPrefecture: state.companyPrefecture,
                        companyCity: state.companyCity,
                        companyAddress: state.companyAddress,
                        sameAsCompanyAddress: state.sameAsCompanyAddress,
                        registrationZip: state.registrationZip,
                        registrationPrefecture: state.registrationPrefecture,
                        registrationCity: state.registrationCity,
                        registrationAddress: state.registrationAddress,
                        documentKind: state.documentKind,
                        documents: state.documents
                    }).catch((e) => {
                        console.log(e)
                        reject(e)
                    })
                    if (!result || result == undefined) {
                        reject()
                    } else {
                        commit('setVerify', result)
                        resolve()
                    }
                })();
            })
        }
    },
    modules: {
    }
}