// import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        email: "",
        password: "",
        password2: "",
        birthdate: "1980-00-00",
        mailMagazine: true,
        scout: true,
        verifiedEmail: null,
        socialPlusIdentifer: null,
        emailSent: false,
    },
    mutations: {
        setEmail(state, v) {
            state.email = v;
        },
        setPassword(state, v) {
            state.password = v;
        },
        setPassword2(state, v) {
            state.password2 = v;
        },
        setBirthdate(state, v) {
            state.birthdate = v;
        },
        setMailMagazine(state, v) {
            state.mailMagazine = v;
        },
        setScout(state, v) {
            state.scout = v;
        },
        setVerifiedEmail(state, v) {
            state.verifiedEmail = v;
        },
        setSocialPlusIdentifer(state, v) {
            state.socialPlusIdentifer = v;
        },
        setEmailSent(state, v) {
            state.emailSent = v;
        },
    },
    actions: {
    }
}
