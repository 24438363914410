import MemberAPI from '@/api/member'

export default {
    namespaced: true,
    state: {
        providers: []
    },
    mutations: {
        setProviders: (state, v) => {
            state.providers = v
        }
    },
    actions: {
        getProviders: ({ commit }) => {
            return new Promise((resolve) => {
                (async () => {
                    const result = await MemberAPI.getSNSProviders().catch(e => {
                        console.log(e)
                        return false;
                    })

                    if (!result) {
                        return false;
                    }
                    commit('setProviders', result.providers)
                    resolve()
                })();
            })
        },
        dissociateGoogle: ({ commit }) => {
            return new Promise((resolve) => {
                (async () => {
                    const result = await MemberAPI.postSNSDissociate({ provider: 'gplus' }).catch(e => {
                        console.log(e)
                        return false;
                    })

                    if (!result) {
                        return false;
                    }
                    commit('setProviders', result.providers)
                    resolve()
                })();
            })
        },
        associate: ({ commit }, { token }) => {
            return new Promise((resolve) => {
                (async () => {
                    const result = await MemberAPI.postSNSAssociate({ token }).catch(e => {
                        console.log(e);
                        return false;
                    })

                    if (!result) {
                        return false;
                    }
                    commit('setProviders', result.providers)
                    resolve()
                })();
            })
        },
        dissociateLine: ({ commit }) => {
            return new Promise((resolve) => {
                (async () => {
                    const result = await MemberAPI.postSNSDissociate({ provider: 'line' }).catch(e => {
                        console.log(e)
                        return false;
                    })

                    if (!result) {
                        return false;
                    }
                    commit('setProviders', result.providers)
                    resolve()
                })();
            })
        },
        dissociateFacebook: ({ commit }) => {
            return new Promise((resolve) => {
                (async () => {
                    const result = await MemberAPI.postSNSDissociate({ provider: 'facebook' }).catch(e => {
                        console.log(e)
                        return false;
                    })

                    if (!result) {
                        return false;
                    }
                    commit('setProviders', result.providers)
                    resolve()
                })();
            })
        }
    },
    getters: {
        googleEnabled(state) {
            return state.providers.filter(a => a == 'gplus').length > 0
        },
        lineEnabled(state) {
            return state.providers.filter(a => a == 'line').length > 0
        },
        facebookEnabled(state) {
            return state.providers.filter(a => a == 'facebook').length > 0
        }
    }
}