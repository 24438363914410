// import Vue from 'vue'

import PublicAPI from '@/api/public'

export default {
  namespaced: true,
  state: {
    banners: [
    ],
    magazine_banners: [
    ],
  },
  mutations: {
    setBanners: (state, banners) => {
      if (banners) {
        state.banners = banners
      }
    },
    setMagazineBanners: (state, magazine_banners) => {
      if (magazine_banners) {
        state.magazine_banners = magazine_banners
      }
    },
  },
  actions: {
    getBanners: ({ commit }) => {
      return new Promise((resolve) => {
        (async () => {

          const result = await PublicAPI.getBanners().catch(e => {
            console.log(e)
			return false;
          })

          if (!result) {
			return false;
          }
          commit('setBanners', result)

          resolve()

        })();
      })
    },
    getMagazineBanners: ({ commit }) => {
      return new Promise((resolve) => {
        (async () => {
          const result = await PublicAPI.getMagazineBanners().catch(e => {
            console.log(e)
            return false;
          })

          if (!result) {
            return false;
          }
          commit('setMagazineBanners', result)

          resolve()

        })();
      })
    },
  },
  modules: {
  }
}