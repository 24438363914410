<template>
  <div>
    <!-- 新しいモーダル -->
    <transition :name="$mq == 'pc' ? 'modalMain' : 'slideModalMain'">
      <template v-if="$mq == 'pc'">
        <div
          class="modalstage selectjobindustry_pc"
          id="master-job_industry_pc"
          v-if="visible"
        >
          <div class="selectjobindustry_modal modalwin">
            <div class="modalwin_detail">
              <div class="modal_selectnav_hgroup">
                <h3 class="title_hdg3">仕事カテゴリを選ぶ</h3>
              </div>

              <div class="modal_selectnav_pc">
                <div class="modal_selectnav_nav">
                  <ul class="modal_selectnav_nav_list">
                    <li
                      v-for="parentCode in constantsJobCategories"
                      :key="parentCode.code"
                    >
                      <a
                        @click="selectedCategory = parentCode"
                        class="selectnav_btn"
                        v-bind:class="{ navon: parentCode == selectedCategory }"
                        ><span>{{ parentCode.name }}</span
                        ><em
                          class="icn_selected"
                          v-show="hasChildren(parentCode)"
                          >選択中</em
                        ></a
                      >
                    </li>
                  </ul>
                </div>
                <!-- /modal_selectnav_nav -->
                <div class="modal_selectnav_body">
                  <div class="modal_selectnav_body_detail">
                    <div
                      class="modal_selectnav_box navon"
                      v-if="selectedCategory"
                    >
                      <h3 class="title_hdg3">
                        <label class="formtable_checkbox_s"
                          ><input
                            type="checkbox"
                            :checked="isSelectedCategoryChecked"
                            @change="changeSelectedCategory"
                          />
                          <span>{{ selectedCategory.name }}</span></label
                        >
                      </h3>
                      <hr />
                      <template>
                        <ul class="modal_selectnav_box_list">
                          <li
                            v-for="businessType in selectedCategory.children"
                            :key="businessType.code"
                          >
                            <label class="formtable_checkbox_s"
                              ><input
                                type="checkbox"
                                v-model="checked"
                                :value="businessType"
                              /><span>{{ businessType.name }}</span></label
                            >
                          </li>
                        </ul>
                      </template>
                    </div>
                    <!-- /#maker -->
                  </div>
                  <!-- /modal_selectnav_body_detail -->
                </div>
                <!-- /modal_selectnav_body -->
              </div>
              <!-- /modal_selectnav_pc -->

              <div class="modal_selectnav_submit">
                <button type="button" class="btn_submit" @click="commit">
                  <span v-if="dialogType == 'search'">検索</span>
                  <span v-else>内容を反映する</span>
                </button>
              </div>

              <span class="modalwin_close_icn" @click="close"
                ><span>閉じる</span></span
              >
              <!-- <span class="modalwin_close_btn"><span>閉じる</span></span> -->
            </div>
          </div>
          <!-- /modalwin -->
        </div>
        <!-- /modalstage -->
      </template>
      <template v-if="$mq == 'sp'">
        <div
          class="modalstage selectjobindustry_sp"
          id="master-job_industry_sp"
          v-if="visible"
        >
          <div class="selectjobindustry_modal modalwin">
            <div class="modalwin_detail">
              <div class="modal_selectnav_hgroup">
                <h3 class="title_hdg3">仕事カテゴリを選ぶ</h3>
              </div>

              <div class="modal_selectnav_sp">
                <ul class="modal_selectnav_nav_list">
                  <li
                    v-for="parentCode in constantsJobCategories"
                    :key="parentCode.code"
                  >
                    <a
                      @click="
                        toggleCategory(parentCode);
                        selectedCategory = parentCode;
                      "
                      class="selectnav_btn"
                      v-bind:class="{ navon: isCategoryOpenned(parentCode) }"
                      ><span>{{ parentCode.name }}</span
                      ><em class="icn_selected" v-show="hasChildren(parentCode)"
                        >選択中</em
                      ></a
                    >
                    <transition
                      name="js-accordion"
                      @before-enter="beforeEnter"
                      @enter="enter"
                      @before-leave="beforeLeave"
                      @leave="leave"
                    >
                      <div
                        class="modal_selectnav_box js-accordion--target"
                        v-show="isCategoryOpenned(parentCode)"
                      >
                        <template>
                          <h4 class="title_bar">
                            <label class="formtable_checkbox_s"
                              ><input
                                type="checkbox"
                                :checked="
                                  isSelectedSubCategoryChecked(parentCode)
                                "
                                @change="
                                  changeSelectedSubCategory(parentCode, $event)
                                "
                              />
                              <span>{{ parentCode.name }}</span>
                            </label>
                          </h4>
                          <ul class="modal_selectnav_box_list">
                            <li
                              v-for="businessType in parentCode.children"
                              :key="businessType.code"
                            >
                              <label class="formtable_checkbox_s"
                                ><input
                                  type="checkbox"
                                  v-model="checked"
                                  :value="businessType"
                                /><span>{{ businessType.name }}</span></label
                              >
                            </li>
                          </ul>
                        </template>
                      </div>
                    </transition>
                    <!-- /#maker -->
                  </li>
                </ul>
              </div>
              <!-- / -->

              <div class="modal_selectnav_submit">
                <button type="button" class="btn_submit" @click="commit">
                  <span v-if="dialogType == 'search'">検索</span>
                  <span v-else>内容を反映する</span>
                </button>
              </div>

              <span class="modalwin_close_icn" @click="close"
                ><span>閉じる</span></span
              >
              <!-- <span class="modalwin_close_btn"><span>閉じる</span></span> -->
            </div>
          </div>
          <!-- /modalwin -->
        </div>
        <!-- /modalstage -->
      </template>
    </transition>

    <!-- 新しいモーダルここまで -->
    <!-- /modalstage -->
    <transition :name="$mq == 'pc' ? 'modalBack' : 'slideModalBack'">
      <span class="stage" v-if="visible" style="z-index:10000"></span>
    </transition>
  </div>
</template>

<script>
// import CodeHelper from "@/helpers/code-helper";

export default {
  name: "JobCategoryDialog",
  data() {
    return {
      checked: [],
      selectedCategory: null,
      parentExpanded: {},
      openedCategories: [],
    };
  },
  computed: {
    constantsJobCategories() {
      return this.$store.state.constants.jobCategories;
    },
    expanded() {
      return (code) => {
        if (this.$mq == "pc") return true;

        return this.parentExpanded[code];
      };
    },
    isCategoryOpenned() {
      return (selectedCategory) => {
        return (
          this.openedCategories.filter((c) => c.code == selectedCategory.code)
            .length > 0
        );
      };
    },
    isSelectedCategoryChecked() {
      if (this.selectedCategory) {
        for (const subcategory of this.selectedCategory.children) {
          if (
            this.checked.filter((checked) => {
              return checked.code == subcategory.code;
            }).length == 0
          ) {
            return false;
          }
        }
      }
      return true;
    },
    isSelectedSubCategoryChecked() {
      return (parentCode) => {
        for (const item of parentCode.children) {
          if (
            this.checked.filter((checked) => {
              return checked.code == item.code;
            }).length == 0
          )
            return false;
        }
        return true;
      };
    },
    hasChildren() {
      return (parentCode) => {
        if (parentCode) {
          for (const subcategory of parentCode.children) {
            if (
              this.checked.filter((checked) => {
                return checked.code == subcategory.code;
              }).length > 0
            ) {
              return true;
            }
          }
          return false;
        } else {
          return false;
        }
      };
    },
  },
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    values: Array,
    dialogType: String,
  },
  methods: {
    close() {
      this.$emit("change", false);
    },
    commit() {
      this.$emit("commit", this.checked);
      this.$emit("change", false);
    },
    toggle(code) {
      this.$set(this.parentExpanded, code, !this.expanded(code));
    },
    toggleCategory(category) {
      if (
        this.openedCategories.filter((c) => c.code == category.code).length > 0
      ) {
        this.openedCategories = this.openedCategories.filter(
          (c) => c.code !== category.code
        );
      } else {
        this.openedCategories.push(category);
      }
      this.selectedCategory = category;
    },
    changeSelectedCategory(event) {
      if (this.selectedCategory) {
        if (event.target.checked) {
          for (const subcategory of this.selectedCategory.children) {
            this.checked = this.checked.filter((checked) => {
              return checked.code != subcategory.code;
            });
            this.checked.push(subcategory);
          }
        } else {
          for (const subcategory of this.selectedCategory.children) {
            this.checked = this.checked.filter((checked) => {
              return checked.code != subcategory.code;
            });
          }
          this.checked = this.checked.filter((checked) => {
            return checked.code != this.selectedCategory.code;
          });
        }
      }
    },
    changeSelectedSubCategory(selectedCategory, event) {
      if (event.target.checked) {
        for (const item of selectedCategory.children) {
          this.checked = this.checked.filter((checked) => {
            return checked.code != item.code;
          });
          this.checked.push(item);
        }
      } else {
        for (const item of selectedCategory.children) {
          this.checked = this.checked.filter((checked) => {
            return checked.code != item.code;
          });
        }
        this.checked = this.checked.filter((checked) => {
          return checked.code != selectedCategory.code;
        });
      }
    },
    isParentCategorySelected() {
      // this.checkedに親カテゴリが入っていたら
      // 親カテゴリのchildrenを全てcheckedに入れる
      if (this.checked && this.checked.length > 0) {
        var parentObj = this.checked.filter((d) => {
          return this.constantsJobCategories.includes(d);
        });

        if (parentObj && parentObj.length > 0) {
          for (var i = 0; i < parentObj.length; i++) {
            for (const item of parentObj[i].children) {
              this.checked = this.checked.filter((checked) => {
                return checked.code != item.code;
              });
              this.checked.push(item);
            }
          }
        }
      }
    },
  },
  watch: {
    isSelectedCategoryChecked() {
      if(!this.isSelectedCategoryChecked && this.visible){
        this.checked = this.checked.filter((checked) => {
          return checked.code != this.selectedCategory.code;
        });
      }else{
        console.log(this.checked)
      }
    },
    values() {
      this.checked = this.values;
    },
    selectedCategory() {
      this.isParentCategorySelected();
    },
    visible() {
      this.isParentCategorySelected();

      var bodyHtml = document.getElementsByTagName("body")[0];
      var html = document.getElementsByTagName("html")[0];
      if (this.visible) {
        if (this.$mq == "sp") {
          bodyHtml.className += " slidemodal";
          html.className += " noscroll";
        }
      } else {
        setTimeout(() => {
          if (this.$mq == "sp") {
            bodyHtml.classList.remove("slidemodal");
            html.classList.remove("noscroll");
          }
        }, 500);
      }
    },
  },
  mounted() {
    this.checked = this.values;
  },
};
</script>
