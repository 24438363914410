<template>
  <!-- ===== header/ ===== -->
  <header>
    <div class="header_container">
      <!-- === header_detail/ === -->
      <div class="header_detail header_login_on">
        <div class="header_siteid">
          <a class="siteid_logo" style="cursor: pointer">スキルが活きる、スキルで生きる スキイキ produced by マイナビ</a>
        </div>

        <nav class="header_nav_global">
          <ul class="header_nav_global_detail">
            <li class="header_nav_global_search">
              <a style="cursor: pointer">仕事を探す</a>
            </li>
            <li class="header_nav_global_favorite">
              <a style="cursor: pointer">
                気になる<span class="delsp">リスト</span>
                <!-- <span class="num">99</span> -->
              </a>
            </li>
            <li class="header_nav_global_magazine">
              <a style="cursor: pointer">
                <span class="delsp">スキイキ</span>マガジン
              </a>
            </li>
            <li class="header_nav_global_guide">
              <a style="cursor: pointer">
                使い方<span class="delsp">ガイド</span>
              </a>
            </li>
            <li class="header_nav_global_message">
              <a>メッセージ</a>
            </li>
            <li class="header_nav_global_scout">
              <a>スカウト</a>
            </li>
            <li class="header_nav_global_spnav header_spnav">
              <a class="header_spnav_btn">
                <span>
                  <span class="on">メニュー</span>
                  <span class="off">閉じる</span>
                </span>
              </a>
            </li>
          </ul>
        </nav>

        <nav class="header_nav_asign">
          <ul class="header_nav_asign_detail">
            <li class="header_nav_login">
              <a class="btn_login">
                <span>ログイン</span>
              </a>
            </li>
            <li class="header_nav_regist">
              <a class="btn_regist">
                <span>会員登録</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <!-- === /header_detail === -->

      <nav class="header_nav_aside_pc">
        <ul class="header_nav_aside_detail">
          <li>
            <a>お知らせ</a>
          </li>
          <li>
            <a>閲覧履歴</a>
          </li>
          <li>
            <a>お問い合わせ</a>
          </li>
        </ul>
      </nav>

      <!-- === header_nav_sp/ === -->
      <div class="header_nav_sp" id="sitenav">
        <nav class="header_nav_sp_menu">
          <div class="header_nav_sp_hgroup">
            <figure>
              <span class="userimage">
                <img src="/assets/images/common/prof_noimage.png" alt />
              </span>
            </figure>
            <p>
              マイナビ 太郎
              <span>（表示名：マイナビ太郎）</span>
            </p>
          </div>
          <ul class="header_nav_sp_list">
            <li>
              <a class="mypage">
                <span>マイページ</span>
              </a>
            </li>
            <li>
              <a class="manage">
                <span>仕事管理</span>
              </a>
            </li>
            <li>
              <a class="message">
                <span>メッセージチャット</span>
              </a>
            </li>
            <li>
              <a class="scout">
                <span>スカウト</span>
              </a>
            </li>
          </ul>
          <div class="header_nav_sp_jobsearch_box">
            <p class="header_nav_sp_hdg">
              仕事検索
              <span>
                <a>（新着XX件）</a>
              </span>
            </p>
            <ul class="header_nav_sp_jobsearch">
              <li>
                <a class="btn_jobcategory">
                  <span>仕事カテゴリから探す</span>
                </a>
              </li>
              <li>
                <a class="btn_workstyle">
                  <span>働き方から探す</span>
                </a>
              </li>
              <li>
                <a class="btn_jobcategory_all">
                  <span>全ての仕事から探す</span>
                </a>
              </li>
              <li>
                <a class="btn_skillsearch">
                  <span>登録スキルから探す</span>
                </a>
              </li>
            </ul>
          </div>
          <ul class="header_nav_sp_detail">
            <li>
              <a class="setting">
                <span>各種設定</span>
              </a>
            </li>
            <li>
              <a class="basicinfo">
                <span>基本情報</span>
              </a>
            </li>
            <li>
              <a class="profile">
                <span>プロフィール</span>
              </a>
            </li>
            <li>
              <a class="skill">
                <span>スキル・経験</span>
              </a>
            </li>
            <li>
              <a class="portfolio">
                <span>ポートフォリオ</span>
              </a>
            </li>

            <li>
              <a class="private">
                <span>非公開設定</span>
              </a>
            </li>

            <li>
              <a class="confirm">
                <span>本人確認書類</span>
              </a>
            </li>
            <li>
              <a class="bankdata">
                <span>振込先口座情報</span>
              </a>
            </li>
            <li>
              <a class="privacy">
                <span>機密保持の再確認</span>
              </a>
            </li>
          </ul>
          <ul class="header_nav_sp_list header_nav_sp_list_aside">
            <li>
              <a class="magazine">
                <span>スキイキマガジン</span>
              </a>
            </li>
            <li>
              <a class="guide">
                <span>使い方ガイド</span>
              </a>
            </li>
            <li>
              <a class="topics">
                <span>お知らせ</span>
              </a>
            </li>
            <li>
              <a class="history">
                <span>閲覧履歴</span>
              </a>
            </li>
            <li>
              <a class="contact">
                <span>お問い合わせ</span>
              </a>
            </li>
            <li>
              <a class="logout">
                <span>ログアウト</span>
              </a>
            </li>
          </ul>
          <div class="header_nav_sp_close">
            <a class="header_nav_sp_close_btn">
              <span>閉じる</span>
            </a>
          </div>
        </nav>
      </div>
      <!-- === /header_nav_sp===  -->
    </div>
    <!-- /header_container -->
  </header>
  <!-- ===== /header ===== -->
</template>

<script>
const listener = {
  methods: {
    listen: function (target, eventType, callback) {
      if (!this._eventRemovers) {
        this._eventRemovers = [];
      }
      target.addEventListener(eventType, callback);
      this._eventRemovers.push({
        remove: function () {
          target.removeEventListener(eventType, callback);
        },
      });
    },
  },
  destroyed: function () {
    if (this._eventRemovers) {
      this._eventRemovers.forEach(function (eventRemover) {
        eventRemover.remove();
      });
    }
  },
};

export default {
  name: "Header",
  props: {},
  mixins: [listener],
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    },
    profileImage() {
      if (this.$store.state.profile.profileImage) {
        return this.$store.state.profile.profileImage;
      }
      return "/assets/images/common/prof_noimage.png";
    },
  },
  methods: {
    menuLink(to) {
      this.showMenu = false;
      if (this.$route.path == to) return;
      this.$router.push(to);
    },
    closeMenu() {
      this.showMenu = false;
    },
  },
  created: function () {
    this.listen(
      window,
      "click",
      function (e) {
        if (this.showMenu) {
          if (!this.$refs.usermenu.contains(e.target)) {
            this.showMenu = false;
          }
        }
      }.bind(this)
    );
  },
  mounted() {
    if (this.isLoggedIn) {
      if (!this.$store.state.profile.loaded) {
        this.$store
          .dispatch("profile/getProfile")
          .then(() => {
            this.$store
              .dispatch("profileStatus/getProfileStatus")
              .then(() => {});
            this.$store.dispatch("unreadScouts/getScouts", 1).then(() => {});
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
