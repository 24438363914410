// import Vue from 'vue'

import PublicAPI from '@/api/public'

export default {
  namespaced: true,
  state: {
    guides: [],  // ガイド全体
    displayGuides: [],  // 表示用のガイド
    guide: {},
    freeWord: ''
  },
  mutations: {
    setGuides: (state, guides) => {
      if (guides) {
        state.guides = guides
      }
    },
    setDisplayGuides: (state, guides) => {
      if (guides) {
        state.displayGuides = guides
      }
    },
    setGuide: (state, guide) => {
      if (guide) {
        state.guide = guide
      }
    },
    setFreeWord: (state, word) => {
      state.freeWord = word
    }
  },
  actions: {
    getAllGuides: ({ commit }) => {
      return new Promise((resolve) => {
        (async () => {

          const result = await PublicAPI.getGuides().catch(e => {
            console.log(e)
            return false;
          })

          if (!result) {
            return false;
          }
          commit('setGuides', result.guide_list)
          commit('setDisplayGuides', result.guide_list)

          resolve()

        })();
      })
    },
    searchGuides: ({ state, commit }) => {
      return new Promise((resolve) => {
        (async () => {

          const result = await PublicAPI.getGuides(state.freeWord).catch(e => {
            console.log(e)
            return false;
          })

          if (!result) {
            return false;
          }
          commit('setDisplayGuides', result.guide_list)

          resolve()

        })();
      })
    },
    getCurrentGuide: ({ commit }, guideId) => {
      return new Promise((resolve) => {
        (async () => {

          const result = await PublicAPI.getGuide(guideId).catch(e => {
            console.log(e)
            return false;
          })

          if (!result) {
            return false;
          }
          commit('setGuide', result)

          resolve()

        })();
      })
    },
    getGuidePreviewKey: ({ commit }, key) => {
      return new Promise((resolve, reject) => {
        (async () => {

          const result = await PublicAPI.getGuidePreviewKey(key).catch(e => {
            console.log(e)
            reject(e)
          })

          if (!result) {
            reject()
          }
          commit('setGuide', result)

          resolve()

        })();
      })
    },
  },
  modules: {
  }
}