const axios = require('axios');

export default class {
  constructor(entryPoint) {
    this.entryPoint = entryPoint
    this.client = axios.create({
      baseURL: entryPoint,
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'json'
    })
  }

  getEntryPoint() {
    return this.entryPoint
  }

  get(user, path) {
    if (path) {
      return new Promise((resolve, reject) => {
        if (!user) {
          console.log("path is " , path, ": NoUserException")
          reject({
            code: "NoUserException",
            message: "Empty Result"
          })
          return
        }
        user.getSession((err, result) => {
          if (err) {
            console.log("path is " , path, ": CannotGetSessionException")
            reject(err)
            return
          }

          if (!result) {
            console.log("path is " , path, ": NoResultException")
            reject({
              code: "NoResultException",
              message: "Empty Result"
            })
            return
          }
          const d = '_=' + Date.now();
          this.client.get(path + (path.indexOf("?") > 0 ? '&' : '?') + d, {
            headers: {
              'Authorization': result.getIdToken().getJwtToken()
            }
          })
            .then(function (response) {
              resolve(response)
            })
            .catch(function (error) {
              console.log("path is " , path, ": ServerError")
              reject(error)
            });
        });
      })
    }
    else {
      path = user
      return new Promise((resolve, reject) => {
        this.client.get(path).then(function (response) {
          resolve(response)
        }).catch(function (error) {
          reject(error)
        });
      })
    }
  }

  post(user, path, requestBody) {
    if (requestBody) {
      return new Promise((resolve, reject) => {
        if (!user) {
          reject({
            code: "NoUserException",
            message: "Empty Result"
          })
          return
        }
        user.getSession((err, result) => {
          if (err) {
            reject(err)
            return
          }

          if (!result) {
            reject({
              code: "NoResultException",
              message: "Empty Result"
            })
            return
          }

          this.client.post(path, requestBody, {
            headers: {
              'Authorization': result.getIdToken().getJwtToken()
            }
          })
            .then(function (response) {
              resolve(response)
            })
            .catch(function (error) {
              reject(error)
            });
        });
      })
    }
    else {
      requestBody = path
      path = user

      return new Promise((resolve, reject) => {
        this.client.post(path, requestBody)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            reject(error)
          });
      })
    }
  }
}
