import { render, staticRenderFns } from "./MynaviCompFooter.vue?vue&type=template&id=51723cb4&scoped=true&"
import script from "./MynaviCompFooter.vue?vue&type=script&lang=js&"
export * from "./MynaviCompFooter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51723cb4",
  null
  
)

export default component.exports