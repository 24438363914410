// import Vue from 'vue'
import MemberAPI from '@/api/member'

export default {
    namespaced: true,
    state: {
        words: [],
        inputWord: '', //入力バリデーション用
    },
    mutations: {
        setWords(state, v) {
            state.words = v
        },
        addWord(state, v) {
            state.words.push(v)
        },
        removeWord(state, v) {
            state.words = state.words.filter((a) => {
                return a.code !== v.code
            })
        },
        setInputWord(state, v) {
            state.inputWord = v
        }
    },
    actions: {
        getWords: ({ commit }) => {
            return new Promise((resolve, reject) => {
                (async () => {

                    const result = await MemberAPI.getMuteWords().catch(e => {
                        console.log(e)
                        reject(e.error.response.data)
                    })

                    if (!result) {
                        return false;
                    }

                    console.log(result)
                    commit('setWords', result.words)

                    resolve()

                })();
            })
        },
        addWord: ({ commit }, word) => {
            return new Promise((resolve, reject) => {
                (async () => {

                    const result = await MemberAPI.postMuteWords({
                        code: null,
                        name: word
                    }).catch(e => {
                        console.log(e)
                        reject(e.error.response.data)
                    })

                    if (!result) {
                        return false;
                    }
                    commit('setInputWord', '')   // 入力欄を空にする
                    commit('addWord', result)

                    resolve()
                })();
            })
        },
        removeWord: ({ commit }, word) => {
            return new Promise((resolve,reject) => {
                (async () => {

                    const result = await MemberAPI.postMuteWordsCode(word.code).catch(e => {
                        console.log(e)
                        reject(e.error.response.data)
                    })

                    if (!result) {
                        return false;
                    }

                    commit('removeWord', result)

                    resolve()
                })();
            })
        }
    },
    modules: {
    }
}