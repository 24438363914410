<template>
  <!-- Headerコンポーネントをコピーして作成 -->
  <!-- Headerコンポーネントが変更になったらこっちも変更が必要 -->

  <!-- 変更方法 -->
  <!-- HEADERコンポーネントをこっちにコピペ -->
  <!-- 「router-link」を「a」に全部置換する -->
  <!-- 「to=」を「href=」へ全部置換する -->
  <!-- 終了 -->

  <div>
    <SimpleHeader v-if="this.simpleHeader"></SimpleHeader>

    <!-- ===== header/ ===== -->
    <header v-else>
      <div class="header_container">
        <p class="header_description">
          スキルで生きるフリーランス・副業・複業のスキルが活きる仕事探し【スキイキ】
        </p>

        <!-- === header_detail/ === -->
        <div class="header_detail">
          <div class="header_siteid">
            <a href="/" class="siteid_logo"
              >スキルが活きる、スキルで生きる スキイキ produced by マイナビ</a
            >
          </div>

          <nav class="header_nav_global">
            <ul class="header_nav_global_detail">
              <li class="header_nav_global_search">
                <a href="/job">仕事を探す</a>
              </li>
              <li class="header_nav_global_favorite">
                <a href="/interest">
                  気になる<span class="delsp">リスト</span>
                  <!-- リリース後対応 -->
                  <!-- <span class="num">99</span> -->
                </a>
              </li>
              <!-- リリース後対応 -->
              <li class="header_nav_global_magazine">
                <a href="/magazine">
                  <span class="delsp">スキイキ</span>マガジン
                </a>
              </li>
              <li class="header_nav_global_message" v-if="isLoggedIn">
                <a href="/mypage/message-chat" :class="{ mark: unreadFlg }"
                  >メッセージ</a
                >
              </li>
              <li class="header_nav_global_guide delsp">
                <a href="/guide"> 使い方<span class="delsp">ガイド</span> </a>
              </li>
              <li class="header_nav_global_scout delpc">
                <a href="/mypage/scout">
                  スカウト
                </a>
              </li>
              <li class="header_nav_global_spnav header_spnav">
                <a
                  class="header_spnav_btn"
                  :class="{ navon: showSpMenu }"
                  @click="showSpMenu = !showSpMenu"
                >
                  <span class="on" v-show="!showSpMenu">メニュー</span>
                  <span class="off" v-show="showSpMenu">閉じる</span>
                </a>
              </li>
            </ul>
          </nav>
          <!-- ここまで確認済み -->

          <!-- 非ログイン時のヘッダー -->
          <nav class="header_nav_asign" v-if="!isLoggedIn">
            <ul class="header_nav_asign_detail">
              <li class="header_nav_login">
                <a href="/login" class="btn_login">
                  <span>ログイン</span>
                </a>
              </li>
              <li class="header_nav_regist">
                <a href="/signup" class="btn_regist">
                  <span>会員登録</span>
                </a>
              </li>
            </ul>
          </nav>

          <!-- ログイン時のヘッダー -->
          <nav class="header_nav_member" v-show="isLoggedIn">
            <ul class="header_nav_member_detail">
              <li class="header_nav_user delsp">
                <a
                  @click="showMenu = !showMenu"
                  class="header_subnav_btn"
                  ref="usermenu"
                >
                  <span class="userimage">
                    <img :src="profileImage" alt />
                  </span>
                </a>
                <ul class="header_nav_user_menu" :class="{ navon: showMenu }">
                  <li class="mypage">
                    <a @click="menuLink('/mypage')">
                      <span>マイページ</span>
                    </a>
                  </li>
                  <li class="setting">
                    <a @click="menuLink('/mypage/setting')">
                      <span>各種設定</span>
                    </a>
                  </li>
                  <li class="profile">
                    <a @click="menuLink('/mypage/setting/profile')">
                      <span>プロフィール編集</span>
                    </a>
                  </li>
                  <li class="logout">
                    <a @click="menuLink('/logout')">
                      <span>ログアウト</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li class="header_nav_user delpc">
                <a href="/mypage"
                  ><span class="userimage"
                    ><img :src="profileImage" alt=""/></span
                ></a>
              </li>
              <li class="header_nav_message">
                <a href="/mypage/message-chat" :class="{ mark: unreadFlg }">
                  <span>メッセージ</span>
                </a>
              </li>
              <li class="header_nav_scout">
                <a href="/mypage/scout">
                  <span>スカウト</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <!-- === /header_detail === -->

        <nav class="header_nav_aside_pc">
          <ul class="header_nav_aside_detail">
            <li>
              <a href="/topic">お知らせ</a>
            </li>
            <li>
              <a href="/history">閲覧履歴</a>
            </li>
            <li>
              <a href="/contact">お問い合わせ</a>
            </li>
            <li class="for_publish" v-if="!isLoggedIn">
              <a href="/publish">
                <span>掲載をお考えの方</span>
              </a>
            </li>
          </ul>
        </nav>

        <template v-if="isLoggedIn">
          <!-- === header_nav_sp/ === -->
          <div class="header_nav_sp" :class="{ header_nav_sp_on: showSpMenu }">
            <nav class="header_nav_sp_menu">
              <div class="header_nav_sp_hgroup">
                <figure>
                  <span class="userimage">
                    <img :src="profileImage" alt />
                  </span>
                </figure>
                <p>
                  {{ profile.familyName }} {{ profile.name }}
                  <span v-if="profile.displayName"
                    >（表示名：{{ profile.displayName }}）</span
                  >
                </p>
              </div>

              <ul class="header_nav_sp_list">
                <li>
                  <a href="/mypage" class="mypage">
                    <span>マイページ</span>
                  </a>
                </li>
                <li>
                  <a href="/mypage/works" class="manage">
                    <span>仕事管理</span>
                  </a>
                </li>
                <li>
                  <a href="/mypage/message-chat" class="message">
                    <span>メッセージチャット</span>
                  </a>
                </li>
                <li>
                  <a href="/mypage/scout" class="scout">
                    <span>スカウト</span>
                  </a>
                </li>
                <li>
                  <a href="/mypage/reward" class="money">
                    <span>報酬管理</span>
                  </a>
                </li>
              </ul>
              <div class="hedaer_nav_sp_jobsearch_box">
                <p class="header_nav_sp_hdg">
                  仕事検索
                  <span>
                    <a href="/job?n=true">（新着{{ newJobCount }}件）</a>
                  </span>
                </p>
                <ul class="header_nav_sp_jobsearch">
                  <li>
                    <a
                      @click="visibleJobCategories = true"
                      class="btn_jobcategory"
                    >
                      <span>仕事カテゴリから探す</span>
                    </a>
                  </li>
                  <li>
                    <a href="/job/W1+W2+W3" class="btn_workstyle">
                      <span>働き方から探す</span>
                    </a>
                  </li>
                  <li>
                    <a href="/job" class="btn_jobcategory_all">
                      <span>全ての仕事から探す</span>
                    </a>
                  </li>
                  <li>
                    <a href="/job?sm=true" class="btn_skillsearch">
                      <span>登録スキルから探す</span>
                    </a>
                  </li>
                </ul>
              </div>

              <ul class="header_nav_sp_detail">
                <li>
                  <a href="/mypage/setting" class="setting">
                    <span>各種設定</span>
                  </a>
                </li>
                <li>
                  <a href="/mypage/setting/basic" class="basicinfo">
                    <span>基本情報</span>
                  </a>
                </li>
                <li>
                  <a href="/mypage/setting/profile" class="profile">
                    <span>プロフィール</span>
                  </a>
                </li>
                <li>
                  <a href="/mypage/setting/skill" class="skill">
                    <span>スキル・経験</span>
                  </a>
                </li>
                <li>
                  <a href="/mypage/setting/portfolio" class="portfolio">
                    <span>ポートフォリオ</span>
                  </a>
                </li>

                <li>
                  <a href="/mypage/setting/mute" class="private">
                    <span>非公開設定</span>
                  </a>
                </li>

                <li>
                  <a href="/mypage/setting/verify" class="confirm">
                    <span>本人確認書類</span>
                  </a>
                </li>
                <li>
                  <a href="/mypage/setting/bank" class="bankdata">
                    <span>振込先口座情報</span>
                  </a>
                </li>
                <li>
                  <a href="/mypage/setting/nda" class="privacy">
                    <span>機密保持の再確認</span>
                  </a>
                </li>
              </ul>

              <ul class="header_nav_sp_list headernav_sp_list_aside">
                <!-- リリースマガジン -->
                <li>
                  <a href="/magazine" class="magazine"
                    ><span>スキイキマガジン</span></a
                  >
                </li>
                <li>
                  <a href="/guide" class="guide"><span>使い方ガイド</span></a>
                </li>
                <li>
                  <a href="/topic" class="topics">
                    <span>お知らせ</span>
                  </a>
                </li>
                <li>
                  <a href="/history" class="history">
                    <span>閲覧履歴</span>
                  </a>
                </li>
                <li>
                  <a href="/contact" class="contact">
                    <span>お問い合わせ</span>
                  </a>
                </li>
                <li>
                  <a href="/logout" class="logout">
                    <span>ログアウト</span>
                  </a>
                </li>
              </ul>
              <div class="header_nav_sp_close">
                <a href="#maincontents" class="header_nav_sp_close_btn">
                  <span>閉じる</span>
                </a>
              </div>
            </nav>
          </div>
          <!-- === /header_nav_sp===  -->
        </template>

        <!-- 非ログイン時 -->
        <template v-else>
          <!-- === header_nav_sp/ === -->
          <div class="header_nav_sp" :class="{ header_nav_sp_on: showSpMenu }">
            <nav class="header_nav_sp_menu">
              <ul class="header_nav_sp_asign">
                <li>
                  <a href="/login" class="btn_login">
                    <span>ログイン</span>
                  </a>
                </li>
                <li>
                  <a href="/signup" class="btn_regist">会員登録</a>
                </li>
              </ul>
              <ul class="header_nav_sp_about">
                <li>
                  <!-- 本番環境ではリンク先が「/guide/27」が正しい。開発環境では「/guide/1」が正。 -->
                  <a href="/guide/27" class="btn_help">
                    <span>スキイキとは？</span>
                  </a>
                </li>
              </ul>
              <div class="header_nav_sp_jobsearch_box">
                <p class="header_nav_sp_hdg">
                  仕事検索
                  <span>
                    <a href="/job?n=true">（新着{{ newJobCount }}件）</a>
                  </span>
                </p>
                <ul class="header_nav_sp_jobsearch">
                  <li>
                    <a
                      @click="visibleJobCategories = true"
                      class="btn_jobcategory"
                    >
                      <span>仕事カテゴリから探す</span>
                    </a>
                  </li>
                  <li>
                    <a href="/job/W1+W2+W3" class="btn_workstyle">
                      <span>働き方から探す</span>
                    </a>
                  </li>
                </ul>
              </div>

              <ul class="header_nav_sp_list header_nav_sp_list_aside">
                <li>
                  <a href="/topic" class="topics">
                    <span>お知らせ</span>
                  </a>
                </li>
                <li>
                  <a href="/history" class="history">
                    <span>閲覧履歴</span>
                  </a>
                </li>
                <li>
                  <a href="/contact" class="contact">
                    <span>お問い合わせ</span>
                  </a>
                </li>
                <li>
                  <a href="/publish" class="publish">
                    <span>掲載をお考えの方</span>
                  </a>
                </li>
              </ul>
              <div class="header_nav_sp_close">
                <a
                  class="header_nav_sp_close_btn"
                  @click="showSpMenu = !showSpMenu"
                >
                  <span>閉じる</span>
                </a>
              </div>
            </nav>
          </div>
          <!-- === /header_nav_sp===  -->
        </template>

        <JobCategoryDialog
          v-model="visibleJobCategories"
          :values="checkedJobCategories"
          :dialogType="'search'"
          @commit="commitJobCategoriesCheck"
        ></JobCategoryDialog>
      </div>
      <!-- /header_container -->
    </header>
    <!-- ===== /header ===== -->
  </div>
</template>

<script>
import JobCategoryDialog from "@/components/job-search/JobCategoryDialog";
import SimpleHeader from "@/components/partial/SimpleHeader";

const listener = {
  methods: {
    listen: function(target, eventType, callback) {
      if (!this._eventRemovers) {
        this._eventRemovers = [];
      }
      target.addEventListener(eventType, callback);
      this._eventRemovers.push({
        remove: function() {
          target.removeEventListener(eventType, callback);
        },
      });
    },
  },
  destroyed: function() {
    if (this._eventRemovers) {
      this._eventRemovers.forEach(function(eventRemover) {
        eventRemover.remove();
      });
    }
  },
};

export default {
  name: "Header",
  props: {},
  mixins: [listener],
  data() {
    return {
      visibleJobCategories: false,
      checkedJobCategories: [],
      showMenu: false,
      showSpMenu: false,
      simpleHeader: false,
    };
  },
  components: {
    JobCategoryDialog,
    SimpleHeader,
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    },
    profile() {
      if (this.isLoggedIn) {
        return this.$store.state.profile;
      } else {
        return {};
      }
    },
    profileImage() {
      if (this.$store.state.profile.profileImage) {
        return this.$store.state.profile.profileImage;
      }
      return "/assets/images/common/prof_noimage.png";
    },
    newJobCount() {
      return this.$store.state.jobs.newJobInfo.newJobCount;
    },
    unreadFlg() {
      if (this.isLoggedIn) {
        if (this.$store.state.profile.messageUnreadFlg) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },

  methods: {
    commitJobCategoriesCheck(checkedList) {
      this.checkedJobCategories = checkedList; //  このcheckedListはコンポーネントから受け取った値
      this.searchByJobCategory();
    },
    searchByJobCategory() {
      this.$store.commit("jobsearch/resetConditions");
      this.$store.commit(
        "jobsearch/setCheckedJobCategories",
        this.checkedJobCategories
      );
      this.$store.commit("jobsearch/setCurrentPage", 1);
      // this.showConditionSP = false;

      const path = this.$store.getters["jobsearch/path"];
      this.$router.push(path);
    },
    menuLink(to) {
      this.showMenu = false;
      if (this.$route.path == to) return;
      this.$router.push(to);
    },
    closeMenu() {
      this.showMenu = false;
    },
  },
  created: function() {
    this.listen(
      window,
      "click",
      function(e) {
        if (this.showMenu) {
          if (!this.$refs.usermenu.contains(e.target)) {
            this.showMenu = false;
          }
        }
      }.bind(this)
    );
  },
  watch: {
    $route(to) {
      this.showSpMenu = false;
      to.name == "JobIdEntry2"
        ? (this.simpleHeader = true)
        : (this.simpleHeader = false);
    },
    showSpMenu() {
      if (this.showSpMenu) {
        this.$store.commit("profile/setShowSpModalBack", true);
      } else {
        this.$store.commit("profile/setShowSpModalBack", false);
      }
    },
  },
  mounted() {
    if (this.isLoggedIn) {
      if (!this.$store.state.profile.loaded) {
        this.$store
          .dispatch("profile/getProfile")
          .then(() => {
            this.$store
              .dispatch("profileStatus/getProfileStatus")
              .then(() => {});
            this.$store.dispatch("unreadScouts/getScouts", 1).then(() => {});
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }

    // 新着案件情報（件数のみ）の取得
    this.$store.dispatch("jobs/getNewJobInfo", true);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
