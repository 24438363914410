import MemberAPI from '@/api/member'

export default {
    namespaced: true,
    state: {
        progress: 0,
        profile: null,
        skill: null,
        verify: null,
        selfCheck: null,
        bank: null,
        nda: null,
        contractStatuses: null,
        enteredJobStatuses: null,
        interestJobStatuses: null,

        withdrawalPossible: false,
    },
    mutations: {
        setProfileStatus: (state, result)=>{
            state.progress = result.progress
            state.profile = result.profile
            state.skill = result.skill
            state.verify = result.verify
            state.selfCheck = result.selfCheck
            state.bank = result.bank
            state.nda = result.nda
            state.contractStatuses = result.contractStatuses
            state.enteredJobStatuses = result.enteredJobStatuses
            state.interestJobStatuses = result.interestJobStatuses
            state.withdrawalPossible = result.withdrawalPossible
        }
    },
    actions: {
        getProfileStatus: ({commit}) => {
            return new Promise((resolve)=>{
                (async()=>{
        
                    const result = await MemberAPI.getProfileStatus().catch(e=>{
                        console.log(e)
                        return false;
                    })

                    if(!result){
                        return false;
                    }
                    commit('setProfileStatus', result)
            
                    resolve()
        
                })();
            })
        }
    },
    modules: {
    }
  }