// import Vue from 'vue'

import PublicAPI from '@/api/public'
import MemberAPI from '@/api/member'

export default {
  namespaced: true,
  state: {
    topics: [],
    topic: {},
  },
  mutations: {
    setTopics: (state, topics) => {
      if (topics) {
        state.topics = topics
      }
    },
    setTopic: (state, topic) => {
      if (topic) {
        state.topic = topic
      }
    },
  },
  actions: {
    getAllTopics: ({ commit }) => {
      return new Promise((resolve) => {
        (async () => {

          const result = await PublicAPI.getTopics().catch(e => {
            console.log(e)
            return false;
          })

          if (!result) {
            return false;
          }

          console.log(result)
          commit('setTopics', result)

          resolve()

        })();
      })
    },

    getAllMemberTopics: ({ commit }) => {
      return new Promise((resolve) => {
        (async () => {

          const result = await MemberAPI.getMemberTopics().catch(e => {
            console.log(e)
            return false;
          })

          if (!result) {
            return false;
          }
          commit('setTopics', result)

          resolve()

        })();
      })
    },
    getCurrentTopic: ({ commit }, topicId) => {
      return new Promise((resolve) => {
        (async () => {

          const result = await PublicAPI.getTopic(topicId).catch(e => {
            console.log(e)
            return false;
          })

          if (!result) {
            return false;
          }
          commit('setTopic', result)

          resolve()

        })();
      })
    },
    getCurrentMemberTopic: ({ commit }, topicId) => {
      return new Promise((resolve) => {
        (async () => {
          const result = await MemberAPI.getMemberTopic(topicId).catch(e => {
            console.log(e)
            return false;
          })
          if (!result) {
            return false;
          }
          commit('setTopic', result)

          resolve()

        })();
      })
    },
  },
  modules: {
  }
}