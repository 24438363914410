import Vue from 'vue'

import PublicAPI from '@/api/public'
import MemberAPI from '@/api/member'

export default {
  namespaced: true,
  state: {
    interestJobs: []
  },
  mutations: {
    clear(state) {
      state.interestJobs = []
    },

    addToInterest(state, job) {
      if (job) {
        if (state.interestJobs) {
          if (state.interestJobs.filter((interest) => {
            return interest.id == job.id
          }).length > 0) return
          state.interestJobs.push(job)
        }
      }
    },

    removeFromInterest(state, jobId) {
      state.interestJobs = state.interestJobs.filter((interest) => {
        return interest.id != jobId
      })
    },

    updateInterestJob(state, status) {
      for (const i in state.interestJobs) {
        const interestJob = state.interestJobs[i]
        if (interestJob.companyId == status.companyId) {
          // 更新内容を更新
          // interestJob.jobCount = status.jobCount
          // interestJob.published = status.published
          // interestJob.archived = status.archived
          Vue.set(state.interestJobs, i, interestJob)
          return
        }
      }
    },

    setInterestJobs(state, v) {
      if (v) {
        state.interestJobs = v;
      }
    },

    storeState(state) {
      const props = Object.keys(state)
      for (const i in props) {
        const prop = props[i];

        window.localStorage.setItem('store.state.interestJobs.' + prop, JSON.stringify(state[prop]));
      }
    },

    restoreState(state) {
      const props = Object.keys(state)
      for (const i in props) {
        const prop = props[i];

        const rawItem = window.localStorage.getItem('store.state.interestJobs.' + prop)
        if (rawItem) {
          const value = JSON.parse(rawItem)

          Vue.set(state, prop, value)
        }
      }
    },
  },
  actions: {
    loadFromLocal({ commit }) {
      return new Promise((resolve) => {
        commit('restoreState')
        resolve()
      });
    },
    loadFromRemote({ commit }) {
      return new Promise((resolve, reject) => {
        (async () => {
          const result = await MemberAPI.getInterestJobs().catch(e => {
            console.log(e)
            return false;
          })

          if (!result) {
            reject()
            return
          }
          commit('setInterestJobs', result.interestJobs)

          // localStorageをクリア
          window.localStorage.setItem('store.state.interestJobs.interestJobs', []);

          resolve()
        })()
      });
    },
    getInterestJobs({ commit, state }) {
      return new Promise((resolve, reject) => {
        (async () => {
          const jobIds = []

          if (state.interestJobs) {
            state.interestJobs.forEach(interestJob => {
              jobIds.push(interestJob.id)
            })
          }

          const result = await MemberAPI.getInterestJobs().catch(e => {
            console.log(e)
            return false;
          })

          if (!result) {
            reject()
            return
          }
          if (result.interestJobs) {
            result.interestJobs.forEach((interestJob) => {
              commit('updateInterestJob', interestJob)
            })
          }

          resolve()
        })()
      });
    },
    add({ commit, rootState }, { jobId, interestedDevice, scoutId }) {
      return new Promise((resolve, reject) => {
        if (rootState.auth.isLoggedIn) {
          (async () => {

            const jobInfo = await MemberAPI.postInterests(jobId,
              { "interestedDevice": interestedDevice, "scoutId" : scoutId ? scoutId : null })
              .catch((e) => {
                reject(e)
              })

            if (!jobInfo) {
              reject()
            }

            commit('addToInterest', jobInfo)
            resolve()
          })();
        }
        else {
          (async () => {
            const jobInfo = await PublicAPI.getJob(jobId).catch(e => {
              console.log(e)
              return false;
            })

            if (!jobInfo) {
              reject()
              return
            }
            commit('addToInterest', {
              alreadyEntry: false,
              archived: false,
              companyId: jobInfo.companyId,
              companyImage: jobInfo.companyImage,
              companyName: jobInfo.companyName,
              features: jobInfo.features,
              id: jobInfo.id,
              image: jobInfo.image,
              interestedDate: Date.now(),
              interestedDevice: interestedDevice,
              lastUpdatedDate: jobInfo.lastUpdateDate,
              new: jobInfo.isNew,
              isPrivate: jobInfo.isPrivate,
              publishEndDate: jobInfo.publishEndDate,
              published: jobInfo.isHiring,
              title: jobInfo.title,
            })
            commit('storeState')
            resolve()
          })();
        }
      });
    },
    remove({ commit, rootState }, { jobId }) {
      return new Promise((resolve, reject) => {
        if (rootState.auth.isLoggedIn) {
          (async () => {
            const jobInfo = await MemberAPI.postInterestsJobId(jobId).catch(e => {
              console.log(e)
              return false;
            })

            if (!jobInfo) {
              reject()
              return
            }

            commit('removeFromInterest', jobId)
            resolve()
          })();
        }
        else {
          (async () => {
            commit('removeFromInterest', jobId)
            commit('storeState')
            resolve()
          })();
        }
      });
    },
    clearLocal({ commit }) {
      return new Promise((resolve) => {
        commit('clear')
        commit('storeState')

        resolve();
      });
    },
    localToRemote({ commit, state, dispatch }) {
      if (state.interestJobs) {
        return new Promise((resolve) => {

          (async () => {
            const jobs = [];
            state.interestJobs.forEach((interest) => {
              jobs.push({ id: interest.id, interestedDevice: interest.interestedDevice });
            });


            for (const i in jobs) {
              await dispatch("add", {
                jobId: jobs[i].id,
                interestedDevice: jobs[i].interestedDevice
              });
            }

            resolve()
          })();
        })
          .then(() => {
            commit('clear')
            commit('storeState')
          });
      }
    }
  }
}
