import Vue from 'vue'

import MemberAPI from '@/api/member'

export default {
    namespaced: true,
    state: {
        scouts: [],
        totalCount: null,
        totalScouts: [],
        count: null,
        perPage: null,
        page: null,
        firstIndex: null,
        lastIndex: null,

        sort: {'code':'01', 'name':'受信日順'},
        filter: {'code':'02', 'name':'未開封・未対応のみ'},

        currentScout: null,
        unreadCount: null,

    },
    mutations: {
        setScoutResults: (state, result)=>{
            console.log(result)
            state.scouts = result.scouts
            state.totalCount = result.totalCount
            state.count = result.count
            state.perPage = result.perPage
            state.page = result.page
            state.firstIndex = result.firstIndex
            state.lastIndex = result.lastIndex
            state.unreadCount = result.unreadCount
        },
        updateScout:(state, scout)=>{
            if(scout){
                for(const i in state.scouts){
                    if( state.scouts[i].id == scout.id ){
                        Vue.set(state.scouts, i, scout)
                    }
                }
            }
        },
        setCurrentScout: (state, scout)=>{
            state.currentScout = scout
        },
        setTotalScouts: (state,scouts)=>{
            state.totalScouts = scouts
        }
    },
    actions: {
        getAllScouts: ({commit}) => {
            return new Promise((resolve)=>{
                (async()=>{
                    const result = await MemberAPI.getScouts(1, '01', '01').catch(e=>{
                        console.log(e)
                        return false;
                    })

                    if(!result){
                        return false;
                    }

                    commit('setTotalScouts', result.scouts)
            
                    resolve()
        
                })();
            })
        },
        getScouts: ({commit, state}, page) => {
            return new Promise((resolve)=>{
                (async()=>{
                    const result = await MemberAPI.getScouts(page, state.filter.code, state.sort.code).catch(e=>{
                        console.log(e)
                        return false;
                    })

                    if(!result){
                        return false;
                    }

                    commit('setScoutResults', result)
            
                    resolve()
        
                })();
            })
        },
        getScout: ({commit, state}, scoutId) => {
            return new Promise((resolve, reject)=>{
                // スカウト一覧にあったら現在のスカウトに格納する？
                // const loaded = state.scouts.filter((a)=>{
                //     return a.id == scoutId
                // })

                // if(loaded.length>0){
                //     commit('setCurrentScout', loaded[0])
                //     resolve()
                // }
                // else{
                    (async()=>{
                        const result = await MemberAPI.getScout(scoutId).catch(e=>{
                            console.log(e)
                            reject(e)
                        })
    
                        if(!result){
                            reject()
                        }
    
                        commit('setCurrentScout', result)
                        commit('updateScout', result)
                        console.log(state)
                        resolve()
                    })();
                // }
            });
        },

        postScout: ({commit},scoutId) => {  // スカウト辞退
            return new Promise((resolve) => {
                (async () => {
                    const result = await MemberAPI.postScout(scoutId).catch(e => {
                        console.log(e)
                        return false
                    })
                    if (!result) {
                        return false;
                    }

                    commit('updateScout', result)
                    resolve(result)
                })();
            })
        }
    },
    modules: {
    }
  }