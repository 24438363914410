<template>
  <header class="contents_single">
    <div class="header_container">
      <!-- === header_detail/ === -->
      <div class="header_detail">
        <a href="/" class="header_siteid">
          <span class="siteid_logo"
            >スキルが活きる、スキルで生きる スキイキ produced by マイナビ</span
          >
        </a>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "SimpleHeader",
  data() {
    return {};
  },
};
</script>

<style scoped>
</style>
