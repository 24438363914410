export default {
  namespaced: true,
  state: {
    domains: [
      'yahoo.co.jp',
      'gmail.com',
      'ezweb.ne.jp',
      'au.com',
      'docomo.ne.jp',
      'i.softbank.jp',
      'softbank.ne.jp',
      'excite.co.jp',
      'hotmail.co.jp',
      'hotmail.com',
      'icloud.com',
      'live.jp',
      'me.com',
      'mineo.jp',
      'nifty.com',
      'outlook.com',
      'outlook.jp',
      'yahoo.ne.jp',
      'ybb.ne.jp',
      'ymobile.ne.jp',
    ],
    defaultDomains: [],
    emailDropdownClasses: "formtable_input email-dropdown-input"
  },
  mutations: {
    setEmailDropdownClasses(state, classText) {
      state.emailDropdownClasses = classText
    }
  },
  actions: {
  },
}