import MemberAPI from '@/api/member'

export default {
    namespaced: true,
    state: {
        confirmed: false,
        confirmedDate: null
    },
    mutations: {
        setNDA: (state, result)=>{
            state.confirmed = result.confirmed
            if(result.confirmedDate){
                state.confirmedDate = result.confirmedDate
            }
        }
    },
    actions: {
        getNDA: ({commit}) => {
            return new Promise((resolve)=>{
                (async()=>{

                    const result = await MemberAPI.getNDA().catch(e=>{
                        console.log(e)
                        return false;
                    })

                    if(!result){
                        return false;
                    }
                    commit('setNDA', result)

                    resolve()

                })();
            })
        },
        postNDA: ({commit}, v) => {
            return new Promise((resolve)=>{
                (async()=>{
        
                    const result = await MemberAPI.postNDA(v).catch(e=>{
                        console.log(e)
                        return false;
                    })

                    if(!result){
                        return false;
                    }
                    commit('setNDA', result)
            
                    resolve()
        
                })();
            })
        }
    },
    modules: {
    }
  }