export default {
  methods: {
    scrollTop() {
      var duration = 1000;
      var Ease = {
        easeOutExpo: (currentTime, startValue, changeValue, duration) => {
          return (
            changeValue *
            (-1 * Math.pow(2, (-10 * currentTime) / duration) + 1) +
            startValue
          );
        },
      };
      // href属性の値を取得
      // var href = this.targetElement.getAttribute('href');

      // 現在のスクロール位置を取得（クロスブラウザに対応）
      var currentPosition =
        document.documentElement.scrollTop || document.body.scrollTop;

      // スクロール先の要素を取得
      var targetElement = document.getElementsByTagName("body")[0];

      // スクロール先の要素が存在する場合はスムーススクロールを実行
      if (targetElement) {
        // デフォルトのイベントアクションをキャンセル
        // this.preventDefault();
        // this.stopPropagation();

        // スクロール先の要素の位置を取得
        var targetPosition =
          window.pageYOffset + targetElement.getBoundingClientRect().top;

        // スタート時点の時間を取得
        var startTime = performance.now();

        // アニメーションのループを定義
        var loop = function (nowTime) {
          // スタートからの経過時間を取得
          var time = nowTime - startTime;

          // duration を1とした場合の経過時間を計算
          var normalizedTime = time / duration;

          // duration に経過時間が達していない場合はアニメーションを実行
          if (normalizedTime < 1) {
            // 経過時間とイージングに応じてスクロール位置を変更
            window.scrollTo(
              0,
              Ease.easeOutExpo(
                time,
                currentPosition,
                targetPosition - currentPosition,
                duration
              )
            );

            // アニメーションを継続
            requestAnimationFrame(loop);

            // duration に経過時間が達したら、アニメーションを終了
          } else {
            window.scrollTo(0, targetPosition);
          }
        };

        // アニメーションをスタート
        requestAnimationFrame(loop);
      }
    },
    scrollTopMagazineId() {
      var duration = 10;
      var Ease = {
        // easingLinear: (currentTime, startValue, changeValue, duration) => {
        //   return changeValue * currentTime / duration + startValue;
        // },
        easeOutExpo: (currentTime, startValue, changeValue, duration) => {
          return (
            changeValue *
            (-1 * Math.pow(2, (-10 * currentTime) / duration) + 1) +
            startValue
          );
        },
      };
      console.log(Ease)
      // href属性の値を取得
      // var href = this.targetElement.getAttribute('href');

      // 現在のスクロール位置を取得（クロスブラウザに対応）
      var currentPosition =
        document.documentElement.scrollTop || document.body.scrollTop;

      // スクロール先の要素を取得
      var targetElement = document.getElementsByTagName("body")[0];
      console.log(targetElement)

      // スクロール先の要素が存在する場合はスムーススクロールを実行
      if (targetElement) {
        // デフォルトのイベントアクションをキャンセル
        // this.preventDefault();
        // this.stopPropagation();

        // スクロール先の要素の位置を取得
        var targetPosition =
          window.pageYOffset + targetElement.getBoundingClientRect().top;

        // スタート時点の時間を取得
        var startTime = performance.now();

        // アニメーションのループを定義
        var loop = function (nowTime) {
          // スタートからの経過時間を取得
          var time = nowTime - startTime;

          // duration を1とした場合の経過時間を計算
          var normalizedTime = time / duration;

          // duration に経過時間が達していない場合はアニメーションを実行
          if (normalizedTime < 1) {
            // 経過時間とイージングに応じてスクロール位置を変更
            window.scrollTo(
              0,
              Ease.easeOutExpo(
                time,
                currentPosition,
                targetPosition - currentPosition,
                duration
              )
            );

            // アニメーションを継続
            requestAnimationFrame(loop);

            // duration に経過時間が達したら、アニメーションを終了
          } else {
            window.scrollTo(0, targetPosition);
          }
        };

        // アニメーションをスタート
        requestAnimationFrame(loop);
      }
    }
  }
}
