import Vue from 'vue'

import PublicAPI from '@/api/public'

export default {
    namespaced: true,
    state: {
        skills: {}
    },
    mutations: {
        setSkills: (state, { category, subcategory, skills }) => {
            Vue.set(state.skills, category.code + "-" + subcategory.code, skills)
        },
    },
    actions: {
        getSkills: ({ commit }, { category, subcategory }) => {
            return new Promise((resolve) => {
                (async () => {

                    const skills = await PublicAPI.getConstantsSkillsCategoryCodeSubCategoryCode(category.code, subcategory.code).catch(e => {
                        console.log(e)
                        return false;
                    })

                    if (!skills) {
                        return false;
                    }
                    commit('setSkills', { category, subcategory, skills })

                    resolve()

                })();
            })
        },
    },
    modules: {
    }
}