import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute
} from 'amazon-cognito-identity-js'

export default class Cognito {
  configure(config) {
    this.options = config

    this.userPool = new CognitoUserPool({
      UserPoolId: this.options.UserPoolId,
      ClientId: this.options.ClientId,
    })

    const usePersistentSession = window.localStorage.getItem("persistentSession")
    const isProxy = window.localStorage.getItem("proxy")

    if (usePersistentSession !== 't' && isProxy != "true") {
      if (document.cookie.indexOf("temporarySession=t") < 0) {
        this.logout();
      }
    }
  }

  /**
   * username, passwordでサインアップ
   */
  signUp(username, password, attributes = []) {
    const attributeList = []
    for (const i in attributes) {
      const attribute = attributes[i]
      attributeList.push(new CognitoUserAttribute(attribute))
    }
    return new Promise((resolve, reject) => {
      this.userPool.signUp(username, password, attributeList, null, (err, result) => {
        if (err) {
          reject(err)
        } else {
          resolve(result)
        }
      })
    })
  }

  /**
   * 確認コードからユーザーを有効化する
   */
  confirmation(username, confirmationCode) {
    const userData = { Username: username, Pool: this.userPool }
    const cognitoUser = new CognitoUser(userData)
    return new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(confirmationCode, true, (err, result) => {
        if (err) {
          reject(err)
        } else {
          resolve(result)
        }
      })
    })
  }

  /**
   * username, passwordでログイン
   */
  login(username, password) {
    const userData = {
      Username: username,
      Pool: this.userPool,
      Storage: this.storage
    }
    const cognitoUser = new CognitoUser(userData)
    const authenticationData = { Username: username, Password: password }
    const authenticationDetails = new AuthenticationDetails(authenticationData)

    return new Promise((resolve, reject) => {
      const authConfig = {
        onSuccess(result) {
          resolve(result);
        },
        onFailure(err) {
          reject(err);
        },
        newPasswordRequired(userAttributes, requiredAttributes) {
          console.log("newPasswordRequired()");
          console.log(userAttributes, requiredAttributes);
          cognitoUser.completeNewPasswordChallenge(password, {}, authConfig);
        },
        mfaRequired(challengeName, challengeParameters) {
          console.log("mfaRequired()");
          console.log(challengeName, challengeParameters);
        },
        customChallenge(challengeParameters) {
          console.log("customChallenge()");
          console.log(challengeParameters);
        }
      };

      cognitoUser.authenticateUser(authenticationDetails, authConfig)
    })
  }

  /**
   * ログアウト
   */
  logout() {
	// 代理ログイン時、管理者がログアウトする際は、一般ユーザーをログアウトさせない
    const isProxy = window.localStorage.getItem("proxy")
	if ( isProxy == "true" ) return this.logOutForProxy()

    return new Promise(resolve => {
      console.log("logouting.")

      const cognitoUser = this.userPool.getCurrentUser()

      if (cognitoUser === null) {
        window.localStorage.removeItem("persistentSession")
        window.localStorage.removeItem("proxy")
        document.cookie = "temporarySession=; max-age=0"
        resolve()
      }

      cognitoUser.getSession((err, session) => {
        if (err) {
          cognitoUser.signOut()
          window.localStorage.removeItem("persistentSession")
          window.localStorage.removeItem("proxy")
          window.localStorage.removeItem("previousSearchPath")
          window.localStorage.removeItem("previousSearchConditionText");
          document.cookie = "temporarySession=; max-age=0"
          resolve()
        } else {
          if (!session.isValid()) {
            cognitoUser.signOut()
            window.localStorage.removeItem("persistentSession")
            window.localStorage.removeItem("proxy")
            window.localStorage.removeItem("previousSearchPath")
            window.localStorage.removeItem("previousSearchConditionText");
            document.cookie = "temporarySession=; max-age=0"
            resolve()
          } else {
            cognitoUser.globalSignOut({
              onFailure: () => {
                cognitoUser.signOut()
                window.localStorage.removeItem("persistentSession")
                window.localStorage.removeItem("proxy")
                window.localStorage.removeItem("previousSearchPath")
                window.localStorage.removeItem("previousSearchConditionText");
                document.cookie = "temporarySession=; max-age=0"
                resolve()
              },
              onSuccess: () => {
                cognitoUser.signOut()
                window.localStorage.removeItem("persistentSession")
                window.localStorage.removeItem("proxy")
                window.localStorage.removeItem("previousSearchPath")
                window.localStorage.removeItem("previousSearchConditionText");
                document.cookie = "temporarySession=; max-age=0"
                resolve()
              }
            })
          }
        }
      })

      resolve();
    })
  }

	// 代理ログイン用  
  logOutForProxy() {

    return new Promise(resolve => {
      console.log("logouting.")

      const cognitoUser = this.userPool.getCurrentUser()

      if (cognitoUser === null) {
        window.localStorage.removeItem("persistentSession")
        window.localStorage.removeItem("proxy")
        document.cookie = "temporarySession=; max-age=0"
        resolve()
      }

		cognitoUser.getSession((err, session) => {
        if (err) {
          cognitoUser.signOut()
          window.localStorage.removeItem("persistentSession")
          window.localStorage.removeItem("proxy")
          window.localStorage.removeItem("previousSearchPath")
          window.localStorage.removeItem("previousSearchConditionText");
          document.cookie = "temporarySession=; max-age=0"
          resolve()
        } else {
          if (!session.isValid()) {
            cognitoUser.signOut()
            window.localStorage.removeItem("persistentSession")
            window.localStorage.removeItem("proxy")
            window.localStorage.removeItem("previousSearchPath")
            window.localStorage.removeItem("previousSearchConditionText");
            document.cookie = "temporarySession=; max-age=0"
            resolve()
          } else {
			cognitoUser.signOut()
            window.localStorage.removeItem("persistentSession")
            window.localStorage.removeItem("proxy")
            window.localStorage.removeItem("previousSearchPath")
            window.localStorage.removeItem("previousSearchConditionText");
            document.cookie = "temporarySession=; max-age=0"
            resolve()
          }
        }
      })

      resolve();
    })
  }
  /**
   * ログインしているかの判定
   */
  isAuthenticated() {
    const cognitoUser = this.userPool.getCurrentUser()
    return new Promise((resolve, reject) => {
      if (cognitoUser === null) { reject(cognitoUser) }
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err)
        } else {
          if (!session.isValid()) {
            reject(session)
          } else {
            resolve(session)
          }
        }
      })
    })
  }

  refreshToken() {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await this.userPool.getCurrentUser()

        if (!user) {
          reject({
            code: 'UserNotFoundException',
            error: null
          });
          return;
        }
        user.getSession((err, session) => {
          if (err) {
            reject({
              code: 'SessionNotFoundException',
              error: null
            });
            return;
          }

          const refreshToken = session.getRefreshToken();
          user.refreshSession(refreshToken, (err) => {
            if (err) {
              reject({
                code: 'FailedToRefreshTokenException',
                error: err
              });
              return;
            }
            resolve();
          });
        })
      })();
    });
  }

  resend(username) {
    return new Promise((resolve, reject) => {
      const userData = {
        Username: username,
        Pool: this.userPool,
        Storage: this.storage
      }
      const cognitoUser = new CognitoUser(userData)

      cognitoUser.resendConfirmationCode(function (err, result) {
        if (err) {
          reject(err)
          return;
        }
        resolve(result)
      });
    })
  }

  getCurrentUser() {
    return new Promise((resolve) => {
      resolve(this.userPool.getCurrentUser())
    })
  }

  getCurrentUserAttributes() {
    return new Promise((resolve, reject) => {
      const user = this.userPool.getCurrentUser()

      if (!user) {
        reject({
          code: "CurrentUserNotFoundException",
          message: {}
        })
        return
      }

      user.getSession((err, session) => {
        if (err) {
          reject({
            code: "CurrentSessionNotFoundException",
            message: err
          })
          return
        }
        if (session.isValid()) {
          user.getUserAttributes((error, attrs) => {
            if (error) {
              reject({
                code: "GetUserAttributesError",
                message: error
              })
              return;
            }

            resolve(attrs)
          });
        }
        else {
          reject({
            code: "InvalidSessionException",
            message: {}
          })
        }
      });
    })
  }

  usePersistentSession(use) {
    if (use) {
      window.localStorage.setItem("persistentSession", 't')
      document.cookie = "temporarySession=; max-age=0"
    }
    else {
      window.localStorage.removeItem("persistentSession")
      document.cookie = "temporarySession=t; path=/"
    }
  }

  customChallenge(username, charange_answer) {
    return new Promise((resolve, reject) => {
      const authenticationData = { Username: username }
      const authenticationDetails = new AuthenticationDetails(authenticationData)

      const userData = {
        Username: username,
        Pool: this.userPool,
        Storage: this.storage
      }
      const cognitoUser = new CognitoUser(userData)

      cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
      cognitoUser.initiateAuth(authenticationDetails, {
        onSuccess: function (result) {
          resolve(result)
        },
        onFailure: function (err) {
          reject(err)
        },
        customChallenge: function () {
          cognitoUser.sendCustomChallengeAnswer(charange_answer, this);
        },
      });
    });
  }

  changePassword(oldPassword, newPassword) {
    return new Promise((resolve, reject) => {
      const user = this.userPool.getCurrentUser()

      if (!user) {
        reject({
          code: "CurrentUserNotFoundException",
          message: {}
        })
        return
      }

      user.getSession((err, session) => {
        if (err) {
          reject({
            code: "CurrentSessionNotFoundException",
            message: err
          })
          return
        }
        if (session.isValid()) {
          user.changePassword(oldPassword, newPassword, function (err, result) {
            if (err) {
              console.log(err)
              reject(err)
              return;
            }
            resolve(result);
          });
        }
        else {
          reject({
            code: "InvalidSessionException",
            message: {}
          })
        }
      });
    })
  }

	isExist(username, password) {
		const userData = {
			Username: username,
			Pool: this.userPool,
			Storage: this.storage
		}
		const cognitoUser = new CognitoUser(userData)
		const authenticationData = { Username: username, Password: password }
		const authenticationDetails = new AuthenticationDetails(authenticationData)

		return new Promise((resolve, reject) => {
			const authConfig = {
				onSuccess(result) {
					resolve(result);
				},
				onFailure(err) {
					reject(err);
				},
			};

			cognitoUser.authenticateUser(authenticationDetails, authConfig)
		})
	}
}
