export default {
  methods: {
    setTitle(title) {
      if (title) {
        const pageTitle = typeof title === 'function' ? title.call(this) : title
        document.title = `スキイキ - ${pageTitle}`
      }
      else {
        document.title = `スキイキ`
      }
    }
  }
}
