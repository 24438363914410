// import MemberAPI from '@/api/member'

export default {
    namespaced: true,
    state: {
        email: null,
        birthdate: "1980-00-00",
    },
    mutations: {
        setEmail: (state, v) => {
            state.email = v
        },
        setBirthdate: (state, v) => {
            state.birthdate = v
        },
    },
    actions: {
    },
    modules: {
    }
}