// import Vue from 'vue'

import PublicAPI from '@/api/public'

export default {
  namespaced: true,
  state: {
    pickups: [],
    pickup: {},
    relatedJobs: []
  },
  mutations: {
    setPickups: (state, pickups) => {
      if (pickups) {
        state.pickups = pickups
      }
    },
    setPickup: (state, pickup) => {
      if (pickup) {
        state.pickup = pickup
      }
    },
    setRelatedJobs: (state, relatedJobs) => {
      if (relatedJobs) {
        relatedJobs = relatedJobs.filter(job => {
          return job.id != null
        })
        state.relatedJobs = relatedJobs
      }
    }
  },
  actions: {
    getAllPickups: ({ commit }) => {
      return new Promise((resolve) => {
        (async () => {

          const result = await PublicAPI.getPickups().catch(e => {
            console.log(e)
            return false;
          })

          if (!result) {
            return false;
          }

          console.log(result)
          commit('setPickups', result.articles)

          resolve(result.articles)

        })();
      })
    },

    getCurrentPickup: ({ commit }, pickupId) => {
      return new Promise((resolve,reject) => {
        (async () => {

          const result = await PublicAPI.getPickup(pickupId).catch(e => {
            console.log(e)
            reject(e)
          })
          console.log(result)

          // 存在しないIDならerror
          if (!result.id) {
            reject(result)
          }

          commit('setPickup', result)
          commit('setRelatedJobs', result.relatedJobs)

          resolve(result)

        })();
      })
    },
  },
  modules: {
  }
}

