// import Vue from 'vue'

import MemberAPI from '@/api/member'
import PublicAPI from '@/api/public'

export default {
    namespaced: true,
    state: {
        skillMatchJobs: [],
        wishNearJobs: [],
        featureJobs: [],
        workAlerts: [],
        wishNearJobCondition: []
      },
    mutations: {
      setSkillMatchJobs(state, result){
        state.skillMatchJobs = result
      },
      setWishNearJobs(state, result){
        state.wishNearJobs = result
      },
      setFeatureJobs(state, result){
        state.featureJobs = result
      },
      setWorkAlerts(state, result){
        state.workAlerts = result
      },
      setWishNearJobCondition(state, result){
        state.wishNearJobCondition = result
      },
    },
    actions: {
      clear({commit}){
        commit('setCompanyId', 0)
        commit('setJobId', 0)
        commit('setSkillMatchJobs', [])
      },
      getWorkAlerts({commit}){
        return new Promise((resolve)=>{
          (async()=>{
            const workAlerts = await MemberAPI.getWorksAlerts().catch(e=>{
              console.log(e)
              return false;
            })
            if(!workAlerts){
              return false;
            }
            else{
              commit('setWorkAlerts', workAlerts)
            }
              resolve()
          })();
      })
          },
      getMypageJobs({commit}){
          return new Promise((resolve)=>{
              (async()=>{
                // あなたのスキルにあった仕事の取得
                const skillMatchResult = await MemberAPI.getSkillMatchJobs().catch(e=>{
                  console.log(e)
                  return false;
                })
                if(!skillMatchResult){
                  return false;
                }
                else{
                  commit('setSkillMatchJobs', skillMatchResult.skillMatchJobs)
                }

                // あなたの希望に近い仕事の取得
                const wishNearResult = await MemberAPI.getWishNearJobs().catch(e=>{
                  console.log(e)
                  return false;
                })
                if(!wishNearResult){
                  return false;
                }
                else{
                  commit('setWishNearJobs', wishNearResult.wishNearJobs)
                  if(wishNearResult.wishNearJobCondition){
                    commit('setWishNearJobCondition', wishNearResult.wishNearJobCondition)
                  }
                }

                // 特集からの仕事を取得
                const featureResult = await PublicAPI.getFeatureJobs().catch(e=>{
                  console.log(e)
                  return false;
                })
                if(!featureResult){
                  return false;
                }
                else{
                  commit('setFeatureJobs', featureResult.featureJobs)
                }
              
                  resolve()
              })();
          })
      },

    },
}
