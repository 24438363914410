export default function zenkakuToHankaku(str) {
  if (str === null || str === '') return str

  // 英数字の全角→半角変換
  str = str.toString().replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  })


  // その他全角→半角変換
  var replaceMap = {
    // ハイフン関連
    'ー': '-',
    '−': '-',
    '―': '-',
  }
  var reg = new RegExp(`(${Object.keys(replaceMap).join('|')})`, 'g')
  str = str.replace(reg, function (match) {
    return replaceMap[match]
  })


  return str

}