
export default class WebSocketClient {
  /**
   * コンストラクタ
   * 
   * @param {string} endpoint WebSocket URL 
   */
  constructor(endpoint) {
    this.endpoint = endpoint
    this.ws = null
    this.closed = true

    this.onOpennedListener = null
    this.onClosedListener = null
    this.onLoginSuccessfulListener = null
    this.onErrorListener = null
    this.onMessageListener = null
    this.onNotifyReadListener = null
    this.onDeleteListener = null
  }

  /**
   * (privare) リスナーセットアップ
   */
  _setListener() {
    this.ws.addEventListener('close', (event) => {
      console.log("WebSocket; close")

      this.ws = null

      if (!this.closed) {
        if (this.onClosedListener) {
          this.onClosedListener(event)
        }
        this.closed = true
      }
    })

    this.ws.addEventListener('error', (event) => {
      console.log("WebSocket; error",)
      if (this.onErrorListener) {
        this.onErrorListener(event)
      }
    })

    this.ws.addEventListener('message', (event) => {
      const data = JSON.parse(event.data);

      if (data.dataType == 'loginSuccessful') {
        console.log("WebSocket; loginSuccessful")

        this.chatterId = data.chatterId

        if (this.onLoginSuccessfulListener) {
          this.onLoginSuccessfulListener(data)
        }
      }
      else if (data.dataType == 'message') {
        console.log("WebSocket; message")
        if (this.onMessageListener) {
          this.onMessageListener(data)
        }
      }
      else if (data.dataType == 'notifyRead') {
        console.log("WebSocket; notifyRead")

        if (this.onNotifyReadListener) {
          this.onNotifyReadListener(data)
        }
      }
      else if (data.dataType == 'deleteMessage') {
        console.log("WebSocket; deleteMessage")

        if (this.onDeleteListener) {
          this.onDeleteListener(data)
        }
      }
    });
  }

  /**
   * 会員ログイン
   * 
   * @param {string} アクセストークン
   */
  loginMember(accessToken) {
    this.ws = new WebSocket(this.endpoint);

    this._setListener();

    this.ws.addEventListener('open', (event) => {
      console.log("WebSocket; openned")

      this.ws.send(JSON.stringify({
        "action": "loginMember",
        "accessToken": accessToken
      }));

      if (this.onOpennedListener) {
        this.onOpennedListener(event)
      }

      this.closed = false
    })
  }

  /**
   * 企業担当者ログイン
   * 
   * @param {string} アクセストークン
   */
  loginCompanyAccount(accessToken) {
    this.ws = new WebSocket(this.endpoint);

    this._setListener();

    this.ws.addEventListener('open', (event) => {
      console.log("WebSocket; openned")

      this.ws.send(JSON.stringify({
        "action": "loginCompanyAccount",
        "accessToken": accessToken
      }));

      if (this.onOpennedListener) {
        this.onOpennedListener(event)
      }

      this.closed = false
    })
  }

  /**
   * ログアウト
   */
  logout() {
    this.closed = true
    this.ws.close()
    this.ws = null
  }

  /**
   * ログイン済みかどうか
   * 
   * @returns {bool} logged in
   */
  isLoggedIn() {
    return this.ws != null;
  }

  /**
   * メッセージ送信
   * 
   * @param {number} entryId エントリーID
   * @param {string} type type 'message' or 'files'
   * @param {string} message メッセージ
   * @param {object} metadata メタデータ（添付ファイル等）
   */
  sendMessage(entryId, type, message, metadata) {
    this.ws.send(JSON.stringify({
      "action": "sendMessage",
      "text": message,
      "entryId": entryId,
      "type": type,
      "metadata": metadata
    }));
  }

  /**
   * メッセージ削除
   * 
   * @param {number} entryId エントリーID
   * @param {string} messageId メッセージ
   */
  deleteMessage(entryId, messageId) {
    this.ws.send(JSON.stringify({
      "action": "deleteMessage",
      "entryId": entryId,
      "messageId": messageId,
    }));
  }

  /**
   * 既読通知送信
   * 
   * @param {number} entryId エントリーID
   */
  notifyRead(entryId) {
    if (this.ws) {
      this.ws.send(JSON.stringify({
        "action": "notifyRead",
        "entryId": entryId,
      }));
    }
  }

  /**
   * 接続開始時リスナー登録
   * 
   * @param {function(message)} listener 
   */
  setOnOpennedListener(listener) {
    this.onOpennedListener = listener;
  }

  /**
   * 接続終了時リスナー登録
   * 
   * @param {function(message)} listener 
   */
  setOnClosedListener(listener) {
    this.onClosedListener = listener;
  }

  /**
   * エラー発生時リスナー登録
   * 
   * @param {function(message)} listener 
   */
  setOnErrorListener(listener) {
    this.onErrorListener = listener;
  }

  /**
   * メッセージ受信時リスナー登録
   * 
   * message = {
   *     messageId: number,
   *     text: string,
   *     entryId: number,
   *     metadata: object, 
   *     timestamp: number,
   *     sender:{
   *         isOwn: bool, // 自分のメッセージかどうか
   *         name: string,
   *         icon: string, // アイコンURL
   *         memberKind: string // 会員種別
   *     }
   * }
   * @param {function(message)} listener 
   */
  setOnMessageListener(listener) {
    this.onMessageListener = listener;
  }

  /**
   * 既読時間受信時リスナー登録
   * 
   * message = {
   *     messageId: number,
   *     entryId: number,
   *     timestamp: number, // 最終既読時間
   *     sender:{
   *         isOwn: bool, // 自分の既読時間かどうか
   *         name: string,
   *         icon: string, // アイコンURL
   *         memberKind: string // 会員種別
   *     }
   * }
   * @param {function(message)} listener 
   */
  setOnNotifyReadListener(listener) {
    this.onNotifyReadListener = listener;
  }
  /**
   * 削除受信時リスナー登録
   * 
   * message = {
   *     messageId: number,
   *     entryId: number,
   * }
   * @param {function(deleteMessage)} listener 
   */
  setOnDeleteListener(listener) {
    this.onDeleteListener = listener;
  }
}
