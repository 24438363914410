// import Vue from 'vue'

import PublicAPI from '@/api/public'

export default {
    namespaced: true,
    state: {
        recommendedJobs: [],
        recommendTitle: "",
      },
    mutations: {
      setRecommendedJobs(state, result){
        state.recommendedJobs = result.jobs
      },
      setRecommendTitle(state, result){
        state.recommendTitle = result.recommendTitle
      },
    },
    actions: {
      clear({commit}){
        commit('setRecommendedJobs', [])
        commit('setRecommendTitle', "")
      },
      loadModel({commit}, jobId){
          return new Promise((resolve)=>{
              (async()=>{
                  const result = await PublicAPI.getRecommendedJobs(jobId).catch(e=>{
                    console.log(e)
                    return false;
                  })
                  if(!result){
                    return false;
                  }
                  else{
                    commit('setRecommendedJobs', result)
                    commit('setRecommendTitle', result)
                  }

                  resolve()
              })();
          })
      },

    },
}
