import Vue from 'vue'
import PublicAPI from '@/api/public'

export default {
    namespaced: true,
    state: {
        loaded: false,
        workingTimes: [],
        jobCategories: [],
        jobTypes: [],
        locations: [],
        minUnitPrices: [],
        maxUnitPrices: [],
        workStyles: [],
        skills: [],
        certifications: [],
        selfChecks: [],
        businessTypes: [],
        contractTypes: [],
        prefectures: [],
        frequeniesOfGoingToOffice: [],
        features: [],
        genders: [],
        memberKinds: [],
        transferTypes: [],
        referencePrices: [],
        contractEntryStatuses: [],
        activeEntryStatuses: [],
        megaBanks:[],
        bankTypes:[],
        accountTypes:[],
        verifyStatuses:[],
        jobSortKeys:[],
        personalVerifyDocumentKinds:[],
        companyVerifyDocumentKinds:[],
        contactKinds: [],
        abuseCategories: [],
        withdrawalReasons:[],
        experienceDescriptionTemplate: "＜職務・経験要約＞\n・××××××\n・××××××\n\n\n＜担当プロジェクト歴（または所属歴）＞\n\n20××年××月～現在\n【 担当プロジェクト名（または所属名）】××××××\n　［メンバー数］×× 名\n　［ポジション］××\n\n【担当業務・取り組み】\n・××××××\n・××××××\n・××××××\n【成果・評価】\n・××××××\n・××××××\n・××××××",
        portfolioTitleTemplate: "○○で採用してもらった●●です。",
        portfolioDescriptionTemplate: "○○を目的として●●の技術を使用して△△を実現し、□□という問題を解決しました。",
        profileCatchPhraseTemplate: "××××××、××××××、××××××が得意です。××××××に貢献します。",
        profileSelfPromotionTemplate: "●××××××（見出し）\n・××××××\n・××××××\n・××××××\n●××××××（見出し）\n・××××××\n・××××××\n・××××××",
        interestFilters:[
            {'code': '01', 'name': '掲載中'},
            {'code': '02', 'name': '未エントリー'},
            {'code': '03', 'name': '掲載終了間近'},
            {'code': '04', 'name': 'すべて表示'},
        ],
        publishCategories:[
            {'code': '01', 'name': '掲載したい'},
            {'code': '02', 'name': '料金体系や仕組みを知りたい'},
            {'code': '03', 'name': '他社の活用事例が知りたい'},
            {'code': '04', 'name': '資料が欲しい'},
            {'code': '05', 'name': '候補者がいるか知りたい'},
            {'code': '07', 'name': '「Co-Work採用」の利用を検討'},
            {'code': '06', 'name': 'その他'},
        ],
        additionalQuestions:[
            { "code": "01", "question": "この仕事情報は「正社員採用あり」の募集です。設問に回答ください", "choices": [ { "code": "01", "name": "業務委託契約終了後、条件が合えば正社員化を希望する" }, { "code": "02", "name": "正社員化を希望しない" },{ "code": "03", "name": "どちらとも言えない" } ] }
        ],
        employeeTypes:[
            {"code": "01", "name": "準委任契約・委任契約"},
            {"code": "02", "name": "正社員"},
        ]
    },
    mutations: {
        setConstants(state, v){
            // Vue.set(state, 'employeeTypes', v.employeeTypes)
            // Vue.set(state, 'publishCategories', v.publishCategories)
            // Vue.set(state, 'interestFilters', v.interestFilters)
            // Vue.set(state, 'profileSelfPromotionTemplate', v.profileSelfPromotionTemplate)
            // Vue.set(state, 'profileCatchPhraseTemplate', v.profileCatchPhraseTemplate)
            // Vue.set(state, 'portfolioDescriptionTemplate', v.portfolioDescriptionTemplate)
            // Vue.set(state, 'portfolioTitleTemplate', v.portfolioTitleTemplate)
            // Vue.set(state, 'experienceDescriptionTemplate', v.experienceDescriptionTemplate)
            Vue.set(state, 'withdrawalReasons', v.withdrawalReasons)
            Vue.set(state, 'abuseCategories', v.abuseCategories)
            Vue.set(state, 'contactKinds', v.contactKinds)
            Vue.set(state, 'workingTimes', v.workingTimes)
            Vue.set(state, 'jobTypes', v.jobTypes)
            Vue.set(state, 'jobCategories', v.jobCategories)
            Vue.set(state, 'locations', v.locations)
            Vue.set(state, 'workStyles', v.workStyles)
            Vue.set(state, 'skills', v.skills)
            Vue.set(state, 'certifications', v.certifications)
            Vue.set(state, 'selfChecks', v.selfChecks)
            Vue.set(state, 'businessTypes', v.businessTypes)
            Vue.set(state, 'contractTypes', v.contractTypes)
            Vue.set(state, 'minUnitPrices', v.minUnitPrices)
            Vue.set(state, 'maxUnitPrices', v.maxUnitPrices)
            Vue.set(state, 'prefectures', v.prefectures)
            Vue.set(state, 'frequeniesOfGoingToOffice', v.frequenciesOfGoingToOffice)
            Vue.set(state, 'features', v.features)
            Vue.set(state, 'genders', v.genders)
            Vue.set(state, 'memberKinds', v.memberKinds)
            Vue.set(state, 'transferTypes', v.transferTypes)
            Vue.set(state, 'contractEntryStatuses', v.contractEntryStatuses)
            Vue.set(state, 'activeEntryStatuses', v.activeEntryStatuses)
            Vue.set(state, 'referencePrices', v.referencePrices)
            Vue.set(state, 'scoutSortKeys', v.scoutSortKeys)
            Vue.set(state, 'scoutFilters', v.scoutFilters)
            Vue.set(state, 'megaBanks', v.megaBanks)
            Vue.set(state, 'bankTypes', v.bankTypes)
            Vue.set(state, 'accountTypes', v.accountTypes)
            Vue.set(state, 'verifyStatuses', v.verifyStatuses)
            Vue.set(state, 'jobSortKeys', v.jobSortKeys)
            Vue.set(state, 'personalVerifyDocumentKinds', v.personalVerifyDocumentKinds)
            Vue.set(state, 'companyVerifyDocumentKinds', v.companyVerifyDocumentKinds)
            state.loaded = true
            // Vue.set(state, 'additionalQuestions', v.companyVerifyDocumentKinds)
            state.loaded = true
        },
    },
    actions: {
        load({commit, state}){ /* eslint no-unused-vars:0 */
            return new Promise((resolve)=>{
                if(state.loaded){
                    resolve()
                }
                else{
                    (async()=>{
                        const constants = await PublicAPI.getConstants().catch(e=>{
                            return false;
                        })
                        if(!constants){
                            return false;
                        }
                        commit('setConstants', constants)

                        resolve()
                    })();
                }
            })
        },
    },
    modules: {
    }
  }