import Vue from 'vue'

import PublicAPI from '@/api/public'
import MemberAPI from '@/api/member'

export default {
  namespaced: true,
  state: {
    follows: []
  },
  mutations: {
    clear(state) {
      state.follows = []
    },

    addToFollow(state, company) {
      if (company) {
        if (state.follows) {
          if (state.follows.filter((follow) => {
            return follow.company.companyId == company.companyId
          }).length > 0) return
          state.follows.push(company)
        }
      }
    },

    removeFromFollow(state, companyId) {
      state.follows = state.follows.filter((follow) => {
        return follow.company.companyId != companyId
      })
    },

    updateStatus(state, status) {
      for (const i in state.follows) {
        const follow = state.follows[i]
        if (follow.company.companyId == status.companyId) {
          follow.jobCount = status.jobCount
          follow.published = status.published
          follow.archived = status.archived
          Vue.set(state.follows, i, follow)
          return
        }
      }
    },

    setFollows(state, v) {
      if (v) {
        state.follows = v;
      }
    },

    storeState(state) {
      const props = Object.keys(state)
      for (const i in props) {
        const prop = props[i];

        window.localStorage.setItem('store.state.follows.' + prop, JSON.stringify(state[prop]));
      }
    },

    restoreState(state) {
      const props = Object.keys(state)
      for (const i in props) {
        const prop = props[i];

        const rawItem = window.localStorage.getItem('store.state.follows.' + prop)

        if (rawItem) {
          const value = JSON.parse(rawItem)

          Vue.set(state, prop, value)
        }
      }
    },
  },
  actions: {
    loadFromLocal({ commit }) {
      return new Promise((resolve) => {
        commit('restoreState')
        resolve()
      });
    },
    loadFromRemote({ commit }) {
      return new Promise((resolve, reject) => {
        (async () => {
          const result = await MemberAPI.getFollows().catch(e => {
            console.log(e)
            return false;
          })

          if (!result) {
            reject()
            return
          }
          commit('setFollows', result.follows)

          // localStorageをクリア
          window.localStorage.setItem('store.state.follows.follows', []);

          resolve()
        })()
      });
    },
    getStatuses({ commit, state }) {
      return new Promise((resolve, reject) => {
        (async () => {
          const companyIds = []
          if (state.follows) {
            state.follows.forEach(follow => {
              companyIds.push(follow.company.companyId)
            })
          }
          const result = await PublicAPI.postCompanyStatuses({ companyIds }).catch(e => {
            console.log(e)
            return false;
          })

          if (!result) {
            reject()
            return
          }
          if (result.statuses) {
            result.statuses.forEach((status) => {
              commit('updateStatus', status)
            })
          }

          resolve()
        })()
      });
    },
    add({ commit, rootState }, { companyId, followedDevice, scoutId }) {
      return new Promise((resolve, reject) => {
        if (rootState.auth.isLoggedIn) {
          (async () => {

            const companyInfo = await MemberAPI.postFollows(companyId, followedDevice, scoutId ? scoutId : null).catch(e => {
              console.log(e)
              return false;
            })

            if (!companyInfo) {
              reject()
              return
            }
            console.log(companyInfo)
            commit('addToFollow', companyInfo)
            resolve()
          })();
        }
        else {
          (async () => {
            const companyInfo = await PublicAPI.getCompanyInfo(companyId).catch(e => {
              console.log(e)
              return false;
            })

            if (!companyInfo) {
              reject()
              return
            }
            commit('addToFollow', {
              company: companyInfo,
              addedAt: Date.now(),
              followedDevice: followedDevice,
              jobCount: null,
              published: true,
              archived: true
            })
            commit('storeState')
            resolve()
          })();
        }
      });
    },
    remove({ commit, rootState }, { companyId }) {
      return new Promise((resolve, reject) => {
        if (rootState.auth.isLoggedIn) {
          (async () => {
            const companyInfo = await MemberAPI.postFollowsCompanyId(companyId).catch(e => {
              console.log(e)
              return false;
            })

            if (!companyInfo) {
              reject()
              return
            }

            commit('removeFromFollow', companyId)
            resolve()
          })();
        }
        else {
          (async () => {
            commit('removeFromFollow', companyId)
            commit('storeState')
            resolve()
          })();
        }
      });
    },
    clearLocal({ commit }) {
      return new Promise((resolve) => {
        commit('clear')
        commit('storeState')

        resolve();
      });
    },
    localToRemote({ commit, state, dispatch }) {
      if (state.follows) {
        return new Promise((resolve) => {
          (async () => {
            const companys = [];
            state.follows.forEach((follow) => {
              companys.push({ companyId: follow.company.companyId, followedDevice: follow.followedDevice });
            });

            for (const i in companys) {
              await dispatch("add", {
                companyId: companys[i].companyId,
                followedDevice: companys[i].followedDevice
              });
            }

            resolve()
          })();
        })
          .then(() => {
            commit('clear')
            commit('storeState')
          });
      }
    }
  }
}
