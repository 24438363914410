import Vue from 'vue'

import MemberAPI from '@/api/member'

export default {
  namespaced: true,
  state: {
    works: [],
    entries: [],
  },
  mutations: {
    setWorks: (state, result) => {
      Vue.set(state, 'works', result.works)
    },
    setEntries: (state, result) => {
      Vue.set(state, 'entries', result.entries)
    },
  },
  actions: {
    getWorks: ({ commit }) => {
      return new Promise((resolve) => {
        (async () => {

          const result = await MemberAPI.getWorks().catch(e => {
            console.log(e)
            return false;
          })

          if (!result) {
            return false;
          }
          commit('setWorks', result)

          resolve()

        })();
      })
    },

    getEntries: ({ commit }) => {
      return new Promise((resolve) => {
        (async () => {

          const result = await MemberAPI.getEntries().catch(e => {
            console.log(e)
            return false;
          })

          if (!result) {
            return false;
          }
          commit('setEntries', result)

          resolve()

        })();
      })
    },


  },
  modules: {
  }
}