<template>
  <header class="contents_single">
    <div class="header_container">
      <!-- === header_detail/ === -->
      <div class="header_detail">
        <div class="header_siteid">
          <span class="siteid_logo">スキルが活きる、スキルで生きる スキイキ produced by マイナビ</span>
        </div>

        <div class="header_btn_close">
          <button
            type="button"
            class="window_close_btn"
            onclick="window.close();"
            value="ウインドウを閉じる"
          >
            <span>ウインドウを閉じる</span>
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
const listener = {
  methods: {
    listen: function (target, eventType, callback) {
      if (!this._eventRemovers) {
        this._eventRemovers = [];
      }
      target.addEventListener(eventType, callback);
      this._eventRemovers.push({
        remove: function () {
          target.removeEventListener(eventType, callback);
        },
      });
    },
  },
  destroyed: function () {
    if (this._eventRemovers) {
      this._eventRemovers.forEach(function (eventRemover) {
        eventRemover.remove();
      });
    }
  },
};

export default {
  name: "HeaderClose",
  mixins: [listener],
  data() {
    return {
      showMenu: false,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
