import PublicAPI from '@/api/public'

import zenkakuToHankaku from '@/helpers/utils/zenkakuToHankaku'

export default {
  namespaced: true,
  state: {
    lastName:      "",
    firstName:     "",
    lastNameKana:  "",
    firstNameKana: "",
    email:         "",
    contactType:   {code:'01', name:'ご質問'},
    detail:        "",
    privacy:       false
  },
  mutations: {
      setLastName(state, v){
        if(v != null) state.lastName = v.toString().replace(/\s+/g,'')
      },
      setFirstName(state, v){
        if(v != null) state.firstName = v.toString().replace(/\s+/g,'')
      },
      setLastNameKana(state, v){
        if(v != null) state.lastNameKana = v.toString().replace(/\s+/g,'')
      },
      setFirstNameKana(state, v){
        if(v != null) state.firstNameKana = v.toString().replace(/\s+/g,'')
      },
      setEmail(state, v){
        v = zenkakuToHankaku(v)
        if(v != null) state.email = v.toString().replace(/\s+/g,'')
      },
      setContactType(state, v){
        state.contactType = v
      },
      setDetail(state, v){
        state.detail = v
      },
      setPrivacy(state, v){
        state.privacy = v
      }
  },
  actions: {
    postContact({commit, state}){
      return new Promise((resolve, reject)=>{
        PublicAPI.postContact({
          lastName:      state.lastName,
          firstName:     state.firstName,
          lastNameKana:  state.lastNameKana,
          firstNameKana: state.firstNameKana,
          email:         state.email,
          contactType:   state.contactType,
          detail:        state.detail,
          privacy:       state.privacy,
          userAgent: navigator.userAgent
        }).then(()=>{
          commit('setLastName', '')
          commit('setFirstName', '')
          commit('setLastNameKana', '')
          commit('setFirstNameKana', '')
          commit('setEmail', '')
          commit('setContactType', {code:'01', name:'ご質問'})
          commit('setDetail', '')
          commit('setPrivacy', false)

          resolve()

        }).catch(e=>{
          reject(e)
        })
      })
    }
  },
  modules: {
  }
}