import MemberAPI from "@/api/member";
import PublicAPI from "@/api/public";

import zenkakuToHankaku from "@/helpers/utils/zenkakuToHankaku";

export default {
	namespaced: true,
	state: {
		// 入力、表示用
		lastName: "",
		firstName: "",
		lastNameKana: "",
		firstNameKana: "",
		email: "",
		companyId: 0,
		companyName: "",
		abuseJobInfos: [],
		abuseJobs: [],
		abuseCategory: [],
		detail: "",
		privacy: false,

		// POST用
		abuseJobId: "",
		abuseCompany: {},
	},
	mutations: {
		setLastName(state, v) {
			if(v != null) state.lastName = v.toString().replace(/\s+/g,'');
		},
		setFirstName(state, v) {
			if(v != null) state.firstName = v.toString().replace(/\s+/g,'');
		},
		setLastNameKana(state, v) {
			if(v != null) state.lastNameKana = v.toString().replace(/\s+/g,'');
		},
		setFirstNameKana(state, v) {
			if(v != null) state.firstNameKana = v.toString().replace(/\s+/g,'');
		},
		setEmail(state, v) {
			state.email = zenkakuToHankaku(v);
		},
		setCompanyId(state, v) {
			state.companyId = v;
		},
		setCompanyName(state, v) {
			if(v != null) state.companyName = v.toString().replace(/\s+/g,'');
		},
		setAbuseJobInfos(state, v) {
			if (v.length != 0) {
				for (var i = 0; i < v.length; i++) {
					state.abuseJobInfos.push(v[i]);
				}
			} else {
				state.abuseJobInfos = v;
			}
		},
		setAbuseJobId(state, v) {
			state.abuseJobId = v;
		},
		setAbuseCategory(state, v) {
			state.abuseCategory = v;
		},
		setDetail(state, v) {
			state.detail = v;
		},
		setPrivacy(state, v) {
			state.privacy = v;
		},
		setAbuseCompany(state, v) {
			state.abuseCompany = v;
		},
		setAbuseJobs(state, v) {
			state.abuseJobs = v;
		},
	},
	actions: {
		loadModel({ commit }, abuseCompanyId) {
			return new Promise((resolve) => {
				(async () => {
					const abuseCompanyInfo = await PublicAPI.getAbuseCompanyInfo(
						abuseCompanyId
					).catch((e) => {
						console.log(e);
						return false;
					});

					commit("setCompanyId", abuseCompanyInfo.companyId);
					commit("setCompanyName", abuseCompanyInfo.companyName);
					commit("setAbuseCompany", {
						code: abuseCompanyInfo.companyId,
						name: abuseCompanyInfo.companyName,
					});
					commit("setAbuseJobInfos", abuseCompanyInfo.jobInfos);
					resolve(abuseCompanyInfo);
				})();
			});
		},

		getAbuseJobs: ({ commit }, companyId) => {
			return new Promise((resolve) => {
				(async () => {
					const result = await MemberAPI.getCompanyAbuse(companyId).catch(
						(e) => {
							console.log(e);
							return false;
						}
					);

					if (!result) {
						return false;
					}
					commit("setAbuseJobs", result);

					resolve();
				})();
			});
		},

		setNames({ commit }, profile) {
			commit("setLastName", profile.familyName);
			commit("setLastNameKana", profile.familyNameKana);
			commit("setFirstName", profile.name);
			commit("setFirstNameKana", profile.nameKana);
		},
		setEmail({ commit }, email) {
			commit("setEmail", email);
		},

		postAbuse({ commit, state }) {
			return new Promise((resolve, reject) => {
				MemberAPI.postCompanyAbuse({
					lastName: state.lastName,
					firstName: state.firstName,
					lastNameKana: state.lastNameKana,
					firstNameKana: state.firstNameKana,
					email: state.email,
					abuseCompanyId: state.companyId,
					abuseJobId: state.abuseJobId,
					abuseJobCategory: state.abuseCategory,
					detail: state.detail,
					privacy: state.privacy,
				})
					.then((response) => {
						commit("setLastName", "");
						commit("setFirstName", "");
						commit("setLastNameKana", "");
						commit("setFirstNameKana", "");
						commit("setEmail", "");
						commit("setCompanyId", 0);
						commit("setCompanyName", "");
						commit("setAbuseCompany", {});
						commit("setAbuseJobInfos", []);
						commit("setAbuseJobId", "");
						commit("setAbuseCategory", []);
						commit("setAbuseCompany", {});
						commit("setDetail", "");
						commit("setPrivacy", false);

						resolve(response);
					})
					.catch((e) => {
						reject(e);
					});
			});
		},
	},
	modules: {},
};
