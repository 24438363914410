import Cognito from './cognito'

const Auth = new Cognito();
Auth.configure({
    Region: process.env.VUE_APP_AUTH_REGION,
    UserPoolId: process.env.VUE_APP_AUTH_USER_POOL_ID,
    ClientId: process.env.VUE_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
})

export default Auth 
