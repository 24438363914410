import MemberAPI from '@/api/member'

export default {
    namespaced: true,
    state: {
        scouts: [],
        totalCount: null,
        count: null,
        perPage: null,
        page: null,
        firstIndex: null,
        lastIndex: null,

        sort: {'code':'01', 'name':'受信日順'},
        filter: {'code':'02', 'name':'未開封・未対応のみ'},

        currentScout: null,
        unreadCount: 0,
    },
    mutations: {
        setScoutResults: (state, result)=>{
            state.scouts = result.scouts
            state.totalCount = result.totalCount
            state.count = result.count
            state.perPage = result.perPage
            state.page = result.page
            state.firstIndex = result.firstIndex
            state.lastIndex = result.lastIndex
            state.unreadCount = result.unreadCount
        }
    },
    actions: {
        getScouts: ({commit, state}, page) => {
            return new Promise((resolve)=>{
                (async()=>{
                    const result = await MemberAPI.getScouts(page, state.filter.code, state.sort.code).catch(e=>{
                        console.log(e)
                        return false;
                    })

                    if(!result){
                        return false;
                    }

                    commit('setScoutResults', result)
            
                    resolve()
        
                })();
            })
        },
    },
    modules: {
    }
  }