import 'babel-polyfill'
import 'isomorphic-fetch'

import Vue from 'vue'
import VueMq from "vue-mq";
import VueLazyload from 'vue-lazyload';

import App from './App.vue'
import router from './router'
import store from './store'
import Auth from './auth'

import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import MemberAPI from '@/api/member'

// MYF_2-856 ユーザー属性APIの作成
// ユーザー属性取得APIを呼び出してグローバル変数に格納
MemberAPI.getUserAttribute()
  .then((res) => {
    window._uaProfile = res;
  })
  .catch((e) => {
    console.log("function error", e);
})

// window.getUserAttribute = function() {
//   return MemberAPI.getUserAttribute()
//   .then((res) => {
//     return res;
//   })
//   .catch((e) => {
//     console.log("function error", e);
//   })
// };
// 下記の形で呼び出す
// window.getUserAttribute().then((_uaProfile) => {
//   window._uaProfile　= _uaProfile;
//   console.log("Returned array:", window._uaProfile);
// }).catch((error) => {
//   console.error("Error occurred:", error);
// });

// エラーモーダルをグローバルコンポーネントに登録
const requireComponent = require.context(
  './components/form',
  false,
  /Errors[A-Z]\w+\.(vue|js)$/
)
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(
    camelCase(fileName.split('/').pop().replace(/\.\w+$/, ''))
  )
  Vue.component(
    componentName,
    componentConfig.default || componentConfig
  )
})


const moment = require('moment-timezone');
moment.tz.setDefault('Asia/Tokyo');

Vue.use(VueMq, {
  breakpoints: {
    sp: 767,
    pc: 768,
  }
});

Vue.use(VueLazyload, {
  preload: 1.3,
  error: "",
  // loading: "/assets/images/common/now_loading.png",
  loading: "/assets/images/common/white_50x50.jpg",
  attempt: 1,
  lazyComponent: true
})

import titleMixin from './mixin/title'
import errorModal from './mixin/errorModal'
import scrollTop from './mixin/scrollTop'
import accordionTransition from './mixin/accordionTransition'

Vue.mixin(titleMixin)
Vue.mixin(errorModal)
Vue.mixin(scrollTop)
Vue.mixin(accordionTransition)

Vue.config.productionTip = false;

// 本番環境時 console.log を無効に
if (!(process.env.VUE_APP_OTHER_ENV == 'development' || process.env.VUE_APP_OTHER_ENV == 'staging')) {
  Object.keys(window.console).forEach(key => {
    if (typeof eval(`console.${key}`) == 'function') {
      eval(`console.${key} = function () { return; };`)
    }
  })
}

window.addEventListener('focus', () => {
  if (store.state.auth.isLoggedIn) {
    Auth.refreshToken().then(
      () => {
        console.log("session valid")
      }
    ).catch((e) => {
      console.error(e);

      if (e.code == 'UserNotFoundException') {
        console.log("session invalid, logout.");
        (async () => {
          try {
            // 失敗したらログアウト
            store.commit("auth/updateLoginStatus", false);
            await Auth.logout();
          }
          finally {
            // ログイン画面に遷移
            window.location.href = "/login"
          }
        })()
      }

    });
  }
});

(async () => {
  await store.dispatch("constants/load")
  await store.dispatch('auth/updateLoginStatus')

  if (store.state.auth.isLoggedIn) {
    try {
      await store.dispatch("profile/getProfile")
    }
    catch {
		// 	  	
	}
  }

  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
})();

