// import Vue from 'vue'

import MemberAPI from '@/api/member'

// const moment = require("moment");

export default {
    namespaced: true,
    state: {
        // 初期表示を「先月」だったのを全件取得するように変更
        // from: moment().subtract(1, 'month').date(1).hours(0).minutes(0).seconds(0).milliseconds(0).valueOf(),
        // to: moment().date(1).subtract(1, 'day').hours(23).minutes(59).seconds(59).milliseconds(999).valueOf(),
        from: 0,
        to: 0,
        companyName: "",

        rewards: [],
        totalCount: null,
        count: null,
        perPage: null,
        page: null,
        firstIndex: null,
        lastIndex: null,
    },
    mutations: {
        setRewardsResult: (state, result) => {
            state.rewards = result.rewards
            state.totalCount = result.totalCount
            state.count = result.count
            state.perPage = result.perPage
            state.page = result.page
            state.firstIndex = result.firstIndex
            state.lastIndex = result.lastIndex
        },
    },
    actions: {
        getRewards: ({ commit, state }, page) => {
            return new Promise((resolve) => {
                (async () => {
                    const result = await MemberAPI.getRewards(page, state.from, state.to, state.companyName).catch(e => {
                        console.log(e)
                        return false;
                    })

                    if (!result) {
                        return false;
                    }

                    commit('setRewardsResult', result)

                    resolve()

                })();
            })
        },
    },
    modules: {
    }
}