import MemberAPI from '@/api/member'

export default {
    namespaced: true,
    state: {
      withdrawalReasons: [],
      withdrawalOptionText: "",
      withdrawalAgreement: false,
    },
    mutations: {
        setWithdrawalReasons: (state, v)=>{
          state.withdrawalReasons = v
        },
        setWithdrawalOptionText: (state, stringValue)=>{
          state.withdrawalOptionText = stringValue
        },
        setWithdrawalAgreement: (state, boolean)=>{
          state.withdrawalAgreement = boolean
        },
    },
    actions: {
      clear: ({commit})=>{
        commit('setWithdrawalReasons', [])
        commit('setWithdrawalOptionText', "")
        commit('setWithdrawalAgreement', false)
      },
      setReasons: ({commit}, v)=>{
        commit('setWithdrawalReasons', v)
      },
      setOptionText: ({commit}, stringValue)=>{
        commit('setWithdrawalOptionText', stringValue)
      },
      setAgreement: ({commit}, boolean)=>{
        commit('setWithdrawalAgreement', boolean)
      },
      postWithdrawal: ({state})=>{
        return new Promise((resolve)=>{
          (async()=>{
            const result = await MemberAPI.postWithdrawal({
              "withdrawalReasons": state.withdrawalReasons,
              "withdrawalOptionText": state.withdrawalOptionText,
              "withdrawalAgreement": state.withdrawalAgreement,}
              ).catch(e=>{
                console.log(e)
                resolve(e)
                return false
              })

              if(!result){
                return false
              }

              resolve(result)
          })();
        })
      },
    },
    modules: {
    }
  }