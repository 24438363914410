// import Vue from 'vue'

import PublicAPI from '@/api/public'

export default {
	namespaced: true,
	state: {
		magazines: [],
		magazine: {},
		relationalJobs: {},
	},
	mutations: {

		setMagazines: (state, magazines) => {
			if (magazines) {
				state.magazines = magazines
			}
		},
		setMagazine: (state, magazine) => {
			if (magazine) {
				state.magazine = magazine
			}
		},
		setCurrentPage(state, page) {
			state.magazines.page = page
		},
		setRelationalJobs(state, jobs) {
			state.relationalJobs = jobs
		},
	},
	actions: {
		getAllMagazines: ({ commit }) => {
			return new Promise((resolve) => {
				/*  */
				(async () => {
					const result = await PublicAPI.getMagazines().catch(e => {
						console.log(e)
						return false;
					})
					if (!result) {
						return false;
					}

					commit('setMagazines', result)
					resolve()

				})();
			})
		},
		getMagazinesByPaging: ({ commit }, page) => {
			return new Promise((resolve) => {
				/* */
				if (page) {

					(async () => {
					const result = await PublicAPI.getMagazinesByPaging(page).catch(e => {
						console.log(e)
						return false;
					})
					if (!result) {
						return false;
					}

					commit('setMagazines', result)
					resolve()

				})();
				} else {
					(async () => {
						const result = await PublicAPI.getMagazines().catch(e => {
							console.log(e)
							return false;
						})
						if (!result) {
							return false;
						}

						commit('setMagazines', result)
						resolve()

					})();
				}
			})
		},
		getCurrentMagazine: ({ commit }, magazineId) => {
			return new Promise((resolve) => {
				(async () => {

					const result = await PublicAPI.getMagazine(magazineId).catch(e => {
						console.log(e)
						return false;
					})

					if (!result) {
						return false;
					}
					commit('setMagazine', result)

					resolve()

				})();
			})
		},
		getMagazinesByCategory: ({ commit }, key) => {
			return new Promise((resolve) => {
				(async () => {
					const result = await PublicAPI.getMagazinesByCategory(key).catch(e => {

						console.log(e)
						return false;
					})
					if (!result) {
						return false;
					}

					commit('setMagazines', result)
					console.log(result);
					resolve()

				})();
			})
		},
		getMagazinePreviewKey: ({ commit }, key) => {
			return new Promise((resolve, reject) => {
				(async () => {

					const result = await PublicAPI.getMagazinePreviewKey(key).catch(e => {
						console.log(e)
						reject(e);
					})

					if (!result) {
						reject();
					}
					commit('setMagazine', result)

					resolve()

				})();
			})
		},
		getRelationalJobs: ({ commit }, params) => {
			return new Promise((resolve) => {
				(async () => {
					const result = await PublicAPI.getRelationalJobsForMagazine(params).catch(e => {
						console.log(e)
						commit('setRelationalJobs', {
							btnLabel: "仕事を探してみる",
							href: "/job?ec=cnts_mag" + params.magazineId,
							jobs: [],
							relationalJobCount: 0,
							title: "スキイキに掲載中の仕事情報"
						})
						return false;
					})

					if (!result) {
						commit('setRelationalJobs', {
							btnLabel: "仕事を探してみる",
							href: "/job?ec=cnts_mag" + params.magazineId,
							jobs: [],
							relationalJobCount: 0,
							title: "スキイキに掲載中の仕事情報"
						})
						return false;
					}
					commit('setRelationalJobs', result)

					resolve()

				})();
			})
		},

		
	},
	getters: {
	},
	modules: {
	}
}