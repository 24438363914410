// import Vue from 'vue'
import MemberAPI from '@/api/member'

export default {
  namespaced: true,
  state: {
    "contractGroupId": null,
    "termStart": null,
    "termEnd": null,
    "startDate": null,
    "endDate": null,
    "unitPrice": 0,
    "reportStatus": null,
    "transferType": null,
    "offerNote": false,
    "reportComment": [],
    "note": "",
    "items": [],
    "expenses": [],
    "reportFiles": [],
    "taxWithholdingAmount": 0,
    "totalReportPaymentAmountInTax": 0,
    "q1": null,
    "q2": null,
    "q3": null,
    "q4": null,
    "q_comment": null,
    "q1A":null,
    "q2A":null,
    "q3A":null,
    "q4A":null,

    sentFlg: false,
    isLastMonth: false,
  },
  mutations: {
    setContractGroupId: (state, v) => {
      state.contractGroupId = v
    },
    setTermStart: (state, v) => {
      state.termStart = v
    },
    setTermEnd: (state, v) => {
      state.termEnd = v
    },
    setStartDate: (state, v) => {
      state.startDate = v
    },
    setEndDate: (state, v) => {
      state.endDate = v
    },
    setUnitPrice: (state, v) => {
      state.unitPrice = v
    },
    setReportStatus: (state, v) => {
      state.reportStatus = v
    },
    setTransferType: (state, v) => {
      state.transferType = v
    },
    setOfferNote: (state, v) => {
      state.offerNote = v
    },
    setReportComment: (state, v) => {
      state.reportComment = v
    },
    setNote: (state, v) => {
      state.note = v
    },
    setItems: (state, v) => {
      state.items = v
    },
    setExpenses: (state, v) => {
      state.expenses = v
    },
    setCostToExpenses: (state, v) => {
      if (typeof v === 'undefined') {
        state.expenses = [];
      } else {
        state.expenses = v
        var totalPrice = 0
        for (const item of v.items) {
          totalPrice += item.itemPrice
        }      
        state.expenses.totalPrice = totalPrice
      }
      console.log("vtotalcost is", v)
      console.log("totalcost is", state.expenses)
    },
    setReportFiles: (state, v) => {
      state.reportFiles = v
    },
    setItemsToReportFiles: (state, v) => {
      console.log("file isis0", v)
      if (typeof v !== 'undefined' && typeof v.items !== 'undefined') {
        state.reportFiles = v.items
      } else {
        state.reportFiles = []
      }
    },
    updateItem: (state, { index, value }) => {
      state.items.splice(index, 1, value)
    },
    addExpenses: (state, value) => {
      state.expenses = value
    },
    addExpense: (state, value) => {
      state.expenses.items.push(value)
    },
    updateExpense: (state, { index, value }) => {
      state.expenses.items.splice(index, 1, value)
    },
    updateExpenseTotalPrice: (state) => {
      var totalPrice = 0
      for (const item of state.expenses.items) {
        console.log("price is", Number(item.itemPrice))
        totalPrice += Number(item.itemPrice)
      }
      state.expenses.totalPrice = totalPrice
    },
    deleteExpenses: (state) => {
      state.expenses = []
    },
    removeExpense: (state, index) => {      
      state.expenses.items.splice(index, 1);
    },
    setAllExpenses: (state, v) => {
      state.expenses = v;
    },
    setTaxWithholdingAmount: (state, v) => {
      state.taxWithholdingAmount = v
    },
    setTotalReportPaymentAmountInTax: (state, v) => {
      state.totalReportPaymentAmountInTax = v
    },
    setSentFlg: (state, v) => {
      state.sentFlg = v
    },
    setLastMonthFlag: (state, v) => {
      state.isLastMonth = v
    },
    setQuestion1: (state, v) => {
      state.q1 = v
    },
    setQuestion2: (state, v) => {
      state.q2 = v
    },
    setQuestion3: (state, v) => {
      state.q3 = v
    },
    setQuestion4: (state, v) => {
      state.q4 = v
    },
    setQuestionComment: (state, v) => {
      state.q_comment = v
    },
    setQ1Answer: (state, v) => {
      state.q1A = v
    },
    setQ2Answer: (state, v) => {
      state.q2A = v
    },
    setQ3Answer: (state, v) => {
      state.q3A = v
    },
    setQ4Answer: (state, v) => {
      state.q4A = v
    },
  },
  actions: {
    clear({ commit }) {
      console.log("clear!!!!!!!!!!!!!!!!!!!!")
      commit('setId', null)
      commit('setTermStart', null)
      commit('setTermEnd', null)
      commit('setWorkStart', null)
      commit('setWorkEnd', null)
      commit('setContractType', null)
      commit('setOfferStatus', null)
      commit('setIsAdded', null)
      commit('setUnitPrice', null)
      commit('setContractGroups', [])
      commit('setNote', "")
      commit('setTaxWithholdingAmount', 0)
      commit('totalReportPaymentAmountInTax', 0)
      commit('setSentFlg', false)
      commit('setItems', [])
      commit('setExpenses', [])
      commit('setReportFiles', [])
      commit('setContractGroupId', null)
      commit('setLastMonthFlag', false)
      commit('setQuestion1', null)
      commit('setQuestion2', null)
      commit('setQuestion3', null)
      commit('setQuestion4', null)
      commit('setQuestionComment', null)
      commit('setQ1Answer', null)
      commit('setQ2Answer', null)
      commit('setQ3Answer', null)
      commit('setQ4Answer', null)
      console.log('cleared')
    },
    clearExpenses({ commit }) {
      commit('setExpenses', [])
    },
    clearItems({ commit }) {
      commit('setItems', [])
    },
    postEntryIdContractGroupIdReport: ({ state }, { entryId, contractGroupId }) => {
      console.log("send file", state.reportFiles)
      return new Promise((resolve, reject) => {
        (async () => {

          const result = await MemberAPI.postEntryIdContractGroupIdReport(entryId, contractGroupId, {
            comment: state.reportComment,
            transferType: state.transferType,
            items: state.items,
            report_costs: state.expenses,
            report_files: state.reportFiles,
            q1:state.q1,
            q2:state.q2,
            q3:state.q3,
            q4:state.q4,
            q_comment:state.q_comment
          }).catch(e => {
            console.log(e)
            reject(e)
          })

          if (!result) {
            return false;
          }

          resolve(result)

        })();
      })
    },
    getEntryIdContractGroupId({ commit }, { entryId, contractGroupId }) {
      return new Promise((resolve, reject) => {
        (async () => {
          try {
            const contractGroup = await MemberAPI.getEntryIdContractGroupId(entryId, contractGroupId);

            console.log("contract response", contractGroup.status != 404)
            if (contractGroup.status != 404) {
              commit('setContractGroupId', contractGroup.contractGroupId);
              commit('setTermStart', contractGroup.termStart);
              commit('setTermEnd', contractGroup.termEnd);
              commit('setStartDate', contractGroup.startDate);
              commit('setEndDate', contractGroup.endDate);
              commit('setUnitPrice', contractGroup.unitPrice);
              commit('setReportStatus', contractGroup.reportStatus);
              commit('setTransferType', contractGroup.transferType);
              commit('setOfferNote', contractGroup.offerNote);
              commit('setReportComment', contractGroup.reportComment);
              commit('setNote', contractGroup.note);
              commit('setItems', contractGroup.items);
              commit('setCostToExpenses', contractGroup.report_costs);
              console.log("reportresponse", contractGroup)
              commit('setItemsToReportFiles', contractGroup.report_files);
              commit('setTaxWithholdingAmount', contractGroup.taxWithholdingAmount);
              commit('setTotalReportPaymentAmountInTax', contractGroup.totalReportPaymentAmountInTax)
              commit('setLastMonthFlag', contractGroup.isLastMonth)
              commit('setQ1Answer', contractGroup.q1)
              commit('setQ2Answer', contractGroup.q2)
              commit('setQ3Answer', contractGroup.q3)
              commit('setQ4Answer', contractGroup.q4)
              commit('setQuestionComment', contractGroup.questionComment)
              resolve(contractGroup);
            } else {
              console.log(contractGroup);
              resolve(contractGroup);
            }
          }
          catch (e) {
            reject(e)
          }
        })();
      });
    },
    postEntryIdContractGroupIdReportCancel: ({ commit }, { entryId, contractGroupId }) => {
      return new Promise((resolve, reject) => {
        (async () => {

          const result = await MemberAPI.postEntryIdContractGroupIdReportCancel(entryId, contractGroupId, {
            "entryId": entryId,
            "contractGroupId": contractGroupId
          }).catch(e => {
            console.log(e)
            reject(e)
            return false;
          })

          if (!result) {
            return false;
          }
          commit('setContractGroupId', null);
          commit('setTermStart', null);
          commit('setTermEnd', null);
          commit('setStartDate', null);
          commit('setEndDate', null);
          commit('setUnitPrice', 0);
          commit('setReportStatus', null);
          commit('setTransferType', null);
          commit('setOfferNote', false);
          commit('setReportComment', []);
          commit('setNote', null);
          commit('setItems', []);
          commit('setExpenses', []);
          commit('setReportFiles', []);
          commit('setQuestion1', null)
          commit('setQuestion2', null)
          commit('setQuestion3', null)
          commit('setQuestion4', null)
          commit('setQuestionComment', null)
          commit('setQ1Answer', null)
          commit('setQ2Answer', null)
          commit('setQ3Answer', null)
          commit('setQ4Answer', null)

          resolve(result)
        })();
      })
    },
    downloadReport: ( commit ,{entryId, contractGroupId} ) => {
      return new Promise((resolve, reject) => {
        (async () => {
          try {
            // キーの取得
            MemberAPI.postReportIdContractReportKey(entryId,contractGroupId)
              .then((key) => {
                console.log(key)
                // 取得したキーを使用して業務報告書兼請求書をDL
                const url = process.env.VUE_APP_DOWNLOAD_ENTRY_POINT + "api/backend/user/download/report/invoice/" + key

                if (!window.open(url)) {
                  location.href = url;
                } else {
                  window.open(url);
                }
                console.log("success", key);

              })
              .catch((error) => {
                reject(error)
              });
          }
          catch (e) {
            reject(e)
          }
        })();
      });

    }
  },
  modules: {
  },
  getters: {
    totalWorkingTime(state) {
      let hours = 0;
      for (const i in state.items) {
        const item = state.items[i];
        hours += item.workingHour - 0;
      }
      return Math.floor(hours * 10) / 10;
    },
    totalPaymentAmount(state) {
      let totalAmount = 0;
      for (const i in state.items) {
        const item = state.items[i];
        totalAmount = ((totalAmount * 10) + (item.paymentAmount * 10)) / 10 - 0;
      }
      return totalAmount;
    },
    taxAmount(state) {
      // return Math.floor(getters.totalPaymentAmount * 0.1);
      let totalTax = 0;
      for (const i in state.items) {
        const item = state.items[i];
        console.log(item.paymentAmount)
        totalTax += Math.floor(Number(item.paymentAmount) * 0.1);
      }
      console.log(totalTax)
      return totalTax;
    },
    taxWithholdingAmount(state) {
      return state.taxWithholdingAmount
    },
    totalPaymentAmountInTax(state, getters) {
      return getters.totalPaymentAmount + getters.taxAmount;
    },
  }
}