const CodeHelper = {
    getCodeObject: (arr, code) => {
        for (const i in arr) {
            const obj = arr[i]
            if (obj.code == code) {
                return obj;
            }

            if (obj.children) {
                const result = CodeHelper.getCodeObject(obj.children, code)
                if (result) {
                    return result;
                }
            }
        }
        return null
    },

    getTopLevelObject: (arr, code) => {
        for (const i in arr) {
            const obj = arr[i]
            if (obj.code == code) {
                return obj;
            }

            if (obj.children) {
                const result = CodeHelper.getCodeObject(obj.children, code)
                if (result) {
                    return obj;
                }
            }
        }
        return null
    },

    // TODO: deprecated
    setParent: (codeList, parentCode) => {
        for (const i in codeList) {
            const code = codeList[i]

            if (parentCode) {
                code.parentCode = parentCode
            }

            if (code.children) {
                CodeHelper.setParent(code.children, code)
            }
        }
    },

    getTopLevelCode: (arr, code) => {
        if (arr.children) {
            for (var i = 0; i < arr.children.length; i++) {
                if (arr.children[i].code == code.code) {
                    return arr.code
                }
            }
        } else {
            return null
        }
    }
};

export default CodeHelper;