// import Vue from 'vue'
import MemberAPI from '@/api/member'

export default {
    namespaced: true,
    state: {
        bankType: {code:'01', name:'銀行・信用金庫'},
        bank: null,
        branch: null,
        accountType: {code:'01', name:'普通'},
        accountCode: "",
        accountNumber: "",
        accountName: "",
        reason: "",
        needReasonFlg: false,
    },
    mutations: {
        setBankProfile: (state, bankProfile)=>{
            state.bankType = bankProfile.bankType
            state.bank = bankProfile.bank
            state.branch = bankProfile.branch
            state.accountType = bankProfile.accountType
            state.accountCode = bankProfile.accountCode
            state.accountNumber = bankProfile.accountNumber
            state.accountName = bankProfile.accountName
            state.reason = bankProfile.reason
        },
        setNeedReasonFlg:(state,v)=>{
            state.needReasonFlg = v
        }
    },
    actions: {
        getBankAccount: ({commit}) => {
            return new Promise((resolve)=>{
                (async()=>{
        
                    const result = await MemberAPI.getBank().catch(e=>{
                        console.log(e)
                        return false;
                    })

                    if(!result){
                        return false;
                    }

                    commit('setBankProfile', result)
            
                    resolve()
        
                })();
            })
        },
        postBankAccount: ({commit, state}) => {
            if(!state.needReasonFlg){  // 入力した名義人とプロフィールの名前が同じ場合はreasonをnullにする
                state.reason = null
            }

            return new Promise((resolve)=>{
                (async()=>{
        
                    const result = await MemberAPI.postBank({
                        bankType: state.bankType,
                        bank: state.bankType.code == '01' ? state.bank : null,
                        branch: state.bankType.code == '01' ? state.branch : null,
                        accountType: state.bankType.code == '01' ? state.accountType : null,
                        accountCode: state.bankType.code == '02' ? state.accountCode : null,
                        accountNumber: state.accountNumber,
                        accountName: state.accountName,
                        reason: state.reason,
                    }).catch(e=>{
                        console.log(e)
                        return false;
                    })

                    if(!result){
                        return false;
                    }
                    
                    commit('setBankProfile', result)
            
                    resolve()
        
                })();
            })
        }
    },
    modules: {
    }
  }