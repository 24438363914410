
import Auth from '@/auth'
import PublicAPI from '@/api/public'

export default {
    namespaced: true,
    state: {
        isLoggedIn: false,
        email: '',
        password: '',
        // canLoggedIn: true,
        reCaptchaToken: '',
        errMsg: null,
        response: null,

        isFromConfirmation: false,
        isInvalidUrl: false  // 認証URLが無効だった場合
    },
    mutations: {
        updateLoginStatus: (state, v) => {
            state.isLoggedIn = v
        },
        setEmail: (state, v) => {
            state.email = v
        },
        setPassword: (state, v) => {
            state.password = v
        },
        // パスワードロック
        updateCanLoggedInStatus: (state, v) => {
            state.response = v
        },
        setRecaptchaToken: (state, v) => {
            state.reCaptchaToken = v
        },
        unsetRecaptchaToken: (state) => {
            state.reCaptchaToken = ''
        },
        setIsFromConfirmation: (state, v) => {
            state.isFromConfirmation = v
        },
        setIsInvalidUrl: (state, v) => {
            state.isInvalidUrl = v
        }
    },
    actions: {
        updateLoginStatus: ({ commit }) => {
            return new Promise((resolve) => {
                Auth.isAuthenticated().then(() => {
                    commit('updateLoginStatus', true)
                    resolve()
                }).catch(() => {
                    commit('updateLoginStatus', false)
                    resolve()
                })
            })
        },
        resetItems: ({ commit }) => {
            commit('setEmail', '')
            commit('setPassword', '')
        },
        tryLoggedIn: ({ state, commit }) => {
            return new Promise((resolve, reject) => {
                PublicAPI.postPasswordLock(state.reCaptchaToken)
                    .then((response) => {
                        console.log(response)
                        if (response) {
                            commit('updateCanLoggedInStatus', response)
                            resolve(state.response)
                        }
                    }).catch((e) => {
                        console.log(e)
                        reject(e)
                    })
            })
        }        
    },
    modules: {
    }
}