import MemberAPI from '@/api/member'

export default {
  namespaced: true,
  state: {
    invoiceNumber: '',
    invoiceStatus: null,
    registerDate: null,
  },
  mutations: {
    setInvoiceNumber(state, result)  {
      state.invoiceNumber = result.invoice_number;
      if(result.invoice_status) {
        state.invoiceStatus = result.invoice_status;
      } else {
        state.invoiceStatus = "1";
      }
      if (result.registerDate) {
        state.registerDate = result.registerDate;
      }
    },
    resetInvoiceNumber(state) {
      state.invoiceNumber = '';
      state.invoiceStatus = "1",
      state.registerDate = null;
    },
  },
  actions: {
    getInvoiceNumber: ({ commit }) => {
      return new Promise((resolve, reject) => {
        (async () => {

          const result = await MemberAPI.getInvoiceNumber().catch(e => {
            console.log(e)
            reject();
          })

          if (!result) {
            reject();
          }
          commit('setInvoiceNumber', result)

          resolve()

        })();
      })
    },
    postInvoiceNumber: ({ commit }, { invoiceNumber, invoiceStatus } ) => {
      let invoice_info = { invoiceNumber, invoiceStatus };
      return new Promise((resolve, reject) => {
        (async () => {
          const result = await MemberAPI.postInvoiceNumber(invoice_info).catch(e => {
            console.log(e);
            reject({
              error: e
            });
          })

          if (!result) {
            reject();
          } else {
            commit('setInvoiceNumber', result)
          }

          resolve()

        })();
      })
    }
  },
  modules: {
  }
}