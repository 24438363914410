import Vue from 'vue'
import VueRouter from 'vue-router'
const Home = () => import('../views/Home.vue')
import Auth from '@/auth'
import store from '@/store'

const AuthLogin = () => import('../views/auth/Login.vue')
const AuthReminder = () => import('../views/auth/Reminder.vue')
const AuthReminderSent = () => import('../views/auth/ReminderSent.vue')
const AuthReminderChange = () => import('../views/auth/ReminderChange.vue')

const JobCorporateID = () => import('../views/job/CorporateId.vue')
const JobPreview = () => import('../views/job/Preview.vue')
const JobSearch = () => import('../views/job/Search.vue')
const JobCorporateIdJobId = () => import('../views/job/CorporateIdJobId.vue')
const JobCorporateIdJobIdEntry1 = () => import('../views/job/CorporateIdJobIdEntry1.vue')
const JobCorporateIdJobIdEntry1Confirm = () => import('../views/job/CorporateIdJobIdEntry1Confirm.vue')

const CorporateIdAbuse = () => import('../views/job/CorporateIdAbuse.vue')
const JobCorporateIDPreview = () => import('../views/job/CorporateIdPreview.vue')

const MyPage_Home = () => import('../views/mypage/Home.vue')
const MyPage_Works = () => import('../views/mypage/Works.vue')
const MyPage_Works_EntryId = () => import('../views/mypage/works/EntryId.vue')
const MyPage_Works_EntryId_Job = () => import('../views/mypage/works/EntryIdJob.vue')
const MyPage_Works_EntryId_Offers = () => import('../views/mypage/works/Offers.vue')
const MyPage_Works_EntryId_Offer_Create = () => import('../views/mypage/works/offer/Create.vue')
const MyPage_Works_EntryId_Offer_Create2 = () => import('../views/mypage/works/offer/Create2.vue')
const MyPage_Works_EntryId_Offer_Create2SPRowNo = () => import('../views/mypage/works/offer/Create2SPRowNo.vue')
const MyPage_Works_EntryId_Offer_Confirm = () => import('../views/mypage/works/offer/Confirm.vue')
const MyPage_Works_EntryId_Offer_OrderIdUpdate = () => import('../views/mypage/works/offer/OfferIdUpdate.vue')
const MyPage_Works_EntryId_Offer_OrderIdUpdate2 = () => import('../views/mypage/works/offer/OfferIdUpdate2.vue')
const MyPage_Works_EntryId_Offer_OrderIdUpdate2SPRowNo = () => import('../views/mypage/works/offer/OfferIdUpdate2SPRowNo.vue')
const MyPage_Works_EntryId_Offer_OrderIdConfirm = () => import('../views/mypage/works/offer/OfferIdConfirm.vue')
const MyPage_Works_EntryId_Offer_OrderIdConfirmSP = () => import('../views/mypage/works/offer/OfferIdConfirmSP.vue')
const MyPage_Works_EntryId_Offer_OfferId = () => import('../views/mypage/works/offer/OfferId.vue')
const MyPage_Works_EntryId_Reports = () => import('../views/mypage/works/Reports.vue')
const MyPage_Works_EntryId_Report_ReportIdUpdate = () => import('../views/mypage/works/report/ReportIdUpdate.vue')
const MyPage_Works_EntryId_Report_ReportIdUpdateSPRowNo = () => import('../views/mypage/works/report/ReportIdUpdateSPRowNo.vue')
const MyPage_Works_EntryId_Report_ReportIdUpdateExpensesSP = () => import('../views/mypage/works/report/ReportIdUpdateExpensesSP.vue')
const MyPage_Works_EntryId_Report_ReportId = () => import('../views/mypage/works/report/ReportId.vue')
const MyPage_Reward = () => import('../views/mypage/Reward.vue')
const MyPage_MessageChats = () => import('../views/mypage/MessageChat.vue')
const MyPage_Scout = () => import('../views/mypage/Scout.vue')
const MyPage_Scout_ScoutId = () => import('../views/mypage/scout/ScoutId.vue')
const MyPage_Setting = () => import('../views/mypage/Setting.vue')
const MyPage_Setting_Basic = () => import('../views/mypage/setting/Basic.vue')
const MyPage_Confirmation = () => import('../views/mypage/Confirmation.vue')
const MyPage_Setting_Profile = () => import('../views/mypage/setting/Profile.vue')
const MyPage_Setting_Skill = () => import('../views/mypage/setting/Skill.vue')
const MyPage_Setting_Portfolio = () => import('../views/mypage/setting/Portfolio.vue')
const MyPage_Setting_Bank = () => import('../views/mypage/setting/Bank.vue')
const MyPage_Setting_Verify = () => import('../views/mypage/setting/Verify.vue')
const MyPage_Setting_Verify_Submit = () => import('../views/mypage/setting/verify/Submit.vue')
const MyPage_Setting_Mute = () => import('../views/mypage/setting/Mute.vue')
const MyPage_Setting_Invoice = () => import('../views/mypage/setting/Invoice.vue')
const MyPage_Setting_NDA = () => import('../views/mypage/setting/NDA.vue')
const MyPage_Setting_Withdrawal = () => import('../views/mypage/setting/Withdrawal.vue')
const MyPage_Setting_Withdrawal2 = () => import('../views/mypage/setting/Withdrawal2.vue')

const Magazine = () => import('../views/magazine/Magazine.vue')
const Magazine_Category = () => import('../views/magazine/Category.vue')
const Magazine_MagazineId = () => import('../views/magazine/MagazineId.vue')
const MagazinePreview = () => import('../views/magazine/MagazinePreview.vue')

const Pickup = () => import('../views/pickup/Pickup.vue')
const Pickup_PickupId = () => import('../views/pickup/PickupId.vue')

const Contact = () => import('../views/contacts/Contact.vue')
const ContactConfirm = () => import('../views/contacts/ContactConfirm.vue')
const ContactComplete = () => import('../views/contacts/ContactComplete.vue')
// const Improvement = () => import('../views/improvement/Improvement.vue')

const Terms = () => import('../views/pages/Terms.vue')
const Privacy1 = () => import('../views/pages/Privacy1.vue')
const Privacy2 = () => import('../views/pages/Privacy2.vue')

// /aboutは実装後にルーティング復活
// const About= () =>import( '../views/pages/About.vue')

const Guide = () => import('../views/pages/Guide.vue')
const Guide_GuideId = () => import('../views/pages/GuideId.vue')
const GuidePreview = () => import('../views/pages/GuidePreview.vue')

const Topic = () => import('../views/topic/Topic.vue')
const Topic_TopicId = () => import('../views/topic/TopicId.vue')
const History = () => import('../views/lists/History.vue')
const Follow = () => import('../views/lists/Follow.vue')
const Interest = () => import('../views/lists/Interest.vue')

const SignUpInput = () => import('../views/signup/Input.vue')
const SignUpSent = () => import('../views/signup/Sent.vue')
const SignUpConfirmation = () => import('../views/signup/Confirmation.vue')

const ProfileStep1 = () => import('../views/profile/Step1.vue')
const ProfileStep2 = () => import('../views/profile/Step2.vue')
const ProfileStep3 = () => import('../views/profile/Step3.vue')
const ProfileStep4 = () => import('../views/profile/Step4.vue')
const ProfileStep5 = () => import('../views/profile/Step5.vue')
const ProfileStep6 = () => import('../views/profile/Step6.vue')
const ProfileComplete = () => import('../views/profile/Complete.vue')

const Publish = () => import('../views/publish/Publish.vue')
const PublishConfirm = () => import('../views/publish/PublishConfirm.vue')
const PublishComplete = () => import('../views/publish/PublishComplete.vue')

const Membership =()=>import('../views/membership/Membership.vue')

const Error404 = () => import('../views/errors/Error404.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { publicAccess: true }
  },

  // 認証
  {
    path: '/login',
    name: 'AuthLogin',
    component: AuthLogin,
    meta: { publicAccess: true }
  },

  {
    path: '/reminder',
    name: 'AuthReminder',
    component: AuthReminder,
    meta: { publicAccess: true }
  },

  {
    path: '/reminder/sent',
    name: 'AuthReminderSent',
    component: AuthReminderSent,
    meta: { publicAccess: true }
  },

  {
    path: '/reminder/change/:key',
    name: 'AuthReminderChange',
    component: AuthReminderChange,
    meta: { publicAccess: true }
  },

  {
    path: '/logout',
    name: 'AuthLogout',
    beforeEnter: () => {
      (async () => {
        try {
          store.commit("auth/updateLoginStatus", false);
          await Auth.logout();
        }
        catch (e) {
          console.log(e);
        }

        await store.dispatch("follows/clearLocal");

        window.location.href = "/";

        return;

      })();
    }
  },

  // 企業
  {
    path: '/job/:corporate_id(\\d+)',
    name: 'JobCorporateID',
    component: JobCorporateID,
    meta: { publicAccess: true }
  },

  {
    path: '/job/:corporate_id(\\d+)/preview',
    name: 'JobCorporateIDPreview',
    component: JobCorporateIDPreview,
    meta: { publicAccess: true }
  },

  {
    path: '/job/:corporate_id(\\d+)/abuse',
    name: 'CorporateIdAbuse',
    component: CorporateIdAbuse,
    meta: { publicAccess: true }
  },

  // ジョブ
  {
    path: '/job/:company_id(\\d+)/preview/:key',
    name: 'JobPreview1',
    component: JobPreview,
    meta: { publicAccess: true }
  },

  {
    path: '/job/:company_id(\\d+)/:job_id(\\d+)/preview',
    name: 'JobPreview2',
    component: JobPreview,
    meta: { publicAccess: true }
  },

  {
    path: '/job/:company_id(\\d+)/:job_id(\\d+)/revision/:revision_id(\\d+)/preview',
    name: 'JobPreview4',
    component: JobPreview,
    meta: { publicAccess: true }
  },

  {
    path: '/job/:corporate_id(\\d+)/:job_id(\\d+)',
    name: 'JobCorporateIdJobId',
    component: JobCorporateIdJobId,
    meta: { publicAccess: true }
  },

  {
    path: '/job/:corporate_id(\\d+)/:job_id(\\d+)/entry1',
    name: 'JobCorporateIdJobIdEntry1',
    component: JobCorporateIdJobIdEntry1,
  },

  {
    path: '/job/:corporate_id(\\d+)/:job_id(\\d+)/entry1/confirm',
    name: 'JobCorporateIdJobIdEntry1Confirm',
    component: JobCorporateIdJobIdEntry1Confirm,
  },

  {
    path: '/job/:corporate_id(\\d+)/:job_id(\\d+)/entry2',
    name: 'JobIdEntry2',
    component: AuthLogin,
    meta: { publicAccess: true }
  },

  {
    path: '/job/:corporate_id(\\d+)/:job_id(\\d+)/entry2/register',
    name: 'JobIdEntry2Register',
    component: SignUpInput,
    meta: { publicAccess: true }
  },

  {
    path: '/job/:corporate_id(\\d+)/:job_id(\\d+)/entry2/step1',
    name: 'JobIdEntry2Step1',
    component: ProfileStep1,
    meta: { publicAccess: true }
  },
  {
    path: '/job/:corporate_id(\\d+)/:job_id(\\d+)/entry2/step2',
    name: 'JobIdEntry2Step2',
    component: ProfileStep2,
    meta: { publicAccess: true }
  },
  {
    path: '/job/:corporate_id(\\d+)/:job_id(\\d+)/entry2/step3',
    name: 'JobIdEntry2Step3',
    component: ProfileStep3,
    meta: { publicAccess: true }
  },
  {
    path: '/job/:corporate_id(\\d+)/:job_id(\\d+)/entry2/step4',
    name: 'JobIdEntry2Step4',
    component: ProfileStep4,
    meta: { publicAccess: true }
  },
  {
    path: '/job/:corporate_id(\\d+)/:job_id(\\d+)/entry2/step5',
    name: 'JobIdEntry2Step5',
    component: ProfileStep5,
    meta: { publicAccess: true }
  },
  {
    path: '/job/:corporate_id(\\d+)/:job_id(\\d+)/entry2/step6',
    name: 'JobIdEntry2Step6',
    component: ProfileStep6,
    meta: { publicAccess: true }
  },
  {
    path: '/job/:corporate_id(\\d+)/:job_id(\\d+)/entry2/sent',
    name: 'JobIdEntry2Sent',
    meta: { publicAccess: true },
    component: SignUpSent
  },

  // 求人検索
  {
    path: '/job',
    name: 'JobSearch',
    component: JobSearch,
    meta: { publicAccess: true }
  },
  {
    path: '/job/:p1',
    name: 'JobSearchP1',
    component: JobSearch,
    meta: { publicAccess: true }
  },
  {
    path: '/job/:p1/:p2',
    name: 'JobSearchP2',
    component: JobSearch,
    meta: { publicAccess: true }
  },
  {
    path: '/job/:p1/:p2/:p3',
    name: 'JobSearchP3',
    component: JobSearch,
    meta: { publicAccess: true }
  },
  {
    path: '/job/:p1/:p2/:p3/:p4',
    name: 'JobSearchP4',
    component: JobSearch,
    meta: { publicAccess: true }
  },
  {
    path: '/job/:p1/:p2/:p3/:p4/:p5',
    name: 'JobSearchP5',
    component: JobSearch,
    meta: { publicAccess: true }
  },
  {
    path: '/job/:p1/:p2/:p3/:p4/:p5/:p6',
    name: 'JobSearchP6',
    component: JobSearch,
    meta: { publicAccess: true }
  },
  {
    path: '/job/:p1/:p2/:p3/:p4/:p5/:p6/:p7',
    name: 'JobSearchP7',
    component: JobSearch,
    meta: { publicAccess: true }
  },
  {
    path: '/job/:p1/:p2/:p3/:p4/:p5/:p6/:p7/:p8',
    name: 'JobSearchP8',
    component: JobSearch,
    meta: { publicAccess: true }
  },
  {
    path: '/job/:p1/:p2/:p3/:p4/:p5/:p6/:p7/:p8/:p9',
    name: 'JobSearchP9',
    component: JobSearch,
    meta: { publicAccess: true }
  },


  // 会員登録
  {
    path: '/signup',
    name: 'SignUpInput',
    component: SignUpInput,
    meta: { publicAccess: true }
  },

  {
    path: '/signup/sent',
    name: 'SignUpSent',
    component: SignUpSent,
    meta: { publicAccess: true }
  },

  {
    path: '/signup/confirmation',
    name: 'SignUpConfirmation',
    component: SignUpConfirmation,
    meta: { publicAccess: true }
  },

  // 基本情報登録
  {
    path: '/signup/profile/step1',
    name: 'ProfileStep1',
    component: ProfileStep1
  },
  {
    path: '/signup/profile/step2',
    name: 'ProfileStep2',
    component: ProfileStep2
  },
  {
    path: '/signup/profile/step3',
    name: 'ProfileStep3',
    component: ProfileStep3
  },
  {
    path: '/signup/profile/step4',
    name: 'ProfileStep4',
    component: ProfileStep4
  },
  {
    path: '/signup/profile/step5',
    name: 'ProfileStep5',
    component: ProfileStep5
  },
  {
    path: '/signup/profile/step6',
    name: 'ProfileStep6',
    component: ProfileStep6
  },
  {
    path: '/signup/profile/complete',
    name: 'ProfileComplete',
    component: ProfileComplete
  },

  // マイページ
  {
    path: '/mypage',
    name: 'MyPage_Home',
    component: MyPage_Home
  },

  {
    path: '/mypage/confirmation',
    name: 'MyPage_Confirmation',
    component: MyPage_Confirmation
  },

  {
    path: '/mypage/works',
    name: 'MyPage_Works',
    component: MyPage_Works
  },

  {
    path: '/mypage/works/:entry_id(\\d+)',
    name: 'MyPage_Works_EntryId',
    component: MyPage_Works_EntryId
  },

  {
    path: '/mypage/works/:entry_id(\\d+)/job',
    name: 'MyPage_Works_EntryId_Job',
    component: MyPage_Works_EntryId_Job
  },

  {
    path: '/mypage/works/:entry_id(\\d+)/offers',
    name: 'MyPage_Works_EntryId_Offers',
    component: MyPage_Works_EntryId_Offers
  },

  {
    path: '/mypage/works/:entry_id(\\d+)/offer/create',
    name: 'MyPage_Works_EntryId_Offer_Create',
    component: MyPage_Works_EntryId_Offer_Create
  },

  {
    path: '/mypage/works/:entry_id(\\d+)/offer/create2',
    name: 'MyPage_Works_EntryId_Offer_Create2',
    component: MyPage_Works_EntryId_Offer_Create2
  },

  {
    path: '/mypage/works/:entry_id(\\d+)/offer/create2/:row_no(\\d+)',
    name: 'MyPage_Works_EntryId_Offer_Create2SPRowNo',
    component: MyPage_Works_EntryId_Offer_Create2SPRowNo
  },

  {
    path: '/mypage/works/:entry_id(\\d+)/offer/confirm',
    name: 'MyPage_Works_EntryId_Offer_Confirm',
    component: MyPage_Works_EntryId_Offer_Confirm
  },

  {
    path: '/mypage/works/:entry_id(\\d+)/offer/:offer_id(\\d+)',
    name: 'MyPage_Works_EntryId_Offer_OfferId',
    component: MyPage_Works_EntryId_Offer_OfferId
  },

  {
    path: '/mypage/works/:entry_id(\\d+)/offer/:offer_id(\\d+)/update',
    name: 'MyPage_Works_EntryId_Offer_OfferIdUpdate',
    component: MyPage_Works_EntryId_Offer_OrderIdUpdate
  },

  {
    path: '/mypage/works/:entry_id(\\d+)/offer/:offer_id(\\d+)/update2',
    name: 'MyPage_Works_EntryId_Offer_OfferIdUpdate2',
    component: MyPage_Works_EntryId_Offer_OrderIdUpdate2
  },

  {
    path: '/mypage/works/:entry_id(\\d+)/offer/:offer_id(\\d+)/update2/:row_no(\\d+)',
    name: 'MyPage_Works_EntryId_Offer_OfferIdUpdate2RowNo',
    component: MyPage_Works_EntryId_Offer_OrderIdUpdate2SPRowNo,
  },

  {
    path: '/mypage/works/:entry_id(\\d+)/offer/:offer_id(\\d+)/confirm',
    name: 'MyPage_Works_EntryId_Offer_OfferIdConfirm',
    component: MyPage_Works_EntryId_Offer_OrderIdConfirm
  },

  {
    path: '/mypage/works/:entry_id(\\d+)/offer/:offer_id(\\d+)/confirmsp',
    name: 'MyPage_Works_EntryId_Offer_OrderIdConfirmSP',
    component: MyPage_Works_EntryId_Offer_OrderIdConfirmSP
  },

  {
    path: '/mypage/works/:entry_id(\\d+)/reports',
    name: 'MyPage_Works_EntryId_Reports',
    component: MyPage_Works_EntryId_Reports
  },

  {
    path: '/mypage/works/:entry_id(\\d+)/report/:report_id(\\d+)',
    name: 'MyPage_Works_EntryId_Report_ReportId',
    component: MyPage_Works_EntryId_Report_ReportId
  },

  {
    path: '/mypage/works/:entry_id(\\d+)/report/:report_id(\\d+)/update',
    name: 'MyPage_Works_EntryId_Report_ReportIdUpdate',
    component: MyPage_Works_EntryId_Report_ReportIdUpdate
  },

  {
    path: '/mypage/works/:entry_id(\\d+)/report/:report_id(\\d+)/update/:row_no(\\d+)',
    name: 'MyPage_Works_EntryId_Report_ReportIdUpdateSPRowNo',
    component: MyPage_Works_EntryId_Report_ReportIdUpdateSPRowNo
  },

  {
    path: '/mypage/works/:entry_id(\\d+)/report/:report_id(\\d+)/updateExpenses',
    name: 'MyPage_Works_EntryId_Report_ReportIdUpdateExpensesSP',
    component: MyPage_Works_EntryId_Report_ReportIdUpdateExpensesSP
  },

  {
    path: '/mypage/reward',
    name: 'MyPage_Reward',
    component: MyPage_Reward
  },

  {
    path: '/mypage/message-chat',
    name: 'MyPage_MessageChats',
    component: MyPage_MessageChats
  },

  {
    path: '/mypage/message-chat/:entry_id(\\d+)',
    name: 'MyPage_MessageChats_EntryId',
    component: MyPage_MessageChats
  },

  {
    path: '/mypage/scout',
    name: 'MyPage_Scout',
    component: MyPage_Scout
  },

  {
    path: '/mypage/scout/:scout_id(\\d+)',
    name: 'MyPage_Scout_ScoutId',
    component: MyPage_Scout_ScoutId
  },

  {
    path: '/mypage/setting',
    name: 'MyPage_Setting',
    component: MyPage_Setting
  },

  {
    path: '/mypage/setting/basic',
    name: 'MyPage_Setting_Basic',
    component: MyPage_Setting_Basic
  },

  {
    path: '/mypage/setting/profile',
    name: 'MyPage_Setting_Profile',
    component: MyPage_Setting_Profile
  },

  {
    path: '/mypage/setting/skill',
    name: 'MyPage_Setting_Skill',
    component: MyPage_Setting_Skill
  },

  {
    path: '/mypage/setting/portfolio',
    name: 'MyPage_Setting_Portfolio',
    component: MyPage_Setting_Portfolio
  },

  {
    path: '/mypage/setting/bank',
    name: 'MyPage_Setting_Bank',
    component: MyPage_Setting_Bank
  },

  {
    path: '/mypage/setting/verify',
    name: 'MyPage_Setting_Verify',
    component: MyPage_Setting_Verify
  },

  {
    path: '/mypage/setting/verify/submit',
    name: 'MyPage_Setting_Verify_Submit',
    component: MyPage_Setting_Verify_Submit
  },

  {
    path: '/mypage/setting/mute',
    name: 'MyPage_Setting_Mute',
    component: MyPage_Setting_Mute
  },

  {
    path: '/mypage/setting/invoice',
    name: 'MyPage_Setting_Invoice',
    component: MyPage_Setting_Invoice
  },

  {
    path: '/mypage/setting/nda',
    name: 'MyPage_Setting_NDA',
    component: MyPage_Setting_NDA
  },

  {
    path: '/mypage/setting/withdrawal',
    name: 'MyPage_Setting_Withdrawal',
    component: MyPage_Setting_Withdrawal
  },

  {
    path: '/mypage/setting/withdrawal2',
    name: 'MyPage_Setting_Withdrawal2',
    component: MyPage_Setting_Withdrawal2
  },

  {
    path: '/magazine',
    name: 'Magazine',
    component: Magazine,
    meta: { publicAccess: true }
  },

  {
    path: '/magazine/category/:categoryCode(\\d+)',
    name: 'Magazine_Category',
    component: Magazine_Category,
    meta: { publicAccess: true }
  },

  {
    path: '/magazine/:magazine_id(\\d+)',
    name: 'Magazine_MagazineId',
    component: Magazine_MagazineId,
    meta: { publicAccess: true }
  },

  {
    path: '/magazine/preview/:key',
    name: 'MagazinePreview',
    component: MagazinePreview,
    meta: { publicAccess: true }
  },


  {
    path: '/pickup',
    name: 'Pickup',
    component: Pickup,
    meta: { publicAccess: true }
  },

  {
    path: '/pickup/:pickup_id(\\d+)',
    name: 'Pickup_PickupId',
    component: Pickup_PickupId,
    meta: { publicAccess: true }
  },

  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: { publicAccess: true }
  },

  {
    path: '/contact/confirm',
    name: 'ContactConfirm',
    component: ContactConfirm,
    meta: { publicAccess: true }
  },

  {
    path: '/contact/complete',
    name: 'ContactComplete',
    component: ContactComplete,
    meta: { publicAccess: true }
  },

  // {
  //   path: '/improvement',
  //   name: 'Improvement',
  //   component: Improvement,
  //   meta: { publicAccess: true }
  // },

  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
    meta: { publicAccess: true }
  },

  {
    path: '/privacy1',
    name: 'Privacy1',
    component: Privacy1,
    meta: { publicAccess: true }
  },

  {
    path: '/privacy2',
    name: 'Privacy2',
    component: Privacy2,
    meta: { publicAccess: true }
  },

  // /aboutページは実装後にルーティング復活
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: About,
  //   meta: { publicAccess: true }
  // },

  {
    path: '/guide',
    name: 'Guide',
    component: Guide,
    meta: { publicAccess: true }
  },

  {
    path: '/guide/:guide_id(\\d+)',
    name: 'Guide_GuideId',
    component: Guide_GuideId,
    meta: { publicAccess: true }
  },

  {
    path: '/guide/preview/:key',
    name: 'GuidePreview',
    component: GuidePreview,
    meta: { publicAccess: true }
  },

  {
    path: '/topic',
    name: 'Topic',
    component: Topic,
    meta: { publicAccess: true }
  },

  {
    path: '/topic/:topic_id(\\d+)',
    name: 'Topic_TopicId',
    component: Topic_TopicId,
    meta: { publicAccess: true }
  },

  {
    path: '/history',
    name: 'History',
    component: History,
    meta: { publicAccess: true }
  },

  {
    path: '/interest',
    name: 'Interest',
    component: Interest,
    meta: { publicAccess: true }
  },

  {
    path: '/follow',
    name: 'Follow',
    component: Follow,
    meta: { publicAccess: true }
  },

  {
    path: '/publish',
    name: 'Publish',
    component: Publish,
    meta: { publicAccess: true }
  },

  {
    path: '/publish/confirm',
    name: 'PublishConfirm',
    component: PublishConfirm,
    meta: { publicAccess: true }
  },

  {
    path: '/publish/complete',
    name: 'PublishComplete',
    component: PublishComplete,
    meta: { publicAccess: true }
  },

  {
    path: '/membership',
    name: 'Membership',
    component: Membership,
  },

  // エラーページ
  {
    path: '/*',
    name: 'Error404',
    component: Error404,
    meta: { publicAccess: true }
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})


router.beforeResolve(async (to, from, next) => {

  if (!document.title) {
    document.title = `スキイキ`;
  }
  try {
    await store.dispatch('auth/updateLoginStatus')
  }
  catch {
    //
  }
  const isLoggedIn = store.state.auth.isLoggedIn;

  if (to.meta.publicAccess) {
    if (isLoggedIn) {
      switch (to.name) {
        case 'Login':
        case 'SignUpInput':
        case 'SignUpSent':
          return next({ path: '/mypage' });
        case 'JobIdEntry2':
        case 'JobIdEntry2Register':
        case 'JobIdEntry2Step1':
        case 'JobIdEntry2Step2':
        case 'JobIdEntry2Step3':
        case 'JobIdEntry2Step4':
        case 'JobIdEntry2Step5':
        case 'JobIdEntry2Step6':
        case 'JobIdEntry2Sent':
          return next({ path: '/job/' + to.params.corporate_id + '/' + to.params.job_id + '/entry1' });
      }
    }

    return next();
  }
  else {
    if (isLoggedIn) {
      return next();
    } else if ((to.name == "MyPage_Setting_Withdrawal2") && (from.name == "MyPage_Setting_Withdrawal")) {
      return next()
    }
    else {
      // localStorageに遷移先urlを保持
      // loginコンポーネントのmountedで破棄
      localStorage.setItem("nextPage",to.fullPath);
      return next({
        path: '/login'
      });
    }
  }
});

export default router
