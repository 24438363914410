// import Vue from 'vue'
import MemberAPI from '@/api/member'

export default {
  namespaced: true,
  state: {
    "entryId": null,
    "contractType": false,
    "timestamp": null,
    "job": null,
    "isNegotiating": false,
    "isEntrying": false,
    "isEnded": false,
    "isAdded": false,
    "isFailure": false,
    "isChatEnabled": false,
    "isOfferEnabled": false,
    "isWorking": false,
  },
  mutations: {
    setEntryId: (state, v)=>{
      state.entryId = v
    },
    setIsNegotiating: (state, v)=>{
      state.isNegotiating = v
    },
    setIsEntrying: (state, v)=>{
      state.isEntrying = v
    },
    setIsEnded: (state, v)=>{
      state.isEnded = v
    },
    setIsAdded: (state, v)=>{
      state.isAdded = v
    },
    setIsChatEnabled: (state, v)=>{
      state.isChatEnabled = v
    },
    setIsOfferEnabled: (state, v)=>{
      state.isOfferEnabled = v
    },
    setIsWorking: (state, v)=>{
      state.isWorking = v
    },
    setContractType: (state, v)=>{
      state.contractType = v
    },
    setIsFailure: (state, v)=>{
      state.isFailure = v
    },
    setTimestamp: (state, v)=>{
      state.timestamp = v
    },
    setJob: (state, v)=>{
      state.job = v
    },
  },
  actions: {
    getEntryId({commit}, {entryId}){ /* eslint no-unused-vars:0 */
      return new Promise((resolve, reject)=>{
          MemberAPI.getEntryId(entryId).then((entry)=>{
              commit('setEntryId', entry.entryId)
              commit('setIsNegotiating', entry.isNegotiating)
              commit('setIsEntrying', entry.isEntrying)
              commit('setIsEnded', entry.isEnded)
              commit('setIsAdded', entry.isAdded)
              commit('setIsChatEnabled', entry.isChatEnabled)
              commit('setIsOfferEnabled', entry.isOfferEnabled)
              commit('setContractType', entry.contractType)
              commit('setIsFailure', entry.isFailure)
              commit('setIsWorking', entry.isWorking)
              commit('setTimestamp', entry.timestamp)
              commit('setJob', entry.job)
              resolve(entry)
          })
          .catch((e)=>{
              reject(e)
          })
      });
    },

    postEntryIdDecline: ({ commit }, entryId) => {
      return new Promise((resolve,reject) => {
        (async () => {

          const result = await MemberAPI.postEntryIdDecline(entryId).catch(e => {
            console.log(e)
            reject(e)
            return false;
          })

          if (!result) {
            return false;
          }
          resolve()
        })();
      })

    },
  },
  modules: {
  }
}