// import Vue from 'vue'
import MemberAPI from '@/api/member'

export default {
  namespaced: true,
  state: {
    entryId: null,
    offers: []
  },
  mutations: {
    setEntryId: (state, v) => {
      state.entryId = v
    },
    setOffers: (state, v) => {
      state.offers = v
    },
  },
  actions: {
    getOffers: ({ commit }, entryId) => {
      return new Promise((resolve,reject) => {
        (async () => {

          const result = await MemberAPI.getEntryIdOffers(entryId).catch(e => {
            console.log(e)
            reject(e)
            return false;
          })

          if (!result) {
            return false;
          }

          if (result.status != 429) {
            commit('setEntryId', entryId)
            commit('setOffers', result.offers)
          }

          resolve(result)
        })();
      })
    },
    getOffersWithReportId: ({ commit }, { entryId, reportId }) => {
      return new Promise((resolve, reject) => {
        (async () => {

          const result = await MemberAPI.getEntryIdOffers(entryId,reportId).catch(e => {
            console.log(e)
            reject(e)
            return false;
          })

          if (!result) {
            return false;
          }

          if (result.status != 429) {
            commit('setEntryId', entryId)
            commit('setOffers', result.offers)
          }

          resolve(result)
        })();
      })
    },
  },
  modules: {
  },
  getters: {
  }
}