<template>
  <div class="footer_sitedetail">
    <!-- ===== Footer Start ===== -->
    <div id="GlobalFooter">
      <div id="FooterCorp">
        <div id="FooterCorpInfo">
          <div id="FooterCorpId">
            <div id="FooterCorpName">
              <a href="https://www.mynavi.jp/" target="_blank" rel="nofollow noopener">
                <img
                  v-lazy="`/img/cmn/corp_name.gif`"
                  width="100"
                  height="13"
                  alt="株式会社マイナビ"
                />
              </a>
            </div>
            <p id="copyright">Copyright © Mynavi Corporation</p>
          </div>
        </div>
      </div>
    </div>
    <!-- // ===== Footer End ===== -->
  </div>
</template>

<script>
export default {
  name: "SimpleFooter",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
