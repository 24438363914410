import CodeHelper from "@/helpers/code-helper";

export default {
  namespaced: true,
  state: {
    breadCrumbs: [
      { path: "/", name: "スキイキ（業務委託・フリーランス・副業）TOP" },
    ],
  },
  mutations: {
    setBreadCrumbs(state, v) {
      state.breadCrumbs.push(v)
    },
    resetBreadCrumbs(state, sp) {
      if (sp) {
        state.breadCrumbs = [
          { path: "/", name: "TOP" },
        ]
      } else {
        state.breadCrumbs = [
          { path: "/", name: "スキイキ（業務委託・フリーランス・副業）TOP" },
        ]
      }
    },
    resetBreadCrumbsForJob(state, sp) {
      if (sp) {
        state.breadCrumbs = [
          { path: "/", name: "TOP" },
        ]
      } else {
        state.breadCrumbs = [
          { path: "/", name: "フリーランス・業務委託・副業情報TOP" },
        ]
      }
    }
  },
  actions: {
    createBreadCrumbs({ commit, rootState }, { route, mq }) {
      // パンくずリストに動的な要素（各種タイトルなど）を含む場合、それがロードされてからこれを叩く

      var isCompany = rootState.profile.memberKind.code == "02" ? true : false

      // パンくずリスト初期化
      commit('resetBreadCrumbs', mq == 'sp')

      // パンくずリスト作成

      // 対応するpathとnameをbreadCrumbsに入れていく
      // 末端ページでリンクを貼らないものはpathがnullでもOK

      // 非会員ページ
      // マガジン一覧
      if (route.path.indexOf("/magazine") != -1) {
        commit("setBreadCrumbs", {
          path: "/magazine",
          name: "スキイキマガジン",
        });
        // マガジン一覧/カテゴリ別
        if (route.params.categoryCode) {
          switch (route.params.categoryCode) {
            case "01":
              commit("setBreadCrumbs", {
                path: route.path,
                name: "トレンド・フォーカス一覧",
              });
              break;
            case "02":
              commit("setBreadCrumbs", {
                path: route.path,
                name: "インタビュー一覧",
              });
              break;
            case "03":
              commit("setBreadCrumbs", {
                path: route.path,
                name: "基礎知識一覧",
              });
              break;
            case "04":
              commit("setBreadCrumbs", {
                path: route.path,
                name: "ノウハウ・TIPS一覧",
              });
              break;
            default:
              break;
          }
        }
        // マガジン一覧/マガジン詳細
        if (route.params.magazine_id) {
          commit("setBreadCrumbs", {
            path: route.path,
            name: rootState.magazines.magazine.magazineTitle
              ? rootState.magazines.magazine.magazineTitle
              : "",
          });
        }
      }

      // 特集一覧
      if (route.path.indexOf("/pickup") != -1) {
        commit("setBreadCrumbs", {
          path: "/pickup",
          name: "特集一覧",
        });
        // 特集一覧/特集詳細
        if (route.params.pickup_id) {
          commit("setBreadCrumbs", {
            path: route.path,
            name: rootState.pickups.pickup.title
              ? rootState.pickups.pickup.title
              : "",
          });
        }
      }

      // 仕事一覧
      if (route.path.indexOf("/job") != -1) {
        if (route.name == "JobCorporateID") {
          console.log(rootState.company)
          // 企業詳細
          commit("setBreadCrumbs", {
            path: '/job',
            name: '仕事情報 募集一覧'
          })
          commit("setBreadCrumbs", {
            path: `/job/${route.params.corporate_id}`,
            name: `${rootState.company.companyName}の企業情報`
          })
        } else if (route.name == "JobCorporateIdJobId") {
          let job = rootState.jobs.models[Number(route.params.job_id)]
          console.log(job)
          if (job) {
            // 仕事詳細
            // commit('resetBreadCrumbsForJob', mq == 'sp')
            commit("setBreadCrumbs", {
              path: `/job/${CodeHelper.getTopLevelObject(
                rootState.constants.jobCategories,
                job.jobCategory[0].code
              ).code
                }`,
              name: `${CodeHelper.getTopLevelObject(
                rootState.constants.jobCategories,
                job.jobCategory[0].code
              ).name
                }の仕事情報 募集一覧`
            })
            commit("setBreadCrumbs", {
              path: `/job/${route.params.corporate_id}`,
              name: `${job.companyName}の企業情報`
            })
            commit("setBreadCrumbs", {
              path: route.path,
              name: `${job.title}の仕事情報`
            })
          }
        } else {
          // 仕事検索
          if (rootState.jobsearch.breadCrumbMiddle.path != '') {
            commit("setBreadCrumbs", {
              path: rootState.jobsearch.breadCrumbMiddle.path,
              name: rootState.jobsearch.breadCrumbMiddle.text
            })
          }
          if (rootState.jobsearch.breadCrumbLast.path != '') {
            commit("setBreadCrumbs", {
              path: rootState.jobsearch.breadCrumbLast.path,
              name: rootState.jobsearch.breadCrumbLast.text
            })
          }
          if (route.name == "JobSearch" && rootState.jobsearch.breadCrumbMiddle.path == '' && rootState.jobsearch.breadCrumbLast.path == '') {
            commit("setBreadCrumbs", {
              path: '/job',
              name: '業務委託・フリーランス・副業の仕事情報 募集一覧'
            })
          }
        }

      }


      // 会員規約
      if (route.path.indexOf("/terms") != -1) {
        commit("setBreadCrumbs", { path: "/terms", name: "会員規約" });
      }

      // プライバシー
      if (route.path.indexOf("/privacy1") != -1) {
        commit("setBreadCrumbs", {
          path: "/privacy1",
          name: "個人情報の取り扱いについて",
        });
      }
      if (route.path.indexOf("/privacy2") != -1) {
        commit("setBreadCrumbs", {
          path: "/privacy2",
          name: "個人情報の取り扱いについて（人材をお探しの企業様）",
        });
      }

      // お知らせ
      if (route.path.indexOf("/topic") != -1) {
        commit("setBreadCrumbs", {
          path: "/topic",
          name: "お知らせ",
        });
        // お知らせ/お知らせ詳細
        if (route.params.topic_id) {
          var topic = rootState.topics.topic;
          commit("setBreadCrumbs", {
            path: route.path,
            name: topic.topicTitle ? topic.topicTitle : "",
          });
        }
      }


      // 気になるリスト
      if (route.path.indexOf("/interest") != -1) {
        commit("setBreadCrumbs", {
          path: "/interest",
          name: "気になるリスト",
        });
      }
      // 企業フォローリスト
      if (route.path.indexOf("/follow") != -1) {
        commit("setBreadCrumbs", {
          path: "/follow",
          name: "企業フォローリスト",
        });
      }
      // 閲覧履歴
      if (route.path.indexOf("/history") != -1) {
        commit("setBreadCrumbs", {
          path: "/history",
          name: "閲覧履歴",
        });
      }

      // ガイド
      if (route.path.indexOf("/guide") != -1) {
        commit("setBreadCrumbs", {
          path: "/guide",
          name: "ヘルプ・利用ガイド",
        });
        // ガイド/ガイド詳細
        var guide = rootState.guides.guide;
        if (route.params.guide_id) {
          commit("setBreadCrumbs", {
            path: route.path,
            name: guide.guideTitle ? guide.guideTitle : "",
          });
        }
        // ガイド/ガイド詳細
        if (route.name == 'GuidePreview') {
          commit("setBreadCrumbs", {
            path: route.path,
            name: guide.guideTitle ? guide.guideTitle : "",
          });
        }
      }

      // 会員ページ（ログイン後）
      // マイページ
      if (route.path.indexOf("/mypage") != -1) {
        commit("setBreadCrumbs", { path: "/mypage", name: "マイページ" });

        // 仕事管理
        if (route.path.indexOf("/works") != -1) {
          commit("setBreadCrumbs", { path: "/mypage/works", name: "仕事管理" });

          // 条件オファー
          if (route.path.indexOf("/offer") != -1) {
            commit("setBreadCrumbs", { path: `/mypage/works/${route.params.entry_id}/offers`, name: "条件オファー" });
            // 条件オファー作成
            if (route.path.indexOf(`/create`) != -1) {
              commit("setBreadCrumbs", { path: null, name: "新規条件オファー作成" });
            }
            // 条件オファー作成確認
            if (route.path == `/mypage/works/${route.params.entry_id}/offer/confirm`) {
              commit("setBreadCrumbs", { path: null, name: "新規条件オファー作成確認" });
            }
            // 条件オファー変更
            if (route.path.indexOf('/update') != -1) {
              commit("setBreadCrumbs", { path: null, name: "条件オファー変更" });
            }
            // 条件オファー変更確認
            if (route.params.entry_id && route.params.offer_id && route.path.indexOf('/confirm') != -1) {
              commit("setBreadCrumbs", { path: null, name: "条件オファー変更確認" });
            }
            // 条件オファー確認
            if (route.path == `/mypage/works/${route.params.entry_id}/offer/${route.params.offer_id}`) {
              var offerPath = route.query.isReserve ? `${route.path}?isReserve=true` : route.path
              commit("setBreadCrumbs", { path: offerPath, name: "条件オファー確認" });
            }
          }
          // 業務報告
          if (route.path.indexOf("/report") != -1) {
            commit("setBreadCrumbs", { path: `/mypage/works/${route.params.entry_id}/reports`, name: "業務報告" });

            // 業務報告提出
            if (route.path == `/mypage/works/${route.params.entry_id}/report/${route.params.report_id}/update`) {
              commit("setBreadCrumbs", { path: route.path, name: "業務報告提出" });
            }
            // 業務報告確認
            if (route.path == `/mypage/works/${route.params.entry_id}/report/${route.params.report_id}`) {
              commit("setBreadCrumbs", { path: route.path, name: "業務報告確認" });
            }
          }
        }

        // スカウト一覧
        if (route.path.indexOf("/scout") != -1) {
          commit("setBreadCrumbs", {
            path: "/mypage/scout",
            name: "スカウト",
          });
          // スカウト詳細
          if (route.params.scout_id) {
            var scout = rootState.scouts.currentScout;
            if (scout) {
              commit("setBreadCrumbs", {
                path: route.path,
                name: scout.title ? scout.title : "",
              });
            }
          }
        }

        // 報酬管理
        if (route.path.indexOf("/reward") != -1) {
          commit("setBreadCrumbs", { path: "/mypage/reward", name: "報酬管理" });
        }

        // 各種設定
        if (route.path.indexOf("/setting") != -1) {
          commit("setBreadCrumbs", { path: "/mypage/setting", name: "各種設定" });

          // 各種設定/基本情報
          if (route.path.indexOf("/basic") != -1) {
            commit("setBreadCrumbs", {
              path: "/mypage/setting/basic",
              name: "基本情報",
            });
          }
          // 各種設定/プロフィール
          if (route.path.indexOf("/profile") != -1) {
            commit("setBreadCrumbs", {
              path: "/mypage/setting/profile",
              name: "プロフィール",
            });
          }
          // 各種設定/プロフィール/スキル・経験
          if (route.path.indexOf("/skill") != -1) {
            commit("setBreadCrumbs", {
              path: "/mypage/setting/skill",
              name: "スキル・経験",
            });
          }
          // 各種設定/プロフィール/ポートフォリオ
          if (route.path.indexOf("/portfolio") != -1) {
            commit("setBreadCrumbs", {
              path: "/mypage/setting/portfolio",
              name: "ポートフォリオ",
            });
          }
          // 各種設定/非公開設定
          if (route.path.indexOf("/mute") != -1) {
            commit("setBreadCrumbs", {
              path: "/mypage/setting/mute",
              name: "非公開設定",
            });
          }
          // 各種設定/振込先口座情報
          if (route.path.indexOf("/bank") != -1) {
            commit("setBreadCrumbs", {
              path: "/mypage/setting/bank",
              name: "振込先口座情報",
            });
          }
          // 各種設定/本人確認書類
          if (route.path.indexOf("/verify") != -1) {
            if (isCompany) {
              // 法人の場合
              commit("setBreadCrumbs", {
                path: "/mypage/setting/verify",
                name: "本人確認書類（法人）",
              });
            } else {
              // 個人の場合
              commit("setBreadCrumbs", {
                path: "/mypage/setting/verify",
                name: "本人確認書類（個人）",
              });
            }

            if (route.path.indexOf("/submit") != -1) {
              if (isCompany) {
                // 法人の場合
                commit("setBreadCrumbs", {
                  path: route.path,
                  name: "本人確認書類提出（法人）",
                });
              } else {
                // 個人の場合
                commit("setBreadCrumbs", {
                  path: route.path,
                  name: "本人確認書類提出（個人）",
                });
              }
            }

          }
          // 各種設定/インボイス
          if (route.path.indexOf("/invoice") != -1) {
            commit("setBreadCrumbs", {
              path: route.path,
              name: "インボイス",
            });
          }
          // 各種設定/機密保持
          if (route.path.indexOf("/nda") != -1) {
            commit("setBreadCrumbs", {
              path: route.path,
              name: "機密保持の再確認",
            });
          }
          // 各種設定/退会手続き
          if (route.path.indexOf("/withdrawal") != -1) {
            commit("setBreadCrumbs", {
              path: "/mypage/setting/withdrawal",
              name: "退会手続き",
            });
          }
        }
      }
    }
  },
  getters: {
    noLink: () => (route) => {
      // リロードして入力中のデータが消えてしまうページはリンクを貼らない
      if (
        // 条件オファー作成中、変更中、確認中の時
        (route.path.indexOf('offers') == -1 &&
          route.path.indexOf('offer') != -1) ||
        // 業務報告
        (route.path.indexOf('reports') == -1 &&
          route.path.indexOf('report') != -1)
      ) {
        // ページ読み込みされるのがNGならtrueにする
        // return true
        return false
      } else {
        return false
      }
    },
    canRouterLink: () => (route) => {
      // ルーターリンクできるページを記載
      if (
        route.path.indexOf('/mypage') != -1
      ) {
        // ルーターリンクでも良いならtrueにする
        // return true
        return false
      } else {
        return false
      }
    }
  }
}