import Vue from 'vue'

import MemberAPI from '@/api/member'

export default {
    namespaced: true,

    state: {
        loaded: false,
        birthdate: null,

        // STEP1
        profileImage: null,
        memberKind: {
            'code': '01',
            'name': '個人',
        },
        companyName: "",
        companyNameKana: "",
        presidentName: "",
        presidentNameKana: "",
        familyNameKana: "",
        nameKana: "",
        familyName: "",
        name: "",
        useNameAsDisplayName: false,
        displayName: "",
        gender: {
            'code': '',
            'name': '',
        },
        zip: "",
        prefCd: "",
        city: "",
        address: "",
        usePersonalLocationAsOfficeLocation: false,
        officeZip: "",
        officePrefCd: "",
        officeCity: "",
        officeAddress: "",
        tel: "",
    
        // STEP2
        employeeTypes: [],
        unitPrice: "",
        workingTimes: [],
        jobTypes: [],
        locations: [],
        workStyles: [],
    
        // STEP3
        professionalLevelSkills: [],
        businessLevelSkills: [],
        basicLevelSkills: [],
        certifications: [],
    
        // STEP4
        workExperiences: [],
    
        // STEP5
        catchPhrase: "",
        selfPromotion: "",
    
        // STEP6
        selfChecks: [],

        // Portfolio
        portfolios: [],

        // Mails
        mailMagazine: true,
        scout: true,

        // controlls
        temporaryProfileId: null,

        unitPriceMin: null,
        unitPriceMax: null,

        // 追加質問（正社員関連など）
        additionalQuestions:[],
    },
    mutations: {
        setAdditionalQuestion(state, { question, answer }) {
            if(question && answer){
                const obj = {
                    question: question,
                    answer: answer
                };
    
                for (const i in state.additionalQuestions) {
                    const oldAnswer = state.additionalQuestions[i]
                    if (oldAnswer.question == question) {
                        state.additionalQuestions.splice(i, 1, obj)
                        return
                    }
                }
                state.additionalQuestions.push(obj)
            }else{
                state.additionalQuestions = []
            }
        },
        setBirthdate(state, v){
            state.birthdate = v;
        },
        
        // STEP1
        setProfileImage(state, v){
            state.profileImage = v
        },
        setMemberKind(state, v){
            state.memberKind = v
        },
        setCompanyName(state, v){
            state.companyName = v
        },
        setCompanyNameKana(state, v){
            state.companyNameKana = v
        },
        setPresidentName(state, v){
            state.presidentName = v
        },
        setPresidentNameKana(state, v){
            state.presidentNameKana = v
        },
        setFamilyNameKana(state, v){
            state.familyNameKana = v
        },
        setNameKana(state, v){
            state.nameKana = v
        },
        setFamilyName(state, v){
            state.familyName = v
        },
        setName(state, v){
            state.name = v
        },
        setUseNameAsDisplayName(state, v){
            state.useNameAsDisplayName = v
        },
        setDisplayName(state, v){
            state.displayName = v
        },
        setGender(state, v){
            state.gender = v
        },
        setZip(state, v){
            state.zip = v
        },
        setPrefCd(state, v){
            state.prefCd = v
        },
        setCity(state, v){
            state.city = v
        },
        setAddress(state, v){
            state.address = v
        },
        setUsePersonalLocationAsOfficeLocation(state, v){
            state.usePersonalLocationAsOfficeLocation = v
        },
        setOfficeZip(state, v){
            state.officeZip = v
        },
        setOfficePrefCd(state, v){
            state.officePrefCd = v
        },
        setOfficeCity(state, v){
            state.officeCity = v
        },
        setOfficeAddress(state, v){
            state.officeAddress = v
        },
        setTel(state, v){
            state.tel = v
        },
    
        // STEP2
        setEmployeeTypes(state, v){
            state.employeeTypes = v
        },
        setUnitPrice(state, v){
            state.unitPrice = v
        },
        setWorkingTimes(state, v){
            state.workingTimes = v
        },
        setJobTypes(state, v){
            state.jobTypes = v
        },
        setLocations(state, v){
            state.locations = v
        },
        setWorkStyles(state, v){
            state.workStyles = v
        },
    
        // STEP3
        setProfessionalLevelSkills(state, v){
            state.professionalLevelSkills = v
        },
        setBusinessLevelSkills(state, v){
            state.businessLevelSkills = v
        },
        setBasicLevelSkills(state, v){
            state.basicLevelSkills = v
        },
        removeProfessionalLevelSkill(state, v){
            state.professionalLevelSkills = state.professionalLevelSkills.filter(a=>{
                return !(a.code == v.code && a.name == v.name && a.parentCode == v.parentCode)
            })
        },
        removeBusinessLevelSkill(state, v){
            state.businessLevelSkills = state.businessLevelSkills.filter(a=>{
                return !(a.code == v.code && a.name == v.name && a.parentCode == v.parentCode)
            })
        },
        removeBasicLevelSkill(state, v){
            state.basicLevelSkills = state.basicLevelSkills.filter(a=>{
                return !(a.code == v.code && a.name == v.name && a.parentCode == v.parentCode)
            })
        },

        setCertifications(state, v){
            state.certifications.push(v)
        },
        addCertification(state, v){
            state.certifications.push(v)
        },
        removeCertification(state, v){
            state.certifications = state.certifications.filter((a)=>{
            if(a.code == v.code && a.name == v.name) return false;
                return true;
            })
        },
        // STEP4
        addWorkExperience(state){
            state.workExperiences.push({
                businessTypes: [],
                jobTypes: [],
                contractType: null,
                periodStartYear: null,
                periodStartMonth: null,
                periodEndYear: null,
                periodEndMonth: null,
                periodCurrent: false,
                companyName: "",
                description: "",
                skills:[],
                knownBusinessTypes: []
            })
        },
    
        updateWorkExperience(state, {index, value}){
            state.workExperiences.splice(index, 1, value)
        },

        removeWorkExperience(state, index){
            state.workExperiences.splice(index, 1)
        },
    
        // STEP5
        setCatchPhrase(state, v){
            state.catchPhrase = v
        },
        setSelfPromotion(state, v){
            state.selfPromotion = v
        },
    
        // STEP6
        setSelfCheck(state, {question, answer}){
            const obj = {
                question: question,
                answer: answer
            };

            for(const i in state.selfChecks){
                const oldAnswer = state.selfChecks[i]
                if(oldAnswer.question == question){
                    state.selfChecks.splice(i, 1, obj)
                    return
                }
            }
            state.selfChecks.push(obj)
        },

        addPortfolio(state){
            state.portfolios.push({
                portfolio: null,
                periodStartYear: null,
                periodStartMonth: null,
                periodEndYear: null,
                periodEndMonth: null,
                periodCurrent: false,
                jobTypes: [],
                title: "",
                description: "",
                referencePrices: null,
                url: ""
            })
        },
    
        updatePortfolio(state, {index, value}){
            state.portfolios.splice(index, 1, value)
        },

        removePortfolio(state, index){
            state.portfolios.splice(index, 1)
        },

        setMailMagazine(state, v){
            state.mailMagazine = v
        },

        setScout(state, v){
            state.scout = v
        },

        setProfile(state, v){
            state.profileImage = v.profileImage
            state.memberKind = v.memberKind
            if(typeof(v.memberKind) == 'string'){
                console.info("memberKindがstring")
                state.memberKind = {
                    'code': '01',
                    'name': '個人',
                }
            }
            state.companyName = v.companyName
            state.companyNameKana = v.companyNameKana
            state.presidentName = v.presidentName
            state.presidentNameKana = v.presidentNameKana
            state.familyNameKana = v.familyNameKana
            state.nameKana = v.nameKana
            state.familyName = v.familyName
            state.name = v.name
            state.useNameAsDisplayName = v.useNameAsDisplayName
            state.displayName = v.displayName
            state.gender = v.gender
            if(typeof(v.gender) == 'string'){
                console.info("genderがstring")
                state.gender = {
                    'code': '01',
                    'name': '男性',
                }
            }
            state.birthdate = v.birthdate
            state.zip = v.zip
            state.prefCd = v.prefCd
            state.city = v.city
            state.address = v.address
            state.usePersonalLocationAsOfficeLocation = v.usePersonalLocationAsOfficeLocation
            state.officeZip = v.officeZip
            state.officePrefCd = v.officePrefCd
            state.officeCity = v.officeCity
            state.officeAddress = v.officeAddress
            state.tel = v.tel
            state.unitPrice = v.unitPrice
            state.unitPriceMin = v.unitPriceMin
            state.unitPriceMax = v.unitPriceMax
            state.employeeTypes = v.employeeTypes ? v.employeeTypes : [] // サーバー側が実装されたら分岐をなくしても良い
            state.workingTimes = v.workingTimes
            if(v.workingTimes&&v.workingTimes.length>0 && typeof(v.workingTimes[0]) == 'string'){
                console.info("TODO: API レスポンス profile.workingTimesがstring")
                state.workingTimes = []
            }
            state.jobTypes = v.jobTypes
            if(v.jobTypes&&v.jobTypes.length>0 && typeof(v.jobTypes[0]) == 'string'){
                console.info("TODO: API レスポンス profile.jobTypesがstring")
                state.jobTypes = []
            }
            state.locations = v.locations
            if(v.locations&&v.locations.length>0 && typeof(v.locations[0]) == 'string'){
                console.info("TODO: API レスポンス profile.locationsがstring")
                state.locations = []
            }
            state.workStyles = v.workStyles
            if(v.workStyles&&v.workStyles.length>0 && typeof(v.workStyles[0]) == 'string'){
                console.info("TODO: API レスポンス profile.workStylesがstring")
                state.workStyles = []
            }
            state.professionalLevelSkills = v.professionalLevelSkills
            state.businessLevelSkills = v.businessLevelSkills
            state.basicLevelSkills = v.basicLevelSkills
            state.certifications = v.certifications
            state.workExperiences = v.workExperiences
            if(v.workExperiences&&v.workExperiences.length>0){
                if(!v.workExperiences[0].businessTypes){
                    console.info("TODO: API レスポンス profile.workExperiencesが古い")
                    state.workExperiences = []
                }
            }
            state.catchPhrase = v.catchPhrase
            state.selfPromotion = v.selfPromotion
            state.selfChecks = v.selfChecks
            state.portfolios = v.portfolios
            state.mailMagazine = v.mailMagazine
            state.scout = v.scout
            state.loaded = true
            state.additionalQuestions = v.additionalQuestions
        }
    },
    actions: {
        getEntryProfile({commit}, entryId){ /* eslint no-unused-vars:0 */
            return new Promise((resolve, reject)=>{
                MemberAPI.getEntryProfile(entryId).then((profile)=>{
                    commit('setProfile', profile)
                    resolve()
                })
                .catch((e)=>{
                    reject(e)
                })
            });
        },
    }
}
