import Vue from 'vue'
import MemberAPI from '@/api/member'
import WebSocket from '@/websocket'
import moment from 'moment'

export default {
    namespaced: true,
    state: {
        chats: [],
        selectedEntryId: null,
        canCancel: true,
        messages: {},
        readChatIds: []
    },
    mutations: {
        setChats: (state, chats) => {
            state.chats = chats
        },
        selectChat: (state, entryId) => {
            // 過去に閲覧したentryIdは配列に入れない
            if (entryId != null && !isNaN(Number(entryId))) {
                if (state.readChatIds.indexOf(Number(entryId)) < 0) {
                    state.readChatIds.push(Number(entryId))
                }
            }
            state.selectedEntryId = entryId
        },
        selectCanCancel: (state, boolean) => {
            if (boolean) {
                state.canCancel = boolean
            } else {
                state.canCancel = true
            }
        },
        setMessages: (state, { entryId, messages }) => {
            Vue.set(state.messages, entryId, messages)
        },
        addMessage: (state, message) => {
            if (state.messages[message.entryId]) {
                state.messages[message.entryId].push(message)
            }
        },
        updateLastUpdateAt: (state, { entryId, timestamp }) => {
            for (const i in state.chats) {
                // if (state.chats[i].entryId == entryId) {
                //     Vue.set(state.chats[i], 'lastUpdateAt', timestamp)
                //     return;
                // }
                const chat = state.chats[i];
                if (chat.entryId == entryId) {
                    chat.lastUpdateAt = timestamp;
                }
            }
        },
        updateReadStatus: (state, { entryId, memberKind, memberId, isOwn, timestamp }) => {
            for (const i in state.chats) {
                const chat = state.chats[i];
                if (chat.entryId == entryId) {
                    if (isOwn) {
                        chat.lastReadAt = timestamp
                    }

                    for (const j in chat.readStatuses) {
                        const readStatus = chat.readStatuses[j]
                        if (readStatus.memberKind == memberKind && readStatus.memberId == memberId) {
                            readStatus.isOwn = isOwn
                            readStatus.lastReadAt = timestamp
                            return;
                        }
                    }
                    chat.readStatuses.push({
                        memberKind: memberKind,
                        memberId: memberId,
                        isOwn: isOwn,
                        lastReadAt: timestamp
                    })
                }
            }
        },
        deleteMessage: (state, { entryId, messageId }) => {
            console.log(entryId, messageId)

            if (!state.messages[entryId]) return

            for (const i in state.messages[entryId]) {
                const message = state.messages[entryId][i]
                if (message.messageId == messageId) {
                    message.status = 9
                    Vue.set(state.messages[entryId], i, message)
                    return
                }
            }
        },
        closeChat: (state, { entryId }) => {
            for (const i in state.chats) {
                const chat = state.chats[i];
                if (chat.entryId == entryId) {
                    chat.closed = true;
                }
            }
        }
    },
    actions: {
        loadChats: ({ commit }) => {
            return new Promise((resolve) => {
                (async () => {

                    const chats = await MemberAPI.getMessageChats().catch(e => {
                        console.log(e)
                        return false;
                    })

                    if (!chats) {
                        return false;
                    }
                    commit('setChats', chats.messageChats)

                    resolve()

                })();
            })
        },
        loadMessages: ({ commit }, entryId) => {
            return new Promise((resolve) => {
                (async () => {
                    const messages = await MemberAPI.getMessageChatsEntryId(entryId).catch(e => {
                        console.log(e)
                        return false;
                    })

                    if (!messages) {
                        return false;
                    }

                    commit('setMessages', {
                        entryId,
                        messages: messages.messages
                    })

                    resolve()
                })();
            })
        },
        connectWebSocket: ({ commit, state }) => { /* eslint no-unused-vars: 0 */
            return new Promise((resolve, reject) => {
                (async () => {
                    const token = await MemberAPI.getMessageChatsToken().catch(e => {
                        console.log(e)
                        return false;
                    })

                    if (!token) {
                        reject()
                    }

                    WebSocket.setOnOpennedListener(() => {
                        resolve();
                    })
                    WebSocket.setOnClosedListener(() => {
                        (async () => {
                            const token = await MemberAPI.getMessageChatsToken().catch(e => {
                                console.log(e)
                                return false;
                            })

                            if (token) {
                                console.log("reconnecting...")
                                WebSocket.loginMember(token.accessToken)
                            }
                        })()
                    })
                    WebSocket.setOnErrorListener(() => { })
                    WebSocket.setOnMessageListener((message) => {
                        // 最終更新日更新
                        commit('updateLastUpdateAt', {
                            entryId: message.entryId,
                            timestamp: message.timestamp
                        });

                        if (message.type == 'block') {
                            if (message.metadata.blocktype == 'deny_entry') {
                                commit('closeChat', { entryId: message.entryId })
                            }
                            else if (message.metadata.blocktype == 'cancel_entry') {
                                commit('closeChat', { entryId: message.entryId })
                            }
                        }

                        // 既にログ取得済みであれば、受信したメッセージを追加
                        if (state.messages[message.entryId]) {
                            console.log('メッセージ追加')
                            console.log(message)
                            var downloadLimit = moment().add(2, 'w').format('YYYY.MM.DD HH:mm')

                            commit('addMessage', {
                                isDownload: true,
                                downloadLimit: message.downloadLimit ? message.downloadLimit : downloadLimit,
                                messageId: message.messageId,
                                type: message.type,
                                status: message.status,
                                metadata: message.metadata,
                                entryId: message.entryId,
                                text: message.text,
                                timestamp: message.timestamp,
                                sender: {
                                    connectionId: message.sender.connectionId,
                                    icon: message.sender.icon,
                                    isOwn: message.sender.isOwn ? message.sender.isOwn : message.sender.memberKind == "M" ? true : false,
                                    name: message.sender.name,
                                }
                            })

                            // チャットを開いている場合は既読にする。
                            if (message.entryId == state.selectedEntryId) {
                                WebSocket.notifyRead(message.entryId)
                            }
                        }
                    });

                    WebSocket.setOnNotifyReadListener((notify) => {
                        // 既読情報
                        commit('updateReadStatus', {
                            entryId: notify.entryId,
                            memberKind: notify.sender.memberKind,
                            memberId: notify.sender.memberId,
                            isOwn: notify.sender.isOwn,
                            timestamp: notify.timestamp
                        });
                    });

                    WebSocket.setOnDeleteListener((del) => {
                        // 削除情報
                        commit('deleteMessage', {
                            entryId: del.entryId,
                            messageId: del.messageId,
                        });
                    })

                    WebSocket.loginMember(token.accessToken)

                })();
            })
        },
        disconnectWebSocket: ({ commit }) => {   /* eslint no-unused-vars: 0 */
            WebSocket.logout();
        },
        send: ({ commit, state }, { entryId, type, message, metadata }) => {   /* eslint no-unused-vars: 0 */
            WebSocket.sendMessage(entryId, type, message, metadata);
            // commit('closeChat', { entryId: message.entryId })
        },
        deleteMessage: ({ commit, state }, { entryId, messageId }) => {   /* eslint no-unused-vars: 0 */
            WebSocket.deleteMessage(entryId, messageId);
        },
        notifyRead: ({ commit, state }, entryId) => {   /* eslint no-unused-vars: 0 */
            WebSocket.notifyRead(entryId)
        }
    },
}