<template>
  <div>
    <transition name="modalBack">
      <span
        class="stage"
        style="z-index: 10000; display: inline"
        v-show="showModalFlg"
      ></span>
    </transition>
    <transition name="modalMain">
      <div class="modalstage" v-if="showModalFlg">
        <div class="modalwin">
          <div class="modalwin_detail">
            <h2 class="title_hdg2">
              <span>エラー</span>
            </h2>
            <p>{{ errorContent }}</p>
            <ul class="text_caption">
              <li>
                ※お問い合わせは<span>
                  <a href="/contact">こちら</a>から。
                </span>
              </li>
            </ul>
            <span class="modalwin_close_icn" @click="showModalFlg = false">
              <span>閉じる</span>
            </span>
            <span class="modalwin_close_btn" @click="showModalFlg = false">
              <span>閉じる</span>
            </span>
          </div>
        </div>
        <!-- /modalwin -->
      </div>
    </transition>
  </div>
</template>

<script>
// ■□■□■□■□■□　コンポーネントを使用する際の注意点　■□■□■□■□■□
// ① 親コンポーネントに埋め込む時、当コンポーネントの属性に下記を追加する
// ref="errorsModal"
//
// ② 下記のメソッドを親に追加し、モーダルを表示させる時に発火させる。
// showErrorsModal(e) {  // eには表示させたいエラーテキストを入れる
//   this.$refs.errorsModal.switchModal(e)
// },

export default {
  name: "ErrorsModal",
  data() {
    return {
      showModalFlg: false,
      errorContent: "",
    };
  },
  methods: {
    switchModal(errorText) {
      errorText
        ? (this.errorContent = errorText)
        : (this.errorContent = "エラーが発生しました");
      this.showModalFlg = !this.showModalFlg;
    },
  },
};
</script>
