// import Vue from 'vue'
import MemberAPI from '@/api/member'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    "id": null,
    "termStart": null,
    "termEnd": null,
    "workStart": null,
    "workEnd": null,
    "contractType": null,
    "offerStatus": null,
    "isAdded": false,
    "isReserve": false,
    "unitPrice": "",
    "contractGroups": [],
    "note": "",
    "sentFlg": false,
  },
  mutations: {
    setId: (state, v) => {
      state.id = v
    },
    setTermStart: (state, v) => {
      state.termStart = v
    },
    setTermEnd: (state, v) => {
      state.termEnd = v
    },
    setWorkStart: (state, v) => {
      state.workStart = v
    },
    setWorkEnd: (state, v) => {
      state.workEnd = v
    },
    setContractType: (state, v) => {
      state.contractType = v
    },
    setOfferStatus: (state, v) => {
      state.offerStatus = v
    },
    setIsAdded: (state, v) => {
      state.isAdded = v
    },
    setIsReserve: (state, v) => {
      state.isReserve = v
    },
    setUnitPrice: (state, v) => {
      state.unitPrice = v
    },
    setContractGroups: (state, v) => {
      state.contractGroups = v
    },
    addContractGroups: (state, { contractGroupId, startDate, endDate, items, canEdit, contractMonth, reportStatus, taxWithholding, totalReportPaymentAmountInTax }) => {
      state.contractGroups.push({
        contractGroupId,
        startDate,
        endDate,
        items,
        canEdit,
        contractMonth,
        reportStatus,
        taxWithholding,
        totalReportPaymentAmountInTax
      })
    },
    setNote: (state, v) => {
      state.note = v
    },
    setSentFlg: (state, v) => {
      state.sentFlg = v
    }
  },
  actions: {
    clear({ commit }) {
      commit('setId', null)
      commit('setTermStart', null)
      commit('setTermEnd', null)
      commit('setWorkStart', null)
      commit('setWorkEnd', null)
      commit('setContractType', null)
      commit('setOfferStatus', null)
      commit('setIsAdded', null)
      commit('setIsReserve', false)
      commit('setUnitPrice', null)
      commit('setContractGroups', [])
      commit('setNote', "")
    },
    clearContractGroups({ commit }) {
      commit('setContractGroups', [])
    },

    postEntryIdOffers({ commit, state, getters }, { entry, willSend }) { /* eslint no-unused-vars:0 */
      return new Promise((resolve, reject) => {
        (async () => {
          try {
            const offer = await MemberAPI.postEntryIdOffers(entry.entryId, {
              id: state.id,
              termStart: state.termStart,
              termEnd: state.termEnd,
              workStart: state.workStart,
              workEnd: state.workEnd,
              contractType: {
                name: entry.contractType.name,
                code: entry.contractType.code,
              },
              unitPrice: state.unitPrice - 0,
              totalWorkingHour: getters.totalWorkingTime,
              totalPaymentAmount: getters.totalPaymentAmount,
              totalPaymentAmountInTax: getters.totalPaymentAmountInTax,
              taxAmountAmount: getters.taxAmount,
              offerStatus: state.offerStatus,
              isReserve: state.isReserve,
              contractGroups: state.contractGroups,
              note: state.note,
              willSend: willSend ? willSend : false
            });

            // console.log({
            //   id: state.id,
            //   termStart: state.termStart,
            //   termEnd: state.termEnd,
            //   workStart: state.workStart,
            //   workEnd: state.workEnd,
            //   contractType: {
            //     name: entry.contractType.name,
            //     code: entry.contractType.code,
            //   },
            //   unitPrice: state.unitPrice - 0,
            //   totalWorkingHour: getters.totalWorkingTime,
            //   totalPaymentAmount: getters.totalPaymentAmount,
            //   totalPaymentAmountInTax: getters.totalPaymentAmountInTax,
            //   taxAmountAmount: getters.taxAmount,
            //   offerStatus: null,
            //   contractGroups: state.contractGroups,
            //   note: state.note
            // })
            // console.log(offer)

            commit('setId', offer.id)
            commit('setTermStart', offer.termStart)
            commit('setTermEnd', offer.termEnd)
            commit('setWorkStart', offer.workStart)
            commit('setWorkEnd', offer.workEnd)
            commit('setContractType', offer.contractType)
            commit('setOfferStatus', offer.offerStatus)
            commit('setIsAdded', offer.isAdded)
            commit('setIsReserve', offer.isReserve)
            commit('setUnitPrice', offer.unitPrice)
            commit('setContractGroups', offer.contractGroups)
            commit('setNote', offer.note)
            resolve(offer)
          }
          catch (e) {
            reject(e)
          }
        })();
      });
    },
    getEntryIdOfferId({ commit }, { entryId, offerId, onlyInfo = false }) {
      return new Promise((resolve, reject) => {
        (async () => {
          try {
            let offer = await MemberAPI.getEntryIdOfferId(entryId, offerId)
              .catch((error) => {
                reject(error)
              });

            if (!offer) {
              reject()
            }

            if (offer != [] && offer.status != 429 && !onlyInfo) {
              commit('setId', offer.id)
              commit('setTermStart', offer.termStart)
              commit('setTermEnd', offer.termEnd)
              commit('setWorkStart', offer.workStart)
              commit('setWorkEnd', offer.workEnd)
              commit('setContractType', offer.contractType)
              commit('setOfferStatus', offer.offerStatus)
              commit('setIsAdded', offer.isAdded)
              commit('setIsReserve', offer.isReserve)
              commit('setUnitPrice', offer.unitPrice)
              commit('setContractGroups', offer.contractGroups)
              commit('setNote', offer.note)
            }
            resolve(offer)
          }
          catch (e) {
            reject(e)
          }
        })();
      });
    },
    getReserveEntryIdOfferId({ commit }, { entryId, offerId, isReserve }) {
      return new Promise((resolve, reject) => {
        (async () => {
          try {
            const offer = await MemberAPI.getEntryIdOfferId(entryId, offerId, isReserve)
              .catch((error) => {
                reject(error)
              });

            if (offer.status != 429) {
              commit('setId', offer.id)
              commit('setTermStart', offer.termStart)
              commit('setTermEnd', offer.termEnd)
              commit('setWorkStart', offer.workStart)
              commit('setWorkEnd', offer.workEnd)
              commit('setContractType', offer.contractType)
              commit('setOfferStatus', offer.offerStatus)
              commit('setIsAdded', offer.isAdded)
              commit('setIsReserve', offer.isReserve)
              commit('setUnitPrice', offer.unitPrice)
              commit('setContractGroups', offer.contractGroups)
              commit('setNote', offer.note)
            }
            resolve(offer)
          }
          catch (e) {
            reject(e)
          }
        })();
      });
    },
    postEntryIdOffersSend({ commit, state, getters }, { entry }) { /* eslint no-unused-vars:0 */
      return new Promise((resolve, reject) => {
        (async () => {
          try {
            const offer = await MemberAPI.postEntryIdOfferIdSend(entry.entryId, state.id);

            commit('setId', offer.id)
            commit('setTermStart', offer.termStart)
            commit('setTermEnd', offer.termEnd)
            commit('setWorkStart', offer.workStart)
            commit('setWorkEnd', offer.workEnd)
            commit('setContractType', offer.contractType)
            commit('setOfferStatus', offer.offerStatus)
            commit('setOfferStatus', offer.offerStatus)
            commit('setIsAdded', offer.isAdded)
            commit('setIsReserve', offer.isReserve)
            commit('setUnitPrice', offer.unitPrice)
            commit('setContractGroups', offer.contractGroups)
            commit('setNote', offer.note)

            commit('setSentFlg', true)

            resolve(offer)
          }
          catch (e) {
            reject(e)
          }
        })();
      });
    },
    postEntryIdOfferIdCancel: ({ commit }, { entryId, offerId }) => {
      return new Promise((resolve) => {
        (async () => {

          const offer = await MemberAPI.postEntryIdOfferIdCancel(entryId, offerId).catch(e => {
            resolve(e)
            return false;
          })

          if (!offer) {
            return false;
          }

          commit('setId', offer.id)
          commit('setTermStart', offer.termStart)
          commit('setTermEnd', offer.termEnd)
          commit('setWorkStart', offer.workStart)
          commit('setWorkEnd', offer.workEnd)
          commit('setContractType', offer.contractType)
          commit('setOfferStatus', offer.offerStatus)
          commit('setIsAdded', offer.isAdded)
          commit('setIsReserve', offer.isReserve)
          commit('setUnitPrice', offer.unitPrice)
          commit('setContractGroups', offer.contractGroups)
          commit('setNote', offer.note)

          resolve(offer)

        })();
      })
    },
    purchaseOrder: ({ commit }, { entryId, offerId }) => {
      return new Promise((resolve, reject) => {
        (async () => {
          try {
            // キーの取得
            const dlKey = MemberAPI.postEntryIdOfferIdPurchaseOrderKey(entryId, offerId)
              .then((key) => {
                // 取得したキーを使用して発注書をDL
                const url = process.env.VUE_APP_DOWNLOAD_ENTRY_POINT + "api/backend/user/download/purchase/order/" + key.dl_key

                console.log("purchase url", url)

                if (!window.open(url)) {
                  location.href = url;
                } else {
                  window.open(url);
                }

              })
              .catch((error) => {
                reject(error)
              });
          }
          catch (e) {
            reject(e)
          }
        })();
      });

    }
  },
  modules: {
  },
  getters: {
    totalWorkingTime(state) {
      let hours = 0;
      for (const i in state.contractGroups) {
        const contractGroup = state.contractGroups[i];
        for (const j in contractGroup.items) {
          const item = contractGroup.items[j];
          hours = ((hours * 10) + (item.workingHour * 10)) / 10 - 0;
        }
      }
      return Math.floor(hours * 10) / 10;
    },
    totalPaymentAmount(state) {
      let totalAmount = 0;
      for (const i in state.contractGroups) {
        const contractGroup = state.contractGroups[i];
        for (const j in contractGroup.items) {
          const item = contractGroup.items[j];
          if (!isNaN(item.paymentAmount)) {
            totalAmount = ((totalAmount * 10) + (item.paymentAmount * 10)) / 10 - 0;
          } else {
            console.log('contract group total payment amount')
            console.log(item)
          }
        }
      }
      return totalAmount;
    },
    taxAmount(state, getters) {
      // return Math.floor(getters.totalPaymentAmount * 0.1);
      let totalTax = 0;
      for (const i in state.contractGroups) {
        const contractGroup = state.contractGroups[i];
        for (const j in contractGroup.items) {
          const item = contractGroup.items[j];
          if (!isNaN(item.paymentAmount)) {
            totalTax += Math.floor(Number(item.paymentAmount * 10) * 0.1) / 10;
          } else {
            console.log('contract group taxAmount')
            console.log(item)
          }
        }
      }
      return totalTax;
    },
    totalPaymentAmountInTax(state, getters) {
      return getters.totalPaymentAmount + getters.taxAmount;
    },
    canEditPrice(state, getters) {
      // 契約前は変更可能
      // 契約中、契約後は変更不可能
      if ((state.offerStatus && state.offerStatus.code == '04') || getters.isReservedEdit || getters.isReservedConfirming || getters.isReservedEditRequest) {
        return false
      } else {
        return true
      }
    },
    isEditting: (state) => (offer) => {
      // 作成中
      if (offer && offer.offerStatus) {
        if (offer.offerStatus.code == '01' && offer.isReserve == false) {
          return true
        } else {
          return false
        }
      } else {
        if (state && state.offerStatus && state.offerStatus.code == '01' && state.isReserve == false) {
          return true
        } else {
          return false
        }
      }
    },
    isConfirming: (state) => (offer) => {
      // 企業確認中
      if (offer && offer.offerStatus) {
        if (offer.offerStatus.code == '02' && offer.isReserve == false) {
          return true
        } else {
          return false
        }
      } else {
        if (state && state.offerStatus && state.offerStatus.code == '02' && state.isReserve == true) {
          return true
        } else {
          return false
        }
      }
    },
    isEditRequest: (state) => (offer) => {
      // 修正要望中
      if (offer && offer.offerStatus) {
        if (offer.offerStatus.code == '03' && offer.isReserve == false) {
          return true
        } else {
          return false
        }
      } else {
        if (state && state.offerStatus && state.offerStatus.code == '03' && state.isReserve == true) {
          return true
        } else {
          return false
        }
      }
    },
    isReservedEdit: (state) => (offer) => {
      // （契約中）作成中
      if (offer && offer.offerStatus) {
        if (offer.offerStatus.code == '01' && offer.isReserve == true) {
          return true
        } else {
          return false
        }
      } else {
        if (state && state.offerStatus && state.offerStatus.code == '01' && state.isReserve == true) {
          return true
        } else {
          return false
        }
      }
    },
    isReservedConfirming: (state) => (offer) => {
      // （契約中）企業確認中
      if (offer && offer.offerStatus) {
        if (offer.offerStatus.code == '02' && offer.isReserve == true) {
          return true
        } else {
          return false
        }
      } else {
        if (state && state.offerStatus && state.offerStatus.code == '02' && state.isReserve == true) {
          return true
        } else {
          return false
        }
      }
    },
    isReservedEditRequest: (state) => (offer) => {
      // （契約中）修正要望中
      if (offer && offer.offerStatus) {
        if (offer.offerStatus.code == '03' && offer.isReserve == true) {
          return true
        } else {
          return false
        }
      } else {
        if (state && state.offerStatus && state.offerStatus.code == '03' && state.isReserve == true) {
          return true
        } else {
          return false
        }
      }
    },
    canSend0WorkingHour(state, getters) {
      // 報酬金額が1円以上なら
      console.log(getters.totalPaymentAmountInTax)
      if (1 <= getters.totalPaymentAmountInTax) {
        return true
      } else {
        return false
      }
    },
    inOtherTerms: (state) => (offers, termStart, termEnd) => {
      // trueだったら他の条件オファーの期間に含まれる
      let doubledTerms = [];
      const thisID = state.id;

      if (offers && offers.length > 0) {
        for (let i = 0; i < offers.length; i++) {
          // 期間チェックの対象から外す
          if (thisID == offers[i].id) continue;
          if (offers[i].offerStatus.code == "05") continue;
          if (offers[i].offerStatus.code == "09") continue;

          // momentに変換
          let offerTermStart = moment(offers[i].termStart);
          let offerTermEnd = moment(offers[i].termEnd);
          let offerTermBetween = moment(
            (offers[i].termStart + offers[i].termEnd) / 2
          );
          if (
            // 開始日が他オファーの期間に含まれる場合
            moment(moment(termStart).format("YYYY-MM-DD"))
              .add(-1, "days")
              .isBetween(offerTermStart, offerTermEnd)
          ) {
            doubledTerms.push(offers[i]);
          } else if (
            // 終了日が他オファーの期間に含まれる場合
            moment(moment(termEnd).format("YYYY-MM-DD"))
              .add(1, "days")
              .isBetween(offerTermStart, offerTermEnd)
          ) {
            doubledTerms.push(offers[i]);
          } else if (
            termStart &&
            termEnd &&
            // 他オファーの中間の期間が作成中の条件オファーの期間に含まれる場合
            offerTermBetween
              .isBetween(moment(termStart), moment(termEnd))
          ) {
            doubledTerms.push(offers[i]);
          }
        }

        if (doubledTerms && doubledTerms.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    }
  }
}