import PublicAPI from '@/api/public'

export default {
    namespaced: true,
    state: {
        model: {},
    },
    mutations: {
        setModel(state, model){
            state.model = model
        },
        setEntryCompleted(state, v){
            state.entryCompleted = v
        }
    },
    actions: {
        getJobCompanyIdPreviewKey({commit}, {companyId, key}){
            return new Promise((resolve,reject)=>{
                (async()=>{
                    const model = await PublicAPI.getJobCompanyIdPreviewKey(companyId, key).catch(e=>{
                        console.log(e)
                        reject(e)
                    })

                    commit('setModel', model)         
                    
                    resolve(model)
                })();
            })
        },
        getJobCompanyIdJobIdPreview({commit}, {companyId, jobId}){
            return new Promise((resolve,reject)=>{
                (async()=>{
                    const model = await PublicAPI.getJobCompanyIdJobIdPreview(companyId, jobId).catch(e=>{
                        console.log(e)
                        reject(e)
                    })

                    commit('setModel', model)         
                    
                    resolve(model)
                })();
            })
        },
        getJobCompanyIdJobIdRevisionRevisionIdPreview({commit}, {companyId, jobId, revisionId}){
            return new Promise((resolve)=>{
                (async()=>{
                    const model = await PublicAPI.getJobCompanyIdJobIdRevisionRevisionIdPreview(companyId, jobId, revisionId).catch(e=>{
                        console.log(e)
                        return false;
                    })

                    commit('setModel', model)         
                    
                    resolve(model)
                })();
            })
        },
    },
}
