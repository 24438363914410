// import PublicAPI from '@/api/public'
import MemberAPI from '@/api/member'

export default {
    namespaced: true,
    state: {
        banks: [],
        branches: []
    },
    mutations: {
        setBanks: (state, banks) => {
            state.banks = banks
            state.branches = []
        },
        setBranches: (state, branches) => {
            state.branches = branches
        },
        clear: (state) => {
            state.banks = []
            state.branches = []
        }
    },
    actions: {
        getBanks: ({ commit }, keyword) => {
            return new Promise((resolve) => {
                (async () => {

                    const result = await MemberAPI.getConstantsBanks({ query: keyword }).catch(e => {
                        console.log(e)
                        return false;
                    })

                    if (!result) {
                        return false;
                    }
                    commit('setBanks', result)

                    resolve()

                })();
            })
        },
        getBranches: ({ commit }, { bankCode, keyword }) => {
            return new Promise((resolve) => {
                (async () => {

                    const result = await MemberAPI.getConstantsBanksBankCode(bankCode, { query: keyword }).catch(e => {
                        console.log(e)
                        return false;
                    })

                    if (!result) {
                        return false;
                    }
                    commit('setBranches', result)

                    resolve()

                })();
            })
        }
    },
    modules: {
    }
}