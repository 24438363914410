export default {
  methods: {
    showErrorsModal(e) {  // eには表示させたいエラーテキストを入れる
      this.$refs.errorsModal.switchModal(e)
    },

    show404page(response) {
      console.log(response);
      try {
        if (response.error.response.data.status == 404) {
          this.$router.replace("/404.html");
        }else if(response.error.response.data.message == "無効なURLです"){
          this.$router.replace("/404.html");
        } else {
          console.log(response)
          this.showErrorsModal();
        }
      } catch (e) {
        console.log(e);
        this.showErrorsModal();
      }
    }
  }
}