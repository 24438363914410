// import Vue from 'vue'

import PublicAPI from '@/api/public'

export default {
    namespaced: true,
    state: {
        companyId:           0,
        companyName:         "",
        companyLogo:         "",
        companyDetail:       "",
        address:             "",
        representative:      "",
        establishment:       "",
        jobType:             {},
        businessDescription: "",
        capital:             "",
        numOfEmployee:       0,
        url:                 "",

        companyJobs: [],

        sort: {'code':'01', 'name':'新着順'},
        currentPage: 1,
        perPage: 3,

        beforePreviewFlg: false,

      },
    mutations: {
      setCompanyId: (state,v) => {
        state.companyId = v
      },
      setCompanyName: (state,v) => {
        state.companyName = v
      },
      setCompanyLogo: (state,v) => {
        state.companyLogo = v
      },
      setCompanyDetail: (state,v) => {
        state.companyDetail = v
      },
      setAddress: (state,v) => {
        state.address = v
      },
      setRepresentative: (state,v) => {
        state.representative = v
      },
      setEstablishment: (state,v) => {
        state.establishment = v
      },
      setjobType: (state,v) => {
        state.jobType = v
      },
      setBusinessDescription: (state,v) => {
        state.businessDescription = v
      },
      setCapital: (state,v) => {
        state.capital = v
      },
      setNumOfEmployee: (state,v) => {
        state.numOfEmployee = v
      },
      setUrl: (state,v) => {
        state.url = v
      },

      setCompanyJobs(state, result){
        state.companyJobs = result.jobs
      },
      setCurrentPage(state, page){
        state.currentPage = page
      },
    },
    actions: {
      clear({commit}){
        commit('setCompanyId', 0)
        commit('setCompanyName', '')
        commit('setCompanyLogo', '')
        commit('setCompanyDetail', '')
        commit('setAddress', '')
        commit('setRepresentative', '')
        commit('setEstablishment', '')
        commit('setjobType', {})
        commit('setBusinessDescription', '')
        commit('setCapital', 0)
        commit('setNumOfEmployee', '')
        commit('setUrl', '')
        commit('setCompanyJobs', [])
      },
      loadModel({commit, state}, companyId){
          return new Promise((resolve)=>{
              (async()=>{

                // 企業情報の取得、格納
                  const companyInfo = await PublicAPI.getCompanyInfo(companyId).catch(e=>{
                      console.log(e)
                      return false;
                  })
                  if(!companyInfo){
                    return false;
                  }else if( companyInfo.status == 404){
                    resolve(companyInfo)
                  }
                  else{
                    commit('setCompanyId', companyId)
                    commit('setCompanyName', companyInfo.name)
                    commit('setCompanyLogo',companyInfo.companyLogo)
                    commit('setCompanyDetail',companyInfo.companyDetail)
                    commit('setAddress',companyInfo.address)
                    commit('setRepresentative',companyInfo.representative)
                    commit('setEstablishment',companyInfo.establishment)
                    commit('setjobType',companyInfo.jobType)
                    commit('setBusinessDescription',companyInfo.businessDescription)
                    commit('setCapital',companyInfo.capital)
                    commit('setNumOfEmployee',companyInfo.numOfEmployee)
                    commit('setUrl',companyInfo.url)
                  }

                // 企業の掲載中仕事一覧の取得、格納
                  const params = {
                    companyId: 0,
                    page: 1,
                    sort: 0,
                    perPage: 0,
                  }
                  params.companyId = companyId
                  params.sort      = state.sort.code
                  params.perPage  = state.perPage
                  const result = await PublicAPI.search(params).catch(e=>{
                    console.log(e)
                    return false;
                  })
                  if(!result){
                    return false;
                  }
                  else{
                    commit('setCompanyJobs', result)
                  }

                  resolve(companyInfo)
              })();
          })
      },

    },
}
