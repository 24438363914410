
export default {
    namespaced: true,

    state: {
        loaded: false,
        memberId: null,
        birthdate: null,

        // STEP1
        profileImage: null,
        memberKind: {
            'code': '01',
            'name': '個人',
        },
        companyName: "",
        companyNameKana: "",
        presidentName: "",
        presidentNameKana: "",
        familyNameKana: "",
        nameKana: "",
        familyName: "",
        name: "",
        useNameAsDisplayName: false,
        displayName: "",
        gender: {
            'code': '01',
            'name': '男性',
        },
        zip: "",
        prefCd: "",
        city: "",
        address: "",
        usePersonalLocationAsOfficeLocation: false,
        officeZip: "",
        officePrefCd: "",
        officeCity: "",
        officeAddress: "",
        tel: "",
    
        // STEP2
        employeeTypes: [],
        unitPrice: "",
        workingTimes: [],
        jobCategories: [],
        locations: [],
        workStyles: [],
    
        // STEP3
        professionalLevelSkills: [],
        businessLevelSkills: [],
        basicLevelSkills: [],
        certifications: [],
    
        // STEP4
        workExperiences: [],
    
        // STEP5
        catchPhrase: "",
        selfPromotion: "",
    
        // STEP6
        selfChecks: [],

        // Portfolio
        portfolios: [],

        // Mails
        mailMagazine: true,
        scout: true,

        // controlls
        temporaryProfileId: null,

        unitPriceMin: null,
        unitPriceMax: null,
    },
    mutations: {
        setProfile(state, v){
            state.profileImage = v.profileImage
            state.memberKind = v.memberKind
            if(typeof(v.memberKind) == 'string'){
                console.info("memberKindがstring")
                state.memberKind = {
                    'code': '01',
                    'name': '個人',
                }
            }
            state.companyName = v.companyName
            state.companyNameKana = v.companyNameKana
            state.presidentName = v.presidentName
            state.presidentNameKana = v.presidentNameKana
            state.familyNameKana = v.familyNameKana
            state.nameKana = v.nameKana
            state.familyName = v.familyName
            state.name = v.name
            state.useNameAsDisplayName = v.useNameAsDisplayName
            state.displayName = v.displayName
            state.gender = v.gender
            if(typeof(v.gender) == 'string'){
                state.gender = {
                    'code': '01',
                    'name': '男性',
                }
            }
            state.birthdate = v.birthdate
            state.zip = v.zip
            state.prefCd = v.prefCd
            state.city = v.city
            state.address = v.address
            state.usePersonalLocationAsOfficeLocation = v.usePersonalLocationAsOfficeLocation
            state.officeZip = v.officeZip
            state.officePrefCd = v.officePrefCd
            state.officeCity = v.officeCity
            state.officeAddress = v.officeAddress
            state.tel = v.tel
            state.unitPrice = v.unitPrice
            state.unitPriceMin = v.unitPriceMin
            state.unitPriceMax = v.unitPriceMax
            state.employeeTypes = v.employeeTypes
            state.workingTimes = v.workingTimes
            if(v.workingTimes.length>0 && typeof(v.workingTimes[0]) == 'string'){
                console.info("TODO: API レスポンス profile.workingTimesがstring")
                state.workingTimes = []
            }
            state.jobCategories = v.jobCategories
            if(v.jobCategories.length>0 && typeof(v.jobCategories[0]) == 'string'){
                console.info("TODO: API レスポンス profile.jobCategoriesがstring")
                state.jobCategories = []
            }
            state.locations = v.locations
            if(v.locations.length>0 && typeof(v.locations[0]) == 'string'){
                console.info("TODO: API レスポンス profile.locationsがstring")
                state.locations = []
            }
            state.workStyles = v.workStyles
            if(v.workStyles.length>0 && typeof(v.workStyles[0]) == 'string'){
                console.info("TODO: API レスポンス profile.workStylesがstring")
                state.workStyles = []
            }
            state.professionalLevelSkills = v.professionalLevelSkills
            state.businessLevelSkills = v.businessLevelSkills
            state.basicLevelSkills = v.basicLevelSkills
            state.certifications = v.certifications
            state.workExperiences = v.workExperiences
            if(v.workExperiences.length>0){
                if(!v.workExperiences[0].businessTypes){
                    console.info("TODO: API レスポンス profile.workExperiencesが古い")
                    state.workExperiences = []
                }
            }
            state.catchPhrase = v.catchPhrase
            state.selfPromotion = v.selfPromotion
            state.selfChecks = v.selfChecks
            state.portfolios = v.portfolios
            state.mailMagazine = v.mailMagazine
            state.scout = v.scout
            state.loaded = true
            state.memberId = v.memberId
        }
    },
    actions: {
        getProfile({commit}, profileInfo){ /* eslint no-unused-vars:0 */
            commit('setProfile', profileInfo)
        },
    }
}