import PublicAPI from '@/api/public'

// import utils
import zenkakuToHankaku from "@/helpers/utils/zenkakuToHankaku"

export default {
  namespaced: true,
  state: {
    companyName: '',
    familyName: "",
    name: "",
    familyNameKana: "",
    nameKana: "",
    email: "",
    tel: '',
    publishCategories: [],
    detail: "",  // 空欄OK
    privacy: false
  },
  mutations: {
      setCompanyName(state, v){
        state.companyName = v
      },
      setFamilyName(state, v){
        state.familyName = v.toString().replace(/\s+/g,'')
      },
      setFamilyNameKana(state, v){
        state.familyNameKana = v.toString().replace(/\s+/g,'')
      },
      setName(state, v){
        state.name = v.toString().replace(/\s+/g,'')
      },
      setNameKana(state, v){
        state.nameKana = v.toString().replace(/\s+/g,'')
      },
      setEmail(state, v){
        state.email = zenkakuToHankaku(v)
      },
      setTel(state, v){
        v = zenkakuToHankaku(v)
        state.tel = v.toString().replace(/\s+/g,'')
      },
      setPublishCategories(state, v){
        state.publishCategories = v
      },
      setDetail(state, v){
        state.detail = v
      },
      setPrivacy(state, v){
        state.privacy = v
      }
  },
  actions: {
    postPublish({commit, state}){
      return new Promise((resolve, reject)=>{
        PublicAPI.postPublish({
          companyName: state.companyName,
          familyName: state.familyName,
          name: state.name,
          familyNameKana:  state.familyNameKana,
          nameKana: state.nameKana,
          email: state.email,
          tel: state.tel,
          publishCategories: state.publishCategories,
          detail: state.detail,
          privacy: state.privacy,
        }).then((response)=>{
          if(response&&response.status == "200"){
            commit('setCompanyName', '')
            commit('setFamilyName', '')
            commit('setName', '')
            commit('setFamilyNameKana', '')
            commit('setNameKana', '')
            commit('setEmail', '')
            commit('setTel', '')
            commit('setPublishCategories', [])
            commit('setDetail', '')
            commit('setPrivacy', false)

            resolve(response)
          }else{
            reject(response.message)
          }

        }).catch(e=>{
          reject(e)
        })
      })
    }
  },
  modules: {
  }
}