import Vue from 'vue'

const moment = require('moment')

export default {
  namespaced: true,
  state: {
    list: []
  },
  mutations: {
    add(state, job) {
      state.list = state.list.filter(j => {
        return j.jobId != job.id
      })

      state.list.push({
        jobId: job.id,
        addedAt: moment().valueOf(),
        job: job
      })
    },
    clean(state) {
      state.jobs = state.jobs.filter(j => {
        return j.addedAt < moment().subtract(3, 'month')
      })
    },
    storeState(state) {
      const props = Object.keys(state)
      for (const i in props) {
        const prop = props[i];

        window.localStorage.setItem('store.state.history.' + prop, JSON.stringify(state[prop]));
      }
    },

    restoreState(state) {
      const props = Object.keys(state)
      for (const i in props) {
        const prop = props[i];

        const rawItem = window.localStorage.getItem('store.state.history.' + prop)

        if (rawItem) {
          const value = JSON.parse(rawItem)
          Vue.set(state, prop, value)
        }
      }
    },
  },
  actions: {
    load({ commit }) {
      return new Promise((resolve) => {
        commit('restoreState')
        resolve()
      })
    },
    addJob({ commit }, { job }) {
      return new Promise((resolve) => {
        commit('add', job)
        commit('storeState')
        resolve()
      })
    },
    cleanJobs({ commit }) {
      return new Promise((resolve) => {
        commit('clean')
        commit('storeState')
        resolve()
      })
    }
  },
  getters: {
    list(state) {
      return state.list
    },
    daily(state) {
      const daily = {}
      const list = state.list.reverse()
      for (const i in list) {
        const history = list[i]
        const key = moment(history.addedAt).hour(0).minute(0).second(0).millisecond(0)

        if (!daily[key]) {
          daily[key] = {
            addedAt: key,
            jobs: []
          }
        }

        daily[key].jobs.push(history.job)
      }
      return Object.keys(daily).map((key) => { return daily[key] })
    }
  }
}
